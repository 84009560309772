import React, { useEffect, useState } from 'react';
import { Modal, Select,Input } from 'antd';

import { useSelector } from 'react-redux';
import CustomButton from '../../CustomButton';
import CustomTextInput from '../../CustomTextInput';
import { editMultiUserGroup, getUserGroup } from '../../../redux/actions/groupcompaginAction';
import { getfreeuser } from '../../../redux/actions/freeuserAction';
import { CloseOutlined } from '@ant-design/icons'; 

const { Option } = Select;

const EditMultiGroup = ({ visible, onClose, setEditNotificationmodal, editgroup,setMultieditgroup,setUserchecked }) => {
  const user = useSelector((state) => state?.Auth?.userDetails?.user);

  // Initialize state for multiple groups
  const [groups, setGroups] = useState([]);
  const freeuser = useSelector((state) => state?.freeuserReducer?.allsearchfreeuser);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [selecteduserid,setSelectedUserid]=useState([])


  

  

  // Handles the selection of users for each group
  // const handleSelectChange = (groupId, selectedUserIds) => {
  //   const updatedGroups = groups.map(group => {
  //     if (group._id === groupId) {
  //       // Map user IDs back to user objects
  //       const selectedUsers = freeuser?.freeuser.filter(u => selectedUserIds.includes(u._id));
  //       return { ...group, users: selectedUsers };
  //     }
  //     return group;
  //   });

  //   setGroups(updatedGroups);
  // };

console.log('groups',groups);



const handleSelectChange = (selectedUserIds) => {
  // Get the selected users based on the value (user _id)
  const selectedUserObjects = freeuser?.freeuser?.filter(user => selectedUserIds.includes(user._id));

  // Update the selectedUserId state with the full user objects
  setSelectedUserid(selectedUserObjects);

  // Apply selected users to all groups
  const updatedGroups = groups.map(group => {
    // Merge new selections with the previous ones for each group
    const updatedUsers = [...group.users, ...selectedUserObjects];

    // Remove duplicate users based on their _id
    const uniqueUsers = updatedUsers.filter((value, index, self) =>
      index === self.findIndex((t) => t._id === value._id)
    );

    return { ...group, users: uniqueUsers };
  });

  // Update the state with the modified groups
  setGroups(updatedGroups);
};


  

const handleRemoveUser = (userId) => {
  // Remove the user from the selectedUserId state
  const updatedSelectedUsers = selecteduserid.filter(user => user._id !== userId);
  setSelectedUserid(updatedSelectedUsers);

  // Remove the user from all groups
  const updatedGroups = groups.map(group => {
    const updatedUsers = group.users.filter(user => user._id !== userId);
    return { ...group, users: updatedUsers };
  });

  // Update the state with the modified groups
  setGroups(updatedGroups);
};



  


  // Cancel button handler
  const cancel = () => {
    onClose();
  };
  const onSearch = async (value) => {
    setSearchTerm(value); // Set search term in state
    if (value.trim() === '') {
      // If search term is empty, load all users
      await getfreeuser({ organizationid: user.organizationid, search: '' });
      setOpen(true)
    } else {
      // Trigger search API call
      let inmodal=true;
      let payload = {
        organizationid: user.organizationid,
        search: value,
        inmodal,
      };
      await getfreeuser(payload);
      setOpen(true)
    }
  };

  useEffect(() => {
    if (editgroup) {
      // Set groups to the incoming data
      setGroups(editgroup); // Assume editgroup is an array of group objects
    }
  }, [editgroup]);

  // Handles saving the updated groups
  const onSave = async () => {
    const payload = groups.map(group => ({
      users: group.users.map(user => user._id), // Send user IDs in the payload
      id: group._id,
    }));

    console.log('Payload for saving groups:', payload);

    // Send the payload to the backend for update
    const res = await editMultiUserGroup(payload);

    if (res?.data) {
      const checkPayload = {
        organizationid: user.organizationid,
        page:1,
      };
      await getUserGroup(checkPayload);
      await getfreeuser(checkPayload)
      // Reload user groups

      cancel();
      setEditNotificationmodal(true); 
      // setMultieditgroup([])
      // setUserchecked([])
      // Show success modal
    }
  };

  return (
    <Modal
      className="addusermodal"
      title="Edit Group"
      open={visible}
      onCancel={onClose}
      centered
      footer={[]}
    >
      <h1>Edit Group</h1>
      <p>Please update the details for the groups.</p>

      <div className="add-user-main">
  <p>Search Users</p>
  <Input.Search
    placeholder="Search user..."
    value={searchTerm}
    // onChange={(e) => setSearchTerm(e.target.value)} 
    onChange={(e) => onSearch(e.target.value)} 
    onSearch={onSearch}
    enterButton
  />

  <p style={{ paddingTop: '20px' }}>Add Users</p>
  <Select
    className="dropdown"
   // mode="multiple"
  //  value={selectedUserIds.map(user => user._id)}  // Show the selected users in the dropdown
  onChange={handleSelectChange} // Update selected users
  placeholder="Select Users"
  style={{ width: '100%' }}
  open={open}
  onBlur={() => setOpen(false)} 
  onSearch={onSearch}
  >
    {freeuser?.freeuser?.map((user) => (
      <Option key={user._id} value={user._id}>
        {user.Name}  
      </Option>
    ))}
  </Select>
   {selecteduserid.length > 0 && (
      <div className='removeitem'>
        {selecteduserid?.map((item) => (
          <div className='removebox' key={item._id} style={{ display: 'flex', alignItems: 'center',flexDirection:'row' }}>
            <span style={{ color:  '#EF4444'}}>{item.Name}</span>
            <CloseOutlined
            size={10}
              onClick={() => handleRemoveUser(item._id)} 
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>
    )}

</div>



       

      <div className="btn">
        <CustomButton style={{ marginBottom: '0px' }} onClick={onSave} text="Save" />
        <CustomButton style={{ marginBottom: '0px' }} onClick={cancel} text="Cancel" />
      </div>
    </Modal>
  );
};

export default EditMultiGroup;
