
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import Search from "antd/es/transfer/search";
import Sidebar from "../../Components/Sidebar"
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Components/Modal/Deletemodal";
import { useEffect, useState } from "react";
import DeleteNotification from "../../Components/Notification/DeleteNotification";
import OrganizationTable from "../../Components/Table/OrganizationTable";
import { useSelector } from "react-redux";
import { GetMyOrganizationUser } from "../../redux/actions/myorganization.action";
import { getUser } from "../../redux/actions/authAction";


const MyOrganization = () => {

    const [deletenotificationmodal, setDeleteNotificationmodal] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [checked, setchecked] = useState(false)

  
    useEffect(() => {
        const fetchData = async () => {
            try {
                let organizationid = localStorage.getItem('organizationid');
               
             
                let myorganizationpayload={organization_ids:[organizationid]}

             

         await getUser(organizationid);
               
                await GetMyOrganizationUser(myorganizationpayload)

                console.log('All API calls completed successfully');
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetchData function

    }, []);
      const AllMyOrganzationUsers = useSelector((state) => state?.myorganizationReducers?.AllMyOrganzationUsers)

      console.log('AllMyOrganzationUsers',AllMyOrganzationUsers);
      

    
   
    // const navigation = useNavigate()
    // const deletemodal = () => {
    //     setModalVisible(true)

    // }

    const onSearch = () => {

    }

    return (

        <>

            <div className="dashboard">

                <Sidebar />
                <div className="dashboard-right-side">
                    <div className="headerbox">

                        <div className="headertext">
                            My Organization
                        </div>



                    </div>




                    <div className="phisingcampagains">

                        <div className="mainheadingdiv">

                            <div style={{ width: '80%' }}>
                                <p className="organization-para">Users Who Are Linked to your Account</p>



                            </div>

{/* 
                            <img
                                alt=""
                                style={{ position: 'relative', cursor: 'pointer', top: '10px' }}
                                width={30}
                                height={30}
                                src={deletebtn}
                                onClick={deletemodal}
                            /> */}

                            <div className="searchbox">

                                <Search placeholder="Search user...." onSearch={onSearch} enterButton />
                            </div>

                        </div>



                        <OrganizationTable
                            Individualuser={AllMyOrganzationUsers?.data?.members}
                            setchecked={setchecked}
                        />


                    </div>




                </div>
            </div>


            <DeleteModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                //    currentRoute={}
                setNotificationmodal={setDeleteNotificationmodal}
            />

            <DeleteNotification
                visible={deletenotificationmodal}
                onClose={() => setDeleteNotificationmodal(false)}

            />

        </>
    )
}

export default MyOrganization;