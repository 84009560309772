import React from 'react'
import { Modal } from 'antd'

import CustomButton from '../../Components/CustomButton'
import { deleteCompagain, getCompagain } from '../../redux/actions/compagainaction';
import { useSelector } from 'react-redux';
import { DeleteMemberOrganizationUser } from '../../redux/actions/myorganization.action';



const DeleteMembermodal = ({ visible, onClose, setNotificationmodal, selectedmemberid }) => {


    const user = useSelector((state) => state?.Auth?.userDetails?.user)
    //   console.log('selectedmemberid',selectedmemberid);


    const cancel = () => {
        // setPlayerInfo({})
        onClose()
    }

    const onsave = async () => {





        try {

            let payload = {
                organization_id: user.organizationid,
                member_id: String(selectedmemberid)

            }


            console.log('payload', payload);


            const res = await DeleteMemberOrganizationUser(payload)

            if (res?.data) {
                cancel()
                setNotificationmodal(true)
            }




        } catch (error) {

            console.log('error', error);


        }



    }



    return (
        <Modal
            className='deletemodal'
            title=''
            open={visible}
            onCancel={onClose}
            centered
            footer={[



            ]}

        >


            <h1>

                Delete Member

            </h1>



            <p>

                Do you want to delete this member ?



            </p>

            <div className='btn'>

                <CustomButton onClick={onsave} text={'Yes'} />


                <CustomButton onClick={cancel} text={'No'} />

            </div>
        </Modal>




    )
}

export default DeleteMembermodal
