

const initialState = {
    AllTemplate:[]
  
  }
  
  const templateReducer = (state = initialState, action) => {
    const { type, payload } = action
    
    switch (type) {
      case 'SET_ALL_TEMPLATE': {
        return {
          ...state,
          AllTemplate: payload,
        
        }
      }
  
    
  
  
  
      default:
        return state
    }
  }
  
  export default templateReducer