
import Sidebar from "../../Components/Sidebar"

import addcircle from "../../assest/add-circle.png"
import edit from "../../assest/Button.png";
import fileupload from "../../assest/file-upload 01.png"
import React, { useEffect, useState } from 'react';
import IndividualUserTable from "../../Components/Table/IndividualUserTable";
import UserGroupTable from "../../Components/Table/UserGroupTable";
import deletebtn from "../../assest/newButton.png";
import AddUserModal from "../../Components/Modal/AddUsermodal";
import ImportUserModal from "../../Components/Modal/ImportUsermodal";
import CreateUserGroupModal from "../../Components/Modal/CreateUserGroup";
import { useLocation } from 'react-router-dom';
import DeleteNotification from "../../Components/Notification/DeleteNotification";
import UpdateNotification from "../../Components/Notification/UpdateNotification";
import EditModal from "../../Components/Modal/EditModal";
import EditGroup from "../../Components/Modal/EditGroup";
import DeleteGroupmodal from "../../Components/Modal/DeleteGroupModal";
import DeleteGroupNotification from "../../Components/Notification/DeleteGroupNotification";
import { useSelector } from "react-redux";
import DeleteUsermodal from "../../Components/Modal/DeleteUsermodal";
import EditUserModal from "../../Components/Modal/MultiEdit/EditUserModal";
import EditMultiGroup from "../../Components/Modal/MultiEdit/EditMultiGroup";
import { Input } from 'antd';
import { getfreeuser } from "../../redux/actions/freeuserAction";
import { getUserGroup } from "../../redux/actions/groupcompaginAction";
import { getAllUser, getUser } from "../../redux/actions/authAction";

// import { useLocation } from "react-router-dom";
const TargetUser = () => {

    const { Search } = Input;

    const [addusermodalVisible, setAddUserModalVisible] = useState(false)
    const [importusermodalVisible, setImportUserModalVisible] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false)
    const [checked, setchecked] = useState([])
    const [selectAll, setSelectAll] = useState(false);
    const [selectAllGroup, setSelectAllGroup] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const [modalVisible, setModalVisible] = useState(false)
    const [editmodalVisible, setEditModalVisible] = useState(false)
    const [editnotificationmodal, setEditNotificationmodal] = useState(false)
    const [deletenotificationmodal, setDeleteNotificationmodal] = useState(false)
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [multieditmodalVisible, setMultiEditModalVisible] = useState(false)
    const [multieditfreeuser, setMultieditfreeuser] = useState([])
    const [multieditgroupmodalVisible, setMultiEditGroupModalVisible] = useState(false)
    const [multieditgroup, setMultieditgroup] = useState([])
    const [usergroup, setUsergroup] = useState(false);
    const myusergroup = useSelector((state) => state?.Groupreducer?.AllusersGroups)
    const freeuser = useSelector((state) => state?.freeuserReducer?.allfreeusers)
    const userdetails = useSelector((state) => state?.Auth?.userDetails?.user)
    const location = useLocation();

    const adduserbtn = () => {
        setAddUserModalVisible(true)


    }

    useEffect(() => {
    
        if (location.state?.usergroup === true) {
          setUsergroup(true);
        }
      }, [location]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let organizationid = localStorage.getItem('organizationid');
                 let page = 1;
                let payload = { organizationid,page };

                console.log('organizationid', organizationid);


                // Await each function one after the other
                await getAllUser();
                await getUser(organizationid);
                await getUserGroup(payload);

                await getfreeuser(payload);

                console.log('All API calls completed successfully');
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetchData function

    }, []);


    const importuserbtn = () => {
        setImportUserModalVisible(true)
    }

    const handleCheckboxChange = (e, record) => {
        const { checked } = e.target;

        // Update the selected checkboxes state
        setSelectedCheckboxes((prevSelected) => {
            if (checked) {
                // Add record to the selected checkboxes
                return [...prevSelected, record._id];
            } else {
                // Remove record from the selected checkboxes
                return prevSelected.filter((key) => key !== record._id);
            }
        });

        setMultieditgroup((prevSelected) => {
            if (checked) {
                // Add campaign.id to the selected checkboxes
                return [...prevSelected, record];
            } else {
                // Remove campaign.id from the selected checkboxes
                return prevSelected.filter((id) => id !== record);
            }
        });



    };


    const handleSelectAllChange = () => {
        if (selectAll) {
          // If selectAll is true, deselect all
          setchecked([]);
        } else {
          // If selectAll is false, select all campaigns
        
          setchecked(freeuser?.freeuser?.map(campaign => campaign._id));
          setMultieditfreeuser(freeuser?.freeuser?.map(campaign => campaign));
        }
        setSelectAll(!selectAll); 
      };



      const handleSelectAllGroupChange = () => {
        if (selectAllGroup) {
          // If selectAll is true, deselect all
          setSelectedCheckboxes([]);
        } else {
          // If selectAll is false, select all campaigns
        
          setSelectedCheckboxes(myusergroup?.usergroup?.map(campaign => campaign._id));
          setMultieditgroup(myusergroup?.usergroup?.map(campaign => campaign));
        }
        setSelectAllGroup(!selectAllGroup); 
      };

    const IndividualhandleCheckboxChange = (e, record) => {
        const { checked } = e.target;

        console.log('record', record);


        // Update the selected checkboxes state
        setchecked((prevSelected) => {
            if (checked) {
                // Add record to the selected checkboxes
                return [...prevSelected, record._id];
            } else {
                // Remove record from the selected checkboxes
                return prevSelected.filter((key) => key !== record._id);
            }
        });
        setMultieditfreeuser((prevSelected) => {
            if (checked) {
                // Add campaign.id to the selected checkboxes
                return [...prevSelected, record];
            } else {
                // Remove campaign.id from the selected checkboxes
                return prevSelected.filter((id) => id !== record);
            }
        });
    };





    const onSearch = async (value) => {

       


        
        try {


            let combinedSearch = value;
            if (multieditfreeuser.length > 0) {
             
                const names = multieditfreeuser.map(user => user.Name).join(', ');
            
               
                combinedSearch += ', ' + names;
            
           
            }

            let payload = {
                organizationid: userdetails.organizationid,
                search: combinedSearch,

            }

            // let payload = {
            //     organizationid: userdetails.organizationid,
            //     search: value,

            // }

            console.log('payload', payload);


            await getfreeuser(payload);

        } catch (error) {
            console.log('error', error);

        }

    }



    const onGroupSearch = async (value) => {



        try {

            let combinedSearch = value;
            if (multieditgroup.length > 0) {
             
                const names = multieditgroup.map(user => user.name).join(', ');
            
               
                combinedSearch += ', ' + names;
            
           
            
            }

            

            let payload = {
                organizationid: userdetails.organizationid,
                search: combinedSearch,

            }

            console.log('payload', payload);


            await getUserGroup(payload);

        } catch (error) {
            console.log('error', error);

        }

    }



    const freeuseractive=async()=>{

        let organizationid = localStorage.getItem('organizationid');
                 let page = 1;
                let payload = { organizationid,page };

             

                
        setUsergroup(false); 
        await getfreeuser(payload);
        setSelectedCheckboxes([]) 
        setSearchValue('');


        
    }



    const groupactive=async()=>{

        let organizationid = localStorage.getItem('organizationid');
                 let page = 1;
                let payload = { organizationid,page };
                // onClick={() => { setUsergroup(true); setchecked([]) }}
             

              
                setUsergroup(true); 
                await getUserGroup(payload);
                setchecked([])
                setSearchValue('');


        
    }


    return (

        <>

            <div className="dashboard">

                <Sidebar />
                <div className="dashboard-right-side">
                    <div className="headerbox">

                        <div className="headertext">
                            Target Users
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                            <div onClick={importuserbtn} style={{ background: '#FFF', color: '#EF4444', border: '1px solid #EF4444', cursor: 'pointer' }} className="createcompaignbutton">
                                <img alt="" className="createcompaignimg" src={fileupload}></img>
                                Import User
                            </div>
                            {/* <div onClick={adduserbtn} className="createcompaignbutton">
                                <img alt="" className="createcompaignimg" src={addcircle}></img>
                           {{usergroup} ? New Group:    New User}
                            </div> */}
                            <div onClick={adduserbtn} className="createcompaignbutton">
                                <img alt="" className="createcompaignimg" src={addcircle} />
                                {usergroup ? "New Group" : "New User"}
                            </div>

                        </div>
                    </div>






                    <div className="phisingcampagains">
                        <div className="selectedbox">
                            <p onClick={freeuseractive}
                                className={usergroup ? "notselectedclass" : "selectedclass"}>Individual Users</p>
                            <p onClick={groupactive}
                                className={usergroup ? "selectedclass" : "notselectedclass"}
                            //className="notselectedclass"
                            >
                                Groups
                            </p>
                            {/* <div className="horizontal-line"></div> */}
                        </div>

                        <div className="mainheadingdiv">

                            <div style={{ width: '80%' }}>
                                {!usergroup ? <p className="heading-div">
                                    Individual Users
                                </p>
                                    :

                                    <p className="heading-div">
                                        Groups
                                    </p>
                                }



                            </div>
                            <div className="d-flex">
                                <div className="d-flex">
                                    {selectedCheckboxes.length > 1 && (
                                        <>


                                            <img
                                                alt=""
                                                //style={{ position: 'relative', top: '10px', cursor: 'pointer', right: '20px' }}
                                                style={{ cursor: 'pointer' }}
                                                width={30}
                                                height={30}
                                                src={edit}
                                                onClick={() => {
                                                    setMultiEditGroupModalVisible(true)
                                                }}
                                            />
                                            <img
                                                alt=""
                                                style={{ cursor: 'pointer' }}
                                                // style={{ position: 'relative', top: '10px', cursor: 'pointer', right: '1%' }}
                                                width={30}
                                                height={30}
                                                src={deletebtn}
                                                onClick={() => {
                                                    setModalVisible(true)
                                                }}
                                            />
                                        </>
                                    )}
                                </div>

                                <div className="d-flex">
                                    {checked.length > 1 && (
                                        <>
                                            <img
                                                alt=""
                                                style={{ cursor: 'pointer' }}
                                                //style={{ position: 'relative', top: '10px', cursor: 'pointer', right: '20px' }}
                                                width={30}
                                                height={30}
                                                src={edit}
                                                onClick={() => {
                                                    setMultiEditModalVisible(true)
                                                }}
                                            />
                                            <img
                                                alt=""
                                                style={{ cursor: 'pointer' }}
                                                // style={{ position: 'relative', top: '10px', cursor: 'pointer', right: '1%' }}
                                                width={30}
                                                height={30}
                                                src={deletebtn}
                                                onClick={() => {
                                                    setModalVisible(true)
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className="searchbox">

                                    {!usergroup ?

                                        <Search placeholder="Search user...."  value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onSearch={onSearch} onPressEnter={(e) => onSearch(e.target.value)} enterButton />
                                        :

                                        <Search placeholder="Search user...."  value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onSearch={onGroupSearch} onPressEnter={(e) => onGroupSearch(e.target.value)} enterButton />
                                    }


                                </div>
                            </div>
                        </div>



                        {!usergroup ?
                            <IndividualUserTable Individualuser={freeuser} checked={checked} onCheckboxChange={IndividualhandleCheckboxChange} setchecked={setchecked} onAllCheckboxChange={handleSelectAllChange} /> : <UserGroupTable usergroupcolmn={myusergroup} selectedCheckboxes={selectedCheckboxes} onCheckboxChange={handleCheckboxChange} onallCheckboxChange={handleSelectAllGroupChange} />
                        }









                    </div>




                </div>
            </div>


            {!usergroup ?

                <AddUserModal
                    visible={addusermodalVisible}
                    onClose={() => setAddUserModalVisible(false)}
                    setNotificationmodal={setNotificationmodal}
                />

                :
                <CreateUserGroupModal
                    visible={addusermodalVisible}
                    onClose={() => setAddUserModalVisible(false)}
                    setNotificationmodal={setNotificationmodal}
                />




            }





            <ImportUserModal
                visible={importusermodalVisible}
                onClose={() => setImportUserModalVisible(false)}

            />

            {/* <DeleteModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                //    currentRoute={}
                setNotificationmodal={setDeleteNotificationmodal}
            />


            <DeleteNotification
                visible={deletenotificationmodal}
                onClose={() => setDeleteNotificationmodal(false)}

            /> */}


            {
                !usergroup ? (
                    <>
                        {/* <DeleteModal
                            visible={modalVisible}
                            onClose={() => setModalVisible(false)}
                            setNotificationmodal={setDeleteNotificationmodal}
                        /> */}
                        <DeleteUsermodal
                            visible={modalVisible}
                            onClose={() => setModalVisible(false)}
                            checked={checked}
                            setchecked={setchecked}
                            setNotificationmodal={setDeleteNotificationmodal}
                            multieditfreeuser={multieditfreeuser}
                        />


                        <DeleteNotification
                            visible={deletenotificationmodal}
                            onClose={() => setDeleteNotificationmodal(false)}
                        />
                    </>
                ) : (
                    <>
                        <DeleteGroupmodal
                            visible={modalVisible}
                            onClose={() => setModalVisible(false)}
                            setNotificationmodal={setDeleteNotificationmodal}
                            selectedCheckboxes={selectedCheckboxes}
                        />
                        <DeleteGroupNotification
                            visible={deletenotificationmodal}
                            onClose={() => setDeleteNotificationmodal(false)}
                        />
                    </>
                )
            }



            {
                !usergroup ? (
                    <EditModal
                        visible={editmodalVisible}
                        onClose={() => setEditModalVisible(false)}
                        setEditNotificationmodal={setEditNotificationmodal}
                    />

                ) : (
                    <EditGroup
                        visible={editmodalVisible}
                        onClose={() => setEditModalVisible(false)}
                        setEditNotificationmodal={setEditNotificationmodal}
                    />
                )
            }



            {/* <EditModal
                visible={editmodalVisible}
                onClose={() => setEditModalVisible(false)}
                //    currentRoute={}
                setEditNotificationmodal={setEditNotificationmodal}
                
            />
           */}



            <EditUserModal
                visible={multieditmodalVisible}
                onClose={() => setMultiEditModalVisible(false)}
                setEditNotificationmodal={setEditNotificationmodal}
                editfreeuser={multieditfreeuser}

            />


            <EditMultiGroup
                visible={multieditgroupmodalVisible}
                onClose={() => setMultiEditGroupModalVisible(false)}
                //    currentRoute={}
                setEditNotificationmodal={setEditNotificationmodal}
                editgroup={multieditgroup}

            />

            <UpdateNotification
                visible={editnotificationmodal}
                onClose={() => setEditNotificationmodal(false)}
            />



        </>

    )
}

export default TargetUser;