import React from 'react';
import { Input } from 'antd'; // TextArea is part of Input
import PropTypes from 'prop-types';

const { TextArea } = Input;

const CustomTextArea = ({ value, onChange, placeholder, disabled, width, style, required, ...props }) => {
  // Properly handle the onChange event
  const handleChange = (e) => {
    onChange(e.target.value); // Pass the new value to the onChange prop
  };

  const textAreaStyle = {
    width, 
    height: style?.height || 'auto',  // Ensure the height is applied correctly
    paddingTop: '5px', // Push content to the top if necessary
    lineHeight: 'normal', // Make sure line height is normal for text to flow
    resize: 'none', // Optional: Prevent resizing the textarea
    ...style, // Merge with the custom style
  };

  return (
    <TextArea
      value={value}
      onChange={handleChange} // Use the handleChange function
      placeholder={placeholder}
      disabled={disabled}
      style={textAreaStyle}
      className='custominput'
      required={required}
      {...props}
    />
  );
};

CustomTextArea.propTypes = {
  value: PropTypes.string, // value should be a string
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  required: PropTypes.bool,
};

CustomTextArea.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
  width: '100%', // Default width
  style: {}, // Default empty style
  required: false,
};

export default CustomTextArea;
