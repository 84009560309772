import React from 'react'
import { Modal } from 'antd'



import success from "../../assest/success.png"

import CustomButton from '../CustomButton'
import { useNavigate } from 'react-router-dom'





const CompagainNotification = ({ visible, onClose,   campaign}) => {


const navigation=useNavigate()

   
//  console.log('user',user?.team?.currentLeague?.name);


console.log('hchcch',   campaign);


  const cancel = () => {
    // setPlayerInfo({})
    onClose()
    navigation('/dashboard/overview')
  }


  


  return (
    <Modal
      className='addnotification'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   

<div className='notificationmain'>
<img className='successimg' src={success} alt=""></img>


{campaign ?
  <h3 className="successs">
Successfully Edited!

    <p>Campaign has successfully edited</p>
</h3>
:


<h3 className="successs">
Successfully Created!

    <p>Campaign has been created successfully</p>
</h3>


}








<CustomButton onClick={cancel} text={'Overview'} />


</div>


   









    </Modal>
  )
}

export default CompagainNotification
