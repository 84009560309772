import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { notification } from 'antd';

const ProtectedRoute = ({ children }) => {
  useEffect(() => {
    const token = localStorage.getItem('token');

    // If no token is found, show an error notification
    if (!token) {
      notification.error({
        message: 'Unauthorized Access',
        description: 'You need to log in to access this page.',
        placement: 'topRight',
      });
    }
  }, []);

  const token = localStorage.getItem('token');

  // If there's no token, show the error notification and redirect
  if (!token) {
    return <Navigate to="/" replace />;
  }

  // If the token exists, render the protected route's children
  return children;
};

export default ProtectedRoute;
