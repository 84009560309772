// import { createStore, applyMiddleware } from 'redux'
// import rootReducer from './rootReducer'
// import thunk from 'redux-thunk'
// import { composeWithDevTools } from 'redux-devtools-extension'

// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

// export default store


import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer'; // Assuming you have a root reducer file

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
