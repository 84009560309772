import { notification } from 'antd'
import { publicAPI } from '../../Config/Constant'
import store from '../store'
import { getfreeuser } from './freeuserAction';



export const addUserGroup = async(payload) => {
 
    console.log('payload here',payload);



     try {
       const res = await publicAPI.post('/usergroup/add', payload)
       if (res) {

           // console.log('res',res);
           
       
         if (res) {

            console.log('res',res);

    //         let newpayload={
    //             organizationid:  res.data.data.data.organizationid
    //         }
        
    // await getUserGroup(newpayload)
            // localStorage.setItem('date', res?.data?.data?.setting?.date)
            
 


 
 
           notification.success({
             description: res.data.data.message,
             duration: 2,
           })
        //    navigate('/dashboard/overview')
            return res
           // }
         } 
         

       }
     } catch (err) {
       console.error('Error during login:', err); 
       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }



 export const getUserGroup = async(payload) => {
 
    console.log('payload here',payload);



     try {
       const res = await publicAPI.post('/usergroup/get', payload)
       if (res) {

           // console.log('res',res);
           
       
         if (res) {
          
            store.dispatch({
                type: 'SET_USER_GROUP',
                payload: res.data.data,
              })

       
    
            return res
           // }
         } 
         

       }
     } catch (err) {
       console.error('Error during login:', err); 
       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }


 // edit

 export const editUserGroup = async(payload) => {
 
  console.log('payload here',payload);



   try {
     const res = await publicAPI.post('/usergroup/edit', payload)

     if (res) {

      console.log('res',res);

       




     notification.success({
       description: res.data.data.message,
       duration: 2,
     })
    }
     return res
   } catch (err) {
     console.error('Error during login:', err); 
     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}


export const editMultiUserGroup = async(payload) => {
 
  console.log('payload here',payload);



   try {
     const res = await publicAPI.post('/usergroup/mult-edit', payload)

     if (res) {

      console.log('res',res);

      



     notification.success({
       description: res.data.data.message,
       duration: 2,
     })
    }
     return res
   } catch (err) {
     console.error('Error during login:', err); 
     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}




 // delete

 export const deleteUserGroup = async(payload) => {
 




   try {
     const res = await publicAPI.post('/usergroup/delete', payload)
     if (res) {

         // console.log('res',res);
         
     
       if (res) {

          console.log('res',res);

          



         notification.success({
           description: res.data.data.message,
           duration: 2,
         })
      //    navigate('/dashboard/overview')
          return res
         // }
       } 
       

     }
   } catch (err) {
     console.error('Error during login:', err); 
     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}

