
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import Sidebar from "../../Components/Sidebar"

import addcircle from "../../assest/add-circle.png"
import CampaignsTable from "../../Components/Table/CompaigainTable";
import { useLocation, useNavigate } from "react-router-dom";
import overview from "../../assest/dashboard b.png";
import targetgroup from "../../assest/2.png";
import targetuser from "../../assest/dashboard a.png";
import { useEffect, useState } from "react";

import deletebtn from "../../assest/newButton.png";
import DeleteModal from "../../Components/Modal/Deletemodal";
import DeleteNotification from "../../Components/Notification/DeleteNotification";
import UpdateNotification from "../../Components/Notification/UpdateNotification";

import ChartCard from "../../Components/Charts/Graph";
import EditCompagain from "../../Components/Modal/EditCompagain";
import { useSelector } from "react-redux";
import { Input } from 'antd';
import { getCompagain } from "../../redux/actions/compagainaction";

const MyCompaigns = () => {

    const { Search } = Input;

    useEffect(() => {
        const fetchData = async () => {
            try {
                let organizationid = localStorage.getItem('organizationid');
                let page = 1;
                let payload = { organizationid, page };

                console.log('organizationid', organizationid);


                // Await each function one after the other
                await getCompagain(payload);

                console.log('All API calls completed successfully');
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetchData function

    }, []);

    const allcompaign = useSelector((state) => state?.compaignReducer?.Allcompaign)

    const userdetails = useSelector((state) => state?.Auth?.userDetails?.user)

    const navigation = useNavigate()
    const location = useLocation();
    const currentRoute = location.pathname

    const [selectedCard, setSelectedCard] = useState(null);
    const [multicompagain,setMultiCompagin]=useState([])

    const handleCardClick = (cardIndex) => {
        setSelectedCard(cardIndex);
        setColornumber(cardIndex)
    };

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const [modalVisible, setModalVisible] = useState(false)
    const [editmodalVisible, setEditModalVisible] = useState(false)
    const [editnotificationmodal, setEditNotificationmodal] = useState(false)
    const [deletenotificationmodal, setDeleteNotificationmodal] = useState(false)
    const [colornumber, setColornumber] = useState(null)
    const [deletecomp, setDeletecomp] = useState(null)
    const [selectAll, setSelectAll] = useState(false);


    console.log('selectedCheckboxes', selectedCheckboxes);


    // const handleCheckboxChange = (e, record) => {
    //     const { checked } = e.target;


    //     console.log('checked', checked);

    //     console.log('record', record);



    //     // Update the selected checkboxes state
    //     setSelectedCheckboxes((prevSelected) => {
    //         if (checked) {
    //             // Add record to the selected checkboxes
    //             return [...prevSelected, record._id];

    //         } else {
    //             // Remove record from the selected checkboxes
    //             return prevSelected.filter((key) => key !== record._id);
    //         }

            
    //     });
    // };

    const handleCheckboxChange = (e, record) => {
        const { checked } = e.target;
    
        console.log('checked', checked);
        console.log('record', record);
    
        // Update the selected checkboxes state
        setSelectedCheckboxes((prevSelected) => {
            let updatedSelected = checked
                ? [...prevSelected, record._id]  // Add record to the selected checkboxes
                : prevSelected.filter((key) => key !== record._id);  // Remove record from selected checkboxes
    
            // Update the multiCompagin state
            // Here, we are adding or removing the record name based on whether the checkbox is checked
            setMultiCompagin(updatedSelected.map(id => record.name));  // Update multiCompagin with the selected names
    
            return updatedSelected;
        });
    };
    

    const handleSelectAllChange = () => {
        if (selectAll) {
          // If selectAll is true, deselect all
          setSelectedCheckboxes([]);
        } else {
          // If selectAll is false, select all campaigns
        
          setSelectedCheckboxes(allcompaign?.usercompaign?.map(campaign => campaign._id));
          setMultiCompagin(allcompaign?.usercompaign?.map(campaign => campaign.name))
        }
        setSelectAll(!selectAll); 
      };

    const onSearch = async (value) => {



        try {


            let combinedSearch = value;

            if (multicompagain.length > 0) {
                combinedSearch += ', ' + multicompagain.join(', ');
              }
          

            let payload = {
                organizationid: userdetails.organizationid,
                search: combinedSearch,

            }

            // let payload = {
            //     organizationid: userdetails.organizationid,
            //     search: value,

            // }

            console.log('payload', payload);


            await getCompagain(payload);

        } catch (error) {
            console.log('error', error);

        }

    }



    const dataYearly = [
        // Sample yearly data
        { name: 'Jan', value: 30 },
        { name: 'Feb', value: 20 },
        { name: 'Mar', value: 27 },
        { name: 'Apr', value: 23 },
        { name: 'May', value: 34 },
        { name: 'Jun', value: 45 },
        { name: 'Jul', value: 65 },
        { name: 'Aug', value: 55 },
        { name: 'Sep', value: 40 },
        { name: 'Oct', value: 30 },
        { name: 'Nov', value: 20 },
        { name: 'Dec', value: 25 }
    ];

    const dataMonthly = [
        // Sample monthly data
        { name: 'Week 1', value: 30 },
        { name: 'Week 2', value: 20 },
        { name: 'Week 3', value: 27 },
        { name: 'Week 4', value: 23 }
    ];

    const dataWeekly = [
        // Sample weekly data
        { name: 'Mon', value: 12 },
        { name: 'Tue', value: 18 },
        { name: 'Wed', value: 20 },
        { name: 'Thu', value: 24 },
        { name: 'Fri', value: 35 },
        { name: 'Sat', value: 40 },
        { name: 'Sun', value: 28 }
    ];

    const dataDaily = [
        // Sample daily data
        { name: '00:00', value: 5 },
        { name: '06:00', value: 10 },
        { name: '12:00', value: 20 },
        { name: '18:00', value: 15 },
        { name: '24:00', value: 25 }
    ];


    return (

        <div className="dashboard">

            <Sidebar />
            <div className="dashboard-right-side">
                <div className="headerbox">

                    <div className="headertext">
                        My Campaigns
                    </div>

                    <div onClick={() => {
                        navigation('/campaignflow')
                    }} className="createcompaignbutton">
                        <img alt="" className="createcompaignimg" src={addcircle}></img>
                        Create New Campaign
                    </div>
                </div>

                <div className="stats">
                    <div className="statsbox">
                        <div className="statsheading">Users Smished
                            <h1>
                                564
                            </h1>
                        </div>
                        <img className="statsimg" src={overview} alt="" />

                    </div>

                    <div className="statsbox">
                        <div className="statsheading">Users Entered Credentials
                            <h1>
                                142
                            </h1>
                        </div>
                        <img className="statsimg" src={targetgroup} alt="" />

                    </div>

                    <div className="statsbox">
                        <div className="statsheading">Users Clicked Linked
                            <h1>
                                112
                            </h1>
                        </div>
                        <img className="statsimg" src={targetuser} alt="" />

                    </div>


                </div>


                <div className="graphmain">
                    <ChartCard title="User Who Clicked" dataKey="value" color="#B91C1C" data={dataWeekly} selected={selectedCard === 0}
                        onClick={() => handleCardClick(0)} colornumber={colornumber} />
                    <ChartCard title="Users Clicked" dataKey="value" color="#15803D" data={dataWeekly} selected={selectedCard === 1}
                        onClick={() => handleCardClick(1)} colornumber={colornumber} />
                </div>


                <div className="phisingcampagains">
                    <p>
                        Phishing Simulation Campaigns
                    </p>


                    <div style={{ justifyContent: 'end' }} className="mainheadingdiv">

                        <div className="d-flex">

                            {selectedCheckboxes.length > 1 && (
                                <>
                                    {/* <img
                                        alt=""
                                        style={{ cursor: 'pointer' }}
                                        // style={{ position: 'relative', top: '14px', cursor: 'pointer', right: '12px' }}
                                        width={30}
                                        height={30}
                                        src={edit}
                                        onClick={() => {
                                            setEditModalVisible(true)
                                        }}
                                    /> */}
                                    <img
                                        alt=""
                                        style={{ cursor: 'pointer' }}
                                        //style={{ position: 'relative', top: '14px', cursor: 'pointer', right: '8px' }}
                                        width={30}
                                        height={30}
                                        src={deletebtn}
                                        onClick={() => {
                                            setModalVisible(true)
                                            setDeletecomp(true)
                                        }}
                                    />
                                </>
                            )}

                        </div>
                        <div style={{ paddingLeft: '12px' }} className="searchbox">

                            <Search placeholder="Search user...." onSearch={onSearch} onPressEnter={(e) => onSearch(e.target.value)} enterButton />
                        </div>

                    </div>



                    <CampaignsTable
                        campaigns={allcompaign}
                        selectedCheckboxes={selectedCheckboxes}
                        onCheckboxChange={handleCheckboxChange}
                        oncheckallboxchange={handleSelectAllChange}

                    />


                    <DeleteModal
                        visible={modalVisible}
                        onClose={() => setModalVisible(false)}
                        currentRoute={currentRoute}
                        setNotificationmodal={setDeleteNotificationmodal}
                        selectedCampaignId={selectedCheckboxes}

                    />


                    <DeleteNotification
                        visible={deletenotificationmodal}
                        onClose={() => setDeleteNotificationmodal(false)}
                        currentRoute={currentRoute}


                    />




                    <EditCompagain
                        visible={editmodalVisible}
                        onClose={() => setEditModalVisible(false)}
                        //    currentRoute={}
                        setEditNotificationmodal={setEditNotificationmodal}

                    />

                    <UpdateNotification
                        visible={editnotificationmodal}
                        onClose={() => setEditNotificationmodal(false)}
                    />


                </div>




            </div>
        </div>
    )
}

export default MyCompaigns;