import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'antd/dist/reset.css'

 import '../styles/style.css'
import Routes from './Routes'
// 
// import { version } from './constants'
// import { notification } from 'antd'



const App = () => {
  {} return <Routes />
}

export default App