

import webapplogo from "../assest/webapplogo.png"



const Emailsentpage = () => {



    // console.log('email', email);

    return (


        <div className='loginpagemain'>

            <div className='loginpage'>

                {/* <Image className='webimage' src={webapplogo}/> */}
                <img className='webimage' src={webapplogo} alt=''></img>



                <p>
                    Reset Your Password

                </p>


                <h2>Your password reset email has been sent to your email address. Please check your inbox.</h2>





            </div>

        </div>
    )
}

export default Emailsentpage