import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Login from '../pages/Login'
import Signup from '../pages/signup'
import Verfication from '../pages/Verfication/VerifyMobile'
import VerficationSuccess from '../pages/Verfication/Success'
import VerficationError from '../pages/Verfication/Error'
import AgreeTerms from '../pages/Verfication/AgreeTerms'
import Dashboard from '../pages/DashBoard'
import Compaigns from '../pages/DashBoard/Compaigns'
import TargetUser from '../pages/DashBoard/targetUser'
import Profile from '../pages/Profile'
import FreeuserCompaignFlow from '../pages/CompaignFlow/FreeuserCompaignFlow'
import MyOrganization from '../pages/DashBoard/myOrganization'
import ResetPassword from '../pages/resetPassword'
import Emailsentpage from '../pages/Emailsentpage'
import PrivateRoute from './PrivateRoute'

const Routers = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
            </Routes>

            <Routes>
                <Route path='/signup' element={<Signup />} />
            </Routes>


            <Routes>
                <Route path='/emailsent' element={<Emailsentpage />} />
            </Routes>


            <Routes>
                <Route path='/resetpassword' element={<ResetPassword />} />
            </Routes>


            <Routes>
                <Route path='/termsagree' element={<AgreeTerms />} />
            </Routes>



            <Routes>
                <Route path='/verfication' element={<Verfication />} />
            </Routes>



            <Routes>
                <Route path='/success' element={<VerficationSuccess />} />
            </Routes>


            <Routes>
                <Route path='/error' element={<VerficationError />} />
            </Routes>



            {/* {private Routes} */}

            <Routes>
                <Route path='/profile' element={

                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                } />
            </Routes>


            <Routes>
                <Route path='/dashboard/overview' element={

                    <PrivateRoute>

                        <Dashboard />
                    </PrivateRoute>



                } />
            </Routes>

            <Routes>
                <Route path='/dashboard/my-campaigns' element={
                    <PrivateRoute>
                        <Compaigns />
                    </PrivateRoute>


                } />
            </Routes>

            <Routes>
                <Route path='/dashboard/target-users' element={
                    <PrivateRoute>

                        <TargetUser />
                    </PrivateRoute>



                } />
            </Routes>


            <Routes>
                <Route path='/campaignflow' element={
                    <PrivateRoute>

                        <FreeuserCompaignFlow />
                    </PrivateRoute>



                } />
            </Routes>


            <Routes>
                <Route path='/myorganization' element={
                    <PrivateRoute>
                        <MyOrganization />
                    </PrivateRoute>



                } />
            </Routes>



        </BrowserRouter>
    )
}


export default Routers