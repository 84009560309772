import React, { useState } from 'react';
import { Table } from 'antd';
import edit from "../../assest/Button.png";
import deletebtnimg from "../../assest/newButton.png";
import { useLocation } from 'react-router-dom';
import UpdateNotification from '../Notification/UpdateNotification';
import moment from 'moment';
import EditMemberOrganization from '../Modal/EditMemberOrganization';
import DeleteMemberNotification from '../Notification/DeleteMemberNotification';
import DeleteMembermodal from '../Modal/DeleteMembermodal';

const OrganizationTable = ({ Individualuser ,setchecked}) => {

    const location = useLocation();
    const currentRoute = location.pathname

    // console.log('currentRoute',currentRoute);
console.log('Individualuser',Individualuser);


    // console.log('location',location);

    const [modalVisible, setModalVisible] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false)
    const [editmodalVisible, setEditModalVisible] = useState(false)
    const [editnotificationmodal, setEditNotificationmodal] = useState(false)
    const [member, SetMember] = useState(null)
    const [selectedmemberid,setSelectedmemberid]=useState(null)

    const deletebtn = (deleteitem) => {
        setSelectedmemberid(deleteitem)
        setModalVisible(true);
    }

    const editmodal = (item)=>{
        SetMember(item)
        setEditModalVisible(true)

    }
    const onChange = (e) => {
        setchecked(e.target.checked)
        console.log(`checked = ${e.target.checked}`);
    };
    const columns = [
        // {
        //     title: <Checkbox onChange={onChange}></Checkbox>,
        //     dataIndex: 'checkbox',
        //     // width:'30px',
        //     width: 10,
        //     key: 'checkbox',
        //     render: (_, record) => (
        //         <Checkbox onChange={onChange}></Checkbox>
        //     ),
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            //  width: 120,

        },

        {
            title: 'Date Joined',
            dataIndex: 'created_at',
            key: 'created_at',
             render: (created_at) => {
                            return moment(created_at).local().format('MMM D, YYYY hh:mm A z');  // Formats date
                        },
            
            // width: '20%',
            // width: 50,
        },
        {
            title: 'Email Address',
            dataIndex: 'email_address',
            key: 'email_address',
            // width: '50%',
        },
        {
            title: 'Phone Number',
            dataIndex: 'mfa_phone_number',
            key: 'mfa_phone_number',
            // width: '50%',
        },


        {
            title: 'User Role',
            dataIndex: 'roles',
            key: 'roles',
            render: (roles) => roles && roles[0] ? roles[0].role_id : 'No Role',
            // width: '18%',
            // width: '9%',
            // render: (text) => `${text} Users`,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            //  width: 10,
            render: (_, record) => (
                <div className="actions">
                     <img style={{cursor:'pointer'}}  onClick={() => editmodal(record)}   src={edit} alt="Edit" />
                     <img style={{cursor:'pointer'}} onClick={() => deletebtn(record.member_id)}   src={deletebtnimg} alt="Delete" />
                </div>
            ),
        },
    ];



    const dataSource = Individualuser?.map((campaign, index) => ({
        key: index,
        ...campaign,
    }));

// console.log('dataSource',dataSource);


    return (
        <>
            <Table
                className="table"
                dataSource={dataSource}
                columns={columns}

                pagination={true}
            />

            <DeleteMembermodal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                currentRoute={currentRoute}
                setNotificationmodal={setNotificationmodal}
                selectedmemberid={selectedmemberid}
            />




            <DeleteMemberNotification
                visible={notificationmodal}
                onClose={() => setNotificationmodal(false)}

            />

<EditMemberOrganization
 visible={editmodalVisible}
 onClose={() => setEditModalVisible(false)}
setEditNotificationmodal={setEditNotificationmodal}
member={member}

/>

<UpdateNotification
visible={editnotificationmodal}
onClose={() => setEditNotificationmodal(false)}
/>



        </>
    );
};

export default OrganizationTable;
