import React, { useState } from 'react';
import { Table, Checkbox } from 'antd';
import edit from "../../assest/Button.png";
import deletebtnimg from "../../assest/newButton.png";
import { useLocation } from 'react-router-dom';
import DeleteModal from '../Modal/Deletemodal';
import DeleteNotification from '../Notification/DeleteNotification';
import EditModal from '../Modal/EditModal';
import UpdateNotification from '../Notification/UpdateNotification';
import moment from 'moment';
import DeleteUsermodal from '../Modal/DeleteUsermodal';

const IndividualUserTable = ({ Individualuser ,checked,onCheckboxChange,setchecked,onAllCheckboxChange}) => {


    

    const location = useLocation();
    const currentRoute = location.pathname

    // console.log('currentRoute',currentRoute);


    // console.log('location',location);

    const [modalVisible, setModalVisible] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false)
    const [editmodalVisible, setEditModalVisible] = useState(false)
    const [editnotificationmodal, setEditNotificationmodal] = useState(false)
    const [editfreeuser, Seteditfreeuser] = useState(null)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
      });


    const deletebtn = (id) => {
        setchecked((prevState) => {
            // Add id to the selected campaign IDs, if not already present
            if (!prevState.includes(id)) {
                return [...prevState, id];
            }
            return prevState;
        });
        setModalVisible(true);
    }

    const editmodal = (compagain)=>{
        Seteditfreeuser(compagain)
        setEditModalVisible(true)

    }
    // const onChange = (e) => {
    //     setchecked(e.target.checked)
    //     console.log(`checked = ${e.target.checked}`);
    // };
    const columns = [
        // {
        //     title: <Checkbox onChange={onChange}></Checkbox>,
        //     dataIndex: 'checkbox',
        //     // width:'30px',
        //     width: 10,
        //     key: 'checkbox',
        //     render: (_, record) => (
        //         <Checkbox onChange={onChange}></Checkbox>
        //     ),
        // },


        {
            title: <Checkbox onChange={(e) => onAllCheckboxChange(e)} />,
            dataIndex: 'checkbox',
            // width:'30px',
            width: 10,
            key: 'checkbox',
            render: (_, record) => (
                // <Checkbox onChange={onChange}></Checkbox>
                <Checkbox 
                onChange={(e) => onCheckboxChange(e, record)} 
                checked={checked.includes(record._id)} 
            />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            //  width: 120,

        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            //width: '50%',
        },

        {
            title: 'Company',
            dataIndex: 'companyName',
            key: 'companyName',
            //width: '50%',
        },



        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            //width: '50%',
        },


        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            //width: '50%',
        },

        
        {
            title: 'Departments',
            dataIndex: 'departments',
            key: 'departments',
            //width: '50%',
        },

        
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (text) => {
              // Check if the text is longer than 10 characters, then truncate
              return text && text.length > 5 ? text.slice(0, 5) + '...' : text;
            }
          },
          

        {
            title: 'Date Joined',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => {
                return moment(createdAt).local().format('MMM D, YYYY hh:mm A z');  // Formats date
            },
            
            // width: '20%',
            // width: 50,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            // width: '18%',
            // width: '9%',
            // render: (text) => `${text} Users`,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            //  width: 10,
            render: (_, record) => (
                <div className="actions">
                    <img style={{cursor:'pointer'}}  onClick={() => editmodal(record)}   src={edit} alt="Edit" />
                    <img style={{cursor:'pointer'}} onClick={() => deletebtn(record._id)}   src={deletebtnimg} alt="Delete" />
                </div>
            ),
        },
    ];



    const dataSource = Individualuser?.freeuser?.map((campaign, index) => ({
        key: index,
        ...campaign,
    }));

console.log('dataSource',dataSource);


    return (
        <>
            <Table
                className="table"
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    current: pagination.current,       
                    pageSize: pagination.pageSize,     
                    total: dataSource,           
                    onChange: (page, pageSize) => {
                      setPagination({
                        current: page,       
                        pageSize: pageSize,  
                      });
                    },
                  }}
            />

            

            <DeleteUsermodal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                currentRoute={currentRoute}
                setNotificationmodal={setNotificationmodal}
                checked={checked}
                setchecked={setchecked}
            />


            
{/* <DeleteUsermodal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                checked={checked}
                setchecked={setchecked}
              

            // setNotificationmodal={setDeleteNotificationmodal}


            /> */}




            <DeleteNotification
                visible={notificationmodal}
                onClose={() => setNotificationmodal(false)}

            />

<EditModal
 visible={editmodalVisible}
 onClose={() => setEditModalVisible(false)}
//    currentRoute={}
setEditNotificationmodal={setEditNotificationmodal}
editfreeuser={editfreeuser}

/>

<UpdateNotification
visible={editnotificationmodal}
onClose={() => setEditNotificationmodal(false)}
/>



        </>
    );
};

export default IndividualUserTable;
