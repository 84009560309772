
import { notification } from 'antd'
import { publicAPI, attachToken, privateAPI, version, base_url } from '../../Config/Constant'
import store from '../store'





export const SETUSERDETAILS = (payload) => {
    return {
      type: 'SET_USER_DETAILS',
      payload: payload,
    }
  }



export const authSignup = async (payload,navigate) => {
  try {
    const res = await publicAPI.post('/auth/register', payload)
    if (res) {
      localStorage.setItem('version', version)
      localStorage.setItem('email', payload.email)
      localStorage.setItem('organizationid', res?.data?.data?.data?.member?.organization_id)
      console.log('res',res);
      attachToken()
      notification.success({
        description: res.data.data.message,
        duration: 2,
      })

    navigate('/emailsent')
    }
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message || 'Server Error',
      duration: 3,
    })
  }
}


export const authPassword = async (payload,navigate) => {
    try {
      const res = await publicAPI.post('/auth/password-create', payload)

     
      if (res) {
        console.log('auth password res',res);
        
        // localStorage.setItem('version', version)
         localStorage.setItem('sessiontoken',res.data.data.sessiontoken)
        attachToken()
        notification.success({
          description: res.data.data.message,
          duration: 2,
        })


        
      //   navigate('/authentication')
      navigate('/verfication')
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }



  export const authLogin = async(payload, navigate) => {
 
     console.log('payload here',payload);



      try {
        const res = await publicAPI.post('/auth/login', payload)
        if (res) {

            // console.log('res',res);
            
        
          if (res) {
           
            attachToken()
            localStorage.setItem('version', version)
            localStorage.setItem('token', res.data.data.token)
            localStorage.setItem('organizationid', res?.data?.data?.user?.organizationid)
            localStorage.setItem('email', res?.data?.data?.user?.email)
          await  getUser(res?.data?.data?.user?.organizationid)
     
             // localStorage.setItem('date', res?.data?.data?.setting?.date)
             
  
  
  
            notification.success({
              description: res.data.data.message,
              duration: 2,
            })

            // getUser()

            // store.dispatch(getUser())

            navigate('/dashboard/overview')
             return res
            // }
          } 
          

        }
      } catch (err) {
        console.error('Error during login:', err); 
        notification.error({
          message: err?.response?.data?.message || 'Server Error',
          duration: 3,
        })
      }
    
  }

  export const getUser = async(organizationid) => {
console.log('organizationid',organizationid);
let payload = {
    organizationid
}

      try {
        attachToken()
        const res = await publicAPI.post('/auth/get-user',payload)
        if (res) {
            console.log('res',res);

            console.log('res.data.data.user',res.data.data.user);
            
            
        //   dispatch({
        //     type: SET_USER_DETAILS,
        //     payload: res.data.data,
        //   })
        store.dispatch(SETUSERDETAILS(res.data.data))

        // store.dispatch(setAllclubhouse(res.data.data))
         
        }
      } catch (err) {
        notification.error({
          message: err?.response?.data?.message || 'Server Error',
          duration: 3,
        })
      }
    
  }


  export const UpdateProfile = async (payload) => {
    try {
      const res = await publicAPI.put('/auth/update-profile', payload)
      if (res) {
      await  getUser()
        // console.log('res',res);
        attachToken()
        notification.success({
          description: res.data.data.message,
          duration: 2,
        })
      //   navigate('/authentication')
      return res
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }


  export const SendOTP = async (payload) => {
    try {
      const res = await publicAPI.post('/auth/send-otp', payload)
      if (res) {
     
        // console.log('res',res);
        attachToken()
        notification.success({
          description: res.data.data.message,
          duration: 2,
        })
      //   navigate('/authentication')
      return res
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }
  


  export const AutheticateOTP = async (payload,navigate) => {
    try {
      const res = await publicAPI.post('/auth/authenticateotp', payload)
      if (res) {

        // console.log('res',res);
        attachToken()
        notification.success({
          description: res.data.data.message,
          duration: 2,
        })

       // return res
        navigate('/success')
      //   navigate('/authentication')
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }



  export const getAllUser = async (payload) => {
    
    
    try {
      const res = await publicAPI.post('/auth/getallusers', payload)
      if (res) {

        // console.log('res',res);
        attachToken()
        // notification.success({
        //   description: res.data.data.message,
        //   duration: 2,
        // })

       //  store.dispatch(SETALLUSERS(res.data.data))

        store.dispatch({
          type: 'SETALLUSERS',
          payload: res.data.data,
        })
      }

      
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }


  
  