import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import webapplogo from "../assest/sidebarlogi.png";
import breifcase from "../assest/briefcase 02.png";

import targetuser from "../assest/users 02.png";
import user from "../assest/user.png";
import Dropdownicon from "../assest/direction-down 01.png";

import oldbrodcast  from "../assest/old-broadcast.png"

import oldhome  from "../assest/old-home-arrow-up 01.png"
import logout from "../assest/logout.png"


const Sidebar = () => {
  const location = useLocation();
  const [selected, setSelected] = useState('');

  const menuItems = [
    { title: 'Overview', icon: oldhome, path: '/dashboard/overview' },
    { title: 'My Campaigns', icon: oldbrodcast, path: '/dashboard/my-campaigns' },
    { title: 'Target Users', icon: targetuser, path: '/dashboard/target-users' },
    // { title: 'Profile', icon: user, path: '/profile' },
    // { title: 'My Organization', icon: breifcase, path: '/myorganization' },
  ];
const navigate = useNavigate()


  const Profilenavigate =()=>{
    navigate('/profile')
  }
  const   organizationnavigate =()=>{
    navigate('/myorganization')
  }


  const HandleLogout =()=>{

    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('organizationid');
    localStorage.removeItem('version');

    navigate('/');

  }


  // Set the selected state based on the current path
  useEffect(() => {
    const currentPath = location.pathname;
    const currentItem = menuItems.find(item => item.path === currentPath);
    if (currentItem) {
      setSelected(currentItem.title);
    }
  }, [location.pathname]);

  return (
    <aside className="sidebar">
      <div className="logo">
        <img src={webapplogo} alt="Logo" />
      </div>
      <div className="menu">
        {menuItems.map((item, index) => (
          <Link
            key={index}
            to={item.path}
            style={{ textDecoration: 'none', color: '#3A3B3F' }}
            onClick={() => setSelected(item.title)}
          >
            <div className={`menu-item ${selected === item.title ? 'selected' : ''}`}>
              <img className="icon" src={item.icon} alt={item.title} />
              <span className="title">{item.title}</span>
            </div>
          </Link>
        ))}
      </div>

      <div style={{marginBottom:'16px'}} className="profile">
        <img className="profile-icon" src={breifcase} alt="User" />
        <span onClick={organizationnavigate} className="profile-text">My Organization</span>
       
      </div>

      <div className="profile">
        <img className="profile-icon" src={user} alt="User" />
        <span onClick={Profilenavigate} className="profile-text">My Profile</span>
        {/* <img className="profile-arrow" src={Dropdownicon} alt="Dropdown" /> */}
      </div>


      <div className="profile">

<img className="profile-icon" src={logout} alt="logout" />
        <span onClick={HandleLogout} className="profile-text">LogOut</span>
        {/* <img className="profile-arrow" src={Dropdownicon} alt="Dropdown" /> */}
      </div>
    </aside>
  );
};

export default Sidebar;
