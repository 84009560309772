
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { Checkbox } from "antd";
import Sidebar from "../../Components/Sidebar"
import overview from "../../assest/1.png";
import targetgroup from "../../assest/2.png";
import targetuser from "../../assest/3.png";
import edit from "../../assest/Button.png";
import deletebtn from "../../assest/newButton.png";
import addcircle from "../../assest/add-circle.png"
import AddUserModal from "../../Components/Modal/AddUsermodal";
import { useEffect, useState } from "react";
import CreateUserGroupModal from "../../Components/Modal/CreateUserGroup";
import DeleteModal from "../../Components/Modal/Deletemodal";
import DeleteNotification from "../../Components/Notification/DeleteNotification";
import AddedNotification from "../../Components/Notification/AddedNotification";
import { useNavigate } from "react-router-dom";
import EditModal from "../../Components/Modal/EditModal";
import UpdateNotification from "../../Components/Notification/UpdateNotification";
import EditGroup from "../../Components/Modal/EditGroup";
import EditCompagain from "../../Components/Modal/EditCompagain";
import { getAllUser, getUser } from "../../redux/actions/authAction";
import { useSelector } from "react-redux";
import { getUserGroup } from "../../redux/actions/groupcompaginAction";
import { getCompagain } from "../../redux/actions/compagainaction";
import moment from 'moment';
import { getfreeuser } from "../../redux/actions/freeuserAction";
import DeleteUsermodal from "../../Components/Modal/DeleteUsermodal";
import DeleteGroupmodal from "../../Components/Modal/DeleteGroupModal";
import EditUserModal from "../../Components/Modal/MultiEdit/EditUserModal";
import EditMultiGroup from "../../Components/Modal/MultiEdit/EditMultiGroup";
import { Input } from 'antd';
import { GetMyOrganizationUser } from "../../redux/actions/myorganization.action";


const Dashboard = () => {

    const navigation = useNavigate()

    const { Search } = Input;


    const usergroup = useSelector((state) => state?.Groupreducer?.AllusersGroups)

    const allcompaign = useSelector((state) => state?.compaignReducer?.Allcompaign)

    const freeuser = useSelector((state) => state?.freeuserReducer?.allfreeusers)
    const userdetails = useSelector((state) => state?.Auth?.userDetails?.user)



    const [addusermodalVisible, setAddUserModalVisible] = useState(false)
    const [addgroupusermodalVisible, setAddGroupUserModalVisible] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [groupmodalVisible, setGroupModalVisible] = useState(false)
    const [editmodalVisible, setEditModalVisible] = useState(false)
    const [editgroupmodalVisible, setEditGroupModalVisible] = useState(false)
    const [editcompaginmodalVisible, setEditcompaginModalVisible] = useState(false)
    const [editnotificationmodal, setEditNotificationmodal] = useState(false)
    const [deletenotificationmodal, setDeleteNotificationmodal] = useState(false)
    const [checked, setchecked] = useState([])
    const [phishingchecked, setPhishingchecked] = useState([])
    const [selectedCampaignId, setSelectedCampaignId] = useState([]);
    const [editCompagin, SeteditCompagin] = useState(null)
    const [editfreeuser, Seteditfreeuser] = useState(null)
    const [deletefreeuser, Setdeletefreeuser] = useState(false)
    const [userchecked, setUserchecked] = useState([])
    const [deletecomp, setDeletecomp] = useState([])
    const [multieditmodalVisible, setMultiEditModalVisible] = useState(false)
    const [multieditfreeuser, setMultieditfreeuser] = useState([])
    const [multicompagain, setMultiCompagin] = useState([])

    const [multieditgroupmodalVisible, setMultiEditGroupModalVisible] = useState(false)
    const [multieditgroup, setMultieditgroup] = useState([])

    console.log('phishingchecked', phishingchecked);

    const [selectAll, setSelectAll] = useState(false);
    const [selectAllfreeuser, setSelectAllFreeuser] = useState(false);
    const [selectAllusergroup, setSelectAllUsergroup,] = useState(false);
    const [editgroup,setEditGroup]=useState(null)

    const navigate = useNavigate()



    const handleSelectAllChange = () => {
        if (selectAll) {
            // If selectAll is true, deselect all
            setPhishingchecked([]);
        } else {
            // If selectAll is false, select all campaigns
            setPhishingchecked(allcompaign?.usercompaign?.map(campaign => campaign._id));
            setSelectedCampaignId(allcompaign?.usercompaign?.map(campaign => campaign._id));
        }
        setSelectAll(!selectAll);
    };


    const handleSelectAllUserGrouChange = () => {
        if (selectAllusergroup) {
            // If selectAll is true, deselect all
            setUserchecked([]);
        } else {
            // If selectAll is false, select all campaigns
            setUserchecked(usergroup?.usergroup?.map(campaign => campaign._id));
            setMultieditgroup(usergroup?.usergroup?.map(campaign => campaign));

        }
        setSelectAllUsergroup(!selectAllusergroup);
    };



    const handleSelectAllFreeuserChange = () => {
        if (selectAllfreeuser) {
            // If selectAll is true, deselect all
            setchecked([]);
        } else {
            // If selectAll is false, select all campaigns
            setchecked(freeuser?.freeuser?.map(campaign => campaign._id));
            setMultieditfreeuser(freeuser?.freeuser?.map(campaign => campaign));


        }
        setSelectAllFreeuser(!selectAllfreeuser);
    };

    console.log('editfreeuser', editfreeuser);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let organizationid = localStorage.getItem('organizationid');
                // let page = 1;
                let payload = { organizationid };
                let myorganizationpayload = { organization_ids: [organizationid] }

                console.log('organizationid', organizationid);


                // Await each function one after the other
                await getAllUser();
                await getUser(organizationid);
                await getUserGroup(payload);
                await getCompagain(payload);
                await getfreeuser(payload);
                await GetMyOrganizationUser(myorganizationpayload)

                console.log('All API calls completed successfully');
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetchData function

    }, []);

    const onSearch = async (value) => {

        console.log('multieditfreeuser', multieditfreeuser);


        try {


            let combinedSearch = value;
            if (multieditfreeuser.length > 0) {

                const names = multieditfreeuser.map(user => user.Name).join(', ');


                combinedSearch += ', ' + names;



            }

            let payload = {
                organizationid: userdetails.organizationid,
                search: combinedSearch,

            }


            // let payload = {
            //     organizationid: userdetails.organizationid,
            //     search: value,

            // }

            console.log('payload', payload);


            await getfreeuser(payload);

        } catch (error) {
            console.log('error', error);

        }

    }

    const onGroupSearch = async (value) => {



        try {


            let combinedSearch = value;
            if (multieditgroup.length > 0) {

                const names = multieditgroup.map(user => user.name).join(', ');


                combinedSearch += ', ' + names;



            }



            let payload = {
                organizationid: userdetails.organizationid,
                search: combinedSearch,

            }

            console.log('payload', payload);


            await getUserGroup(payload);

        } catch (error) {
            console.log('error', error);

        }

    }


    const onCompaingSearch = async (value) => {

        //  console.log('here compagin',multicompagain);


        try {

            let combinedSearch = value;

            if (multicompagain.length > 0) {
                combinedSearch += ', ' + multicompagain.join(', ');
            }


            let payload = {
                organizationid: userdetails.organizationid,
                search: combinedSearch,

            }

            console.log('payload', payload);


            await getCompagain(payload);

        } catch (error) {
            console.log('error', error);

        }

    }



    const onChange = (e, campaign) => {


        console.log('campaign here ', campaign);


        const { checked } = e.target;

        // Update the selected checkboxes state
        setchecked((prevSelected) => {
            if (checked) {
                // Add campaign.id to the selected checkboxes
                return [...prevSelected, campaign._id];
            } else {
                // Remove campaign.id from the selected checkboxes
                return prevSelected.filter((id) => id !== campaign._id);
            }
        });

        setMultieditfreeuser((prevSelected) => {
            if (checked) {
                // Add campaign.id to the selected checkboxes
                return [...prevSelected, campaign];
            } else {
                // Remove campaign.id from the selected checkboxes
                return prevSelected.filter((id) => id !== campaign);
            }
        });


    };
    const UsergrouponChange = (e, campaign) => {


        const { checked } = e.target;

        // Update the selected checkboxes state
        setUserchecked((prevSelected) => {
            if (checked) {
                // Add campaign.id to the selected checkboxes
                return [...prevSelected, campaign._id];
            } else {
                // Remove campaign.id from the selected checkboxes
                return prevSelected.filter((id) => id !== campaign._id);
            }
        });


        setMultieditgroup((prevSelected) => {
            if (checked) {
                // Add campaign.id to the selected checkboxes
                return [...prevSelected, campaign];
            } else {
                // Remove campaign.id from the selected checkboxes
                return prevSelected.filter((id) => id !== campaign);
            }
        });





    };

    const phisingonChange = (e, campaign) => {
        console.log('campaign', campaign);  // This will print the campaign object correctly

        const { checked } = e.target; // Extract the 'checked' value from the event


        setPhishingchecked((prevSelected) => {
            let updatedSelection;
            let multicompaginselection;
            if (checked) {
                // Add campaign._id to the selected checkboxes
                updatedSelection = [...prevSelected, campaign._id];
                multicompaginselection = [...prevSelected, campaign.name];
            } else {
                // Remove campaign._id from the selected checkboxes
                updatedSelection = prevSelected.filter((id) => id !== campaign._id);
                multicompaginselection = prevSelected.filter((id) => id !== campaign.name)
            }

            // Now update selectedCampaignId correctly
            setSelectedCampaignId(updatedSelection);
            setMultiCompagin(multicompaginselection)

            // Return the updated array to ensure correct state update
            return updatedSelection;
        });
    };







    const adduserbtn = () => {
        setAddUserModalVisible(true)
    }

    const addGroupuset = () => {
        setAddGroupUserModalVisible(true)
    }

    const multiedituser = () => {
        // setMultieditfreeuser(compagain)
        setMultiEditModalVisible(true)
    }

    const editmodal = (compagain) => {
        Seteditfreeuser(compagain)
        setEditModalVisible(true)

    }




   


    const editcompmodal = (compagain) => {
        //SeteditCompagin(compagain)

        navigate('/campaignflow', {
            state: { campaign: compagain }
        });

        // SeteditCompagin((prevState) => [...prevState, compagain]);

        //setEditcompaginModalVisible(true)
    }

    const editGroupmodal = (group)=>{
        setEditGroup(group)
        setEditGroupModalVisible(true)
    }


    const deletemodal = (id) => {

        setchecked((prevState) => {
            if (!prevState.includes(id)) {
                return [...prevState, id];
            }
            return prevState;
        });

        Setdeletefreeuser(true)
        // setModalVisible(true)

    }

    const deletecompmodal = (id) => {
        // console.log('value',id)

        // setSelectedCampaignId((prevState) => [...prevState, id]);
        setSelectedCampaignId((prevState) => {
            // Add id to the selected campaign IDs, if not already present
            if (!prevState.includes(id)) {
                return [...prevState, id];
            }
            return prevState;
        });


        setModalVisible(true)
        setDeletecomp(true)

    }



    const deletgroupmodal = (id) => {

        setUserchecked((prevState) => {
            // Add id to the selected campaign IDs, if not already present
            if (!prevState.includes(id)) {
                return [...prevState, id];
            }
            return prevState;
        });
        setGroupModalVisible(true)
    }

    console.log('userchecked', userchecked);


    return (

        <>
            <div className="dashboard">

                <Sidebar />
                <div className="dashboard-right-side">
                    <div className="headerbox">

                        <div className="headertext">
                            Overview
                        </div>

                        <div onClick={() => {
                            navigation('/campaignflow')
                        }} className="createcompaignbutton">
                            <img alt="" className="createcompaignimg" src={addcircle}></img>
                            Create New Campaign
                        </div>
                    </div>

                    <div className="stats">
                        <div className="statsbox">
                            <div className="statsheading">Total Campaigns
                                <h1>
                                    {allcompaign?.compaigncount || 0}
                                </h1>
                            </div>
                            <img className="statsimg" src={overview} alt="" />

                        </div>

                        <div className="statsbox">
                            <div className="statsheading">Total Target Users
                                <h1>
                                    {freeuser?.freeusercount || 0}
                                </h1>
                            </div>
                            <img className="statsimg" src={targetgroup} alt="" />

                        </div>

                        <div className="statsbox">
                            <div className="statsheading">Total User Groups
                                <h1>
                                    {usergroup?.usergroupcount || 0}
                                </h1>
                            </div>
                            <img className="statsimg" src={targetuser} alt="" />

                        </div>


                    </div>


                    <div className="phisingcampagains">
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className="name">
                                Phishing Simulation Campaigns
                            </p>

                            <div className="d-flex">

                                <div className="d-flex">
                                    {phishingchecked.length > 1 && (
                                        <>
                                            {/* <img
                                                alt=""
                                                // style={{ position: 'relative', cursor: 'pointer', top: '14px', left: '34%' }}
                                                style={{ cursor: 'pointer' }}
                                                width={25}
                                                height={25}
                                                src={edit}
                                                onClick={editcompmodal}
                                            /> */}


                                            <img
                                                alt=""
                                                style={{ cursor: 'pointer' }}
                                                // style={{ position: 'relative', cursor: 'pointer', top: '14px', left: '17%' }}
                                                width={25}
                                                height={25}
                                                src={deletebtn}
                                                onClick={deletecompmodal}
                                            />
                                        </>
                                    )
                                    }
                                </div>

                                <div style={{ marginBottom: '28px' }} className="searchbox">

                                    <Search placeholder="Search campaigns...." onSearch={onCompaingSearch} onPressEnter={(e) => onCompaingSearch(e.target.value)} enterButton />
                                </div>
                            </div>

                        </div>
                        <div className="mainphisingcampagains">
                            <div>
                                <Checkbox checked={selectAll}
                                    onChange={handleSelectAllChange}></Checkbox>

                            </div>

                            <div>
                                Campaign ID
                            </div>
                            <div>
                                Campaign Name
                            </div>
                            <div>
                                Date & Time
                            </div>

                            <div>
                                Users
                            </div>


                            <div>
                                Action
                            </div>
                        </div>




                        {allcompaign?.usercompaign?.map((campaign, index) => (
                            <div key={index} className="phisingcampagainsdescription">
                                <div>
                                    <Checkbox onChange={(e) => phisingonChange(e, campaign)}
                                        checked={phishingchecked.includes(campaign._id)}
                                    ></Checkbox>
                                </div>
                                <div>
                                    {`ST000${index + 1}`}
                                </div>
                                <div>
                                    {campaign.name}
                                </div>
                                <div>
                                    {moment(campaign?.createdDate).local().format('MMM D, YYYY hh:mm A z')}
                                </div>
                                <div>
                                    {campaign.userCount} Users
                                </div>
                                <div className="actions">
                                    {/* <img onClick={() => editcompmodal(campaign)} src={edit} alt="Edit" /> */}
                                    <img
                                        onClick={() => {

                                            const currentDate = new Date();
                                            const campaignDate = new Date(campaign.createdDate);

                                            if (campaignDate < currentDate) {
                                                return;
                                            }

                                            editcompmodal(campaign);
                                        }}
                                        src={edit}
                                        alt="Edit"
                                        style={{
                                            cursor: new Date(campaign.createdDate) < new Date() ? 'not-allowed' : 'pointer',
                                            opacity: new Date(campaign.createdDate) < new Date() ? 0.5 : 1
                                        }}
                                    />

                                    <img onClick={() => deletecompmodal(campaign._id)} src={deletebtn} alt="Delete" />


                                </div>
                            </div>
                        ))}

                        <div onClick={() => {
                            navigate('/dashboard/my-campaigns')
                        }}


                            className="viewall">View All</div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '20px' }}>

                        <div style={{ marginBottom: '24px' }} className="phisingcampagains">
                            <div className="usercompangain-main-div">
                                <p>
                                    Individual Users


                                </p>
                                <div className="d-flex">

                                    <div style={{ marginBottom: '20px' }} className="d-flex">
                                        {checked.length > 1 && (
                                            <>
                                                <img
                                                    alt=""
                                                    style={{ cursor: 'pointer' }}
                                                    // style={{ position: 'relative', cursor: 'pointer', left: '34%', top: '14px' }}
                                                    width={25}
                                                    height={25}
                                                    src={edit}
                                                    onClick={multiedituser}
                                                />


                                                <img
                                                    alt=""
                                                    style={{ cursor: 'pointer' }}
                                                    // style={{ position: 'relative', cursor: 'pointer', left: '17%', top: '14px' }}
                                                    width={25}
                                                    height={25}
                                                    src={deletebtn}
                                                    onClick={deletemodal}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div onClick={adduserbtn} className="usercompangain">
                                        <img alt="" className="createcompaignimg" src={addcircle}></img>
                                        New User
                                    </div>
                                </div>





                            </div>



                            <div style={{ marginBottom: '13px' }} className="searchbox">

                                <Search placeholder="Search user...." onSearch={onSearch} onPressEnter={(e) => onSearch(e.target.value)} enterButton />

                            </div>
                            <div className="mainphisingcampagains">
                                <div style={{ width: '6%' }}>
                                    <Checkbox
                                        checked={selectAllfreeuser}
                                        onChange={handleSelectAllFreeuserChange}></Checkbox>

                                </div>

                                <div style={{ width: '21%' }}>
                                    User ID
                                </div>
                                <div style={{ width: '58%' }}>
                                    Name
                                </div>
                                <div style={{ width: '28%' }}>
                                    Role
                                </div>




                                <div>
                                    Action
                                </div>
                            </div>




                            {freeuser?.freeuser?.map((campaign, index) => (
                                <div key={index} className="phisingcampagainsdescription">
                                    <div style={{ width: '6%' }}>
                                        <Checkbox onChange={(e) => onChange(e, campaign)}
                                            checked={checked.includes(campaign._id)}
                                        >


                                        </Checkbox>
                                    </div>
                                    <div style={{ width: '21%' }}>
                                        {`ST000${index + 1}`}
                                    </div>
                                    <div style={{ width: '58%' }}>
                                        {campaign.Name}
                                    </div>

                                    <div style={{ width: '28%' }}>
                                        {campaign.role}
                                    </div>
                                    <div className="actions">
                                        <img onClick={() => editmodal(campaign)} actions src={edit} alt="Edit" />
                                        <img onClick={() => deletemodal(campaign._id)} src={deletebtn} alt="Delete" />
                                    </div>
                                </div>
                            ))}
                            <div onClick={() => {
                                navigate('/dashboard/target-users')
                            }}


                                className="viewall">View All</div>

                        </div>

                        <div style={{ marginBottom: '24px' }} className="phisingcampagains">
                            {/* <p>
                            User Groups
                        </p> */}

                            <div className="usercompangain-main-div">
                                <p>
                                    Groups
                                </p>
                                <div className="d-flex">


                                    <div style={{ marginBottom: '20px' }} className="d-flex">

                                        {userchecked.length > 1 && (
                                            <>
                                                <img
                                                    alt=""
                                                    style={{ cursor: 'pointer' }}
                                                    // style={{ position: 'relative', cursor: 'pointer', left: '34%', top: '14px' }}
                                                    width={25}
                                                    height={25}
                                                    src={edit}
                                                    onClick={() => { setMultiEditGroupModalVisible(true) }}
                                                />
                                                <img
                                                    alt=""

                                                    style={{ cursor: 'pointer' }}
                                                    // style={{ position: 'relative', cursor: 'pointer', left: '17%', top: '14px' }}
                                                    width={25}
                                                    height={25}
                                                    src={deletebtn}
                                                    onClick={deletgroupmodal}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <div onClick={addGroupuset} className="usercompangain">
                                        <img alt="" className="createcompaignimg" src={addcircle}></img>
                                        Create Group
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: '13px' }} className="searchbox">

                                <Search placeholder="Search group...." onSearch={onGroupSearch} onPressEnter={(e) => onGroupSearch(e.target.value)} enterButton />
                            </div>


                            <div className="mainphisingcampagains">
                                <div style={{ width: '7%' }}>
                                    <Checkbox
                                        checked={selectAllusergroup}
                                        onChange={handleSelectAllUserGrouChange}
                                    >


                                    </Checkbox>

                                </div>

                                <div style={{ width: '80%' }}>
                                    Group Name
                                </div>
                                <div>
                                    Members
                                </div>



                                <div>
                                    Action
                                </div>



                            </div>



                            {usergroup?.usergroup?.map((campaign, index) => (
                                <div key={index} className="phisingcampagainsdescription">
                                    <div style={{ width: '7%' }}>
                                        <Checkbox onChange={(e) => UsergrouponChange(e, campaign)} checked={userchecked.includes(campaign._id)} ></Checkbox>
                                    </div>

                                    <div style={{ width: '80%' }}>
                                        {campaign?.name}
                                    </div>

                                    <div>
                                        {campaign?.userCount}  Users
                                    </div>

                                    <div className="actions">
                                        <img onClick={() => editGroupmodal(campaign)} actions src={edit} alt="Edit" />
                                        <img onClick={() => deletgroupmodal(campaign._id)} src={deletebtn} alt="Delete" />
                                    </div>

                                </div>
                            ))}

                            <div onClick={() => {
                                // navigate('/dashboard/target-users')
                                navigate('/dashboard/target-users', { state: { usergroup: true } });
                            }}


                                className="viewall">View All</div>

                        </div>


                    </div>


                </div>
            </div>


            <AddUserModal
                visible={addusermodalVisible}
                onClose={() => setAddUserModalVisible(false)}
                setNotificationmodal={setNotificationmodal}
            />

            <CreateUserGroupModal
                visible={addgroupusermodalVisible}
                onClose={() => setAddGroupUserModalVisible(false)}
            />



            <DeleteModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                // currentRoute={currentRoute}
                setNotificationmodal={setDeleteNotificationmodal}
                deletecomp={deletecomp}
                selectedCampaignId={selectedCampaignId}

            />


            <DeleteNotification
                visible={deletenotificationmodal}
                onClose={() => setDeleteNotificationmodal(false)}
                deletecomp={deletecomp}
            />


            <DeleteUsermodal
                visible={deletefreeuser}
                onClose={() => Setdeletefreeuser(false)}
                deletecomp={deletecomp}
                checked={checked}
                setchecked={setchecked}
                setNotificationmodal={setDeleteNotificationmodal}
                multieditfreeuser={multieditfreeuser}

            // setNotificationmodal={setDeleteNotificationmodal}


            />


            <AddedNotification
                visible={notificationmodal}
                onClose={() => setNotificationmodal(false)}
            />




            <EditModal
                visible={editmodalVisible}
                onClose={() => setEditModalVisible(false)}
                setEditNotificationmodal={setEditNotificationmodal}
                editfreeuser={editfreeuser}


            />


            <EditUserModal
                visible={multieditmodalVisible}
                onClose={() => setMultiEditModalVisible(false)}
                setEditNotificationmodal={setEditNotificationmodal}
                editfreeuser={multieditfreeuser}
                setMultieditfreeuser={setMultieditfreeuser}
                setchecked={setchecked}
                setSelectAllFreeuser={setSelectAllFreeuser}


            />


            <EditCompagain
                visible={editcompaginmodalVisible}
                onClose={() => setEditcompaginModalVisible(false)}
                //    currentRoute={}
                setEditNotificationmodal={setEditNotificationmodal}
                editCompagin={editCompagin}

            />


            <EditGroup
                visible={editgroupmodalVisible}
                onClose={() => setEditGroupModalVisible(false)}
                //    currentRoute={}
                setEditNotificationmodal={setEditNotificationmodal}
                editgroup={editgroup}

            />


            <EditMultiGroup
                visible={multieditgroupmodalVisible}
                onClose={() => setMultiEditGroupModalVisible(false)}
                //    currentRoute={}
                setEditNotificationmodal={setEditNotificationmodal}
                editgroup={multieditgroup}
                setMultieditgroup={setMultieditgroup}
                setUserchecked={setUserchecked}

            />





            <UpdateNotification
                visible={editnotificationmodal}
                onClose={() => setEditNotificationmodal(false)}
            />


            <DeleteGroupmodal
                visible={groupmodalVisible}
                onClose={() => setGroupModalVisible(false)}
                setNotificationmodal={setNotificationmodal}
                deletegroup={userchecked}
                setUserchecked={setUserchecked}
                setMultieditgroup={setMultieditgroup}



            />



        </>
    )
}

export default Dashboard