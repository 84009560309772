

const initialState = {
  userDetails: null,
  Allusers:[]

}

const userReducer = (state = initialState, action) => {
  const { type, payload } = action
  
  switch (type) {
    case 'SET_USER_DETAILS': {
      return {
        ...state,
        userDetails: payload,
      
      }
    }

    case 'SETALLUSERS': {
      return {
        ...state,
        Allusers: payload,
      
      }
    }



    default:
      return state
  }
}

export default userReducer