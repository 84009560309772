import { notification } from 'antd'
import { publicAPI } from '../../Config/Constant'
import store from '../store'



export const createTemplate = async(paylaod) => {
 




    try {
      const res = await publicAPI.post('/message/',paylaod)
      if (res) {

           console.log('res',res);
          
let newpaylaod ={
  organizationid:paylaod.organizationid,
  page:1,
}
           await getTemplate(newpaylaod)
      
        // if (res) {
         
        //   await getTemplate()
        //    return res
        //   // }
        // } 
        return res

      }
    } catch (err) {
   //    console.error('Error during login:', err); 
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  
}


 export const getTemplate = async(paylaod) => {
 




     try {
       const res = await publicAPI.post('/message/get',paylaod)
       if (res) {

           // console.log('res',res);
           
       
         if (res) {
          
            store.dispatch({
                type: 'SET_ALL_TEMPLATE',
                payload: res.data.data,
              })
    
            return res
           // }
         } 
         

       }
     } catch (err) {
    //    console.error('Error during login:', err); 
       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }
