

const initialState = {
    Allcompaign:[]
  
  }
  
  const userCompaginReducer = (state = initialState, action) => {
    const { type, payload } = action
    
    switch (type) {
      case 'SET_ALL_COMPAIGN': {
        return {
          ...state,
          Allcompaign: payload,
        
        }
      }
  
    
  
  
  
      default:
        return state
    }
  }
  
  export default userCompaginReducer