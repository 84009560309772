import  { useState } from 'react'

import webapplogo from "../assest/webapplogo.png"

import CustomTextInput from '../Components/CustomTextInput'
import CustomButton from '../Components/CustomButton'
import CustomPasswordInput from '../Components/CustomInputPassword'
import { useNavigate } from 'react-router-dom'
import { authLogin } from '../redux/actions/authAction'
import { invalidemail } from '../Config/Constant'
import { notification } from 'antd';

const Login = () => {
    const [email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
     const navigate = useNavigate()

  
    // console.log('email', email);

const loginAccount =()=>{
    try {

    
        const emailDomain = email.split('@')[1];

        if (invalidemail.includes(emailDomain)) {
          
            return notification.error({
              message: 'Invalid Email',
              description: 'The email domain is not allowed.',
            });
          }

const payload={
    email,
    Password,
  
}

// console.log('payload',payload);

  authLogin(payload,navigate)
        //  navigate('/dashboard/overview')
    } catch (error) {
        console.log('error',error);
        
    }
}

  



    return (


        <div className='loginpagemain'>

            <div className='loginpage'>

                {/* <Image className='webimage' src={webapplogo}/> */}
                <img className='webimage' src={webapplogo} alt=''></img>

                <p>
                    Welcome back!

                </p>
                <h2>Please enter details</h2>
                <div className='textinput'>
                    <p>
                        Email Address
                    </p>
                    <CustomTextInput value={email} onChange={setEmail} />

                    <p style={{ marginTop: '10px' }}>
                        Password
                    </p>
                    <CustomPasswordInput value={Password} onChange={setPassword} />
                    <CustomButton 
                    onClick={loginAccount}
                    // onClick={()=>{
                    //     navigate('/dashboard/overview')
                    // }}
                     text={'Login'} />

                    <p className='notaccount'>
                    Don’t have an account? <span onClick={()=>{
                        navigate('/signup')
                    }} > Sign Up</span> 
                    </p>
                </div>

            </div>

        </div>
    )
}

export default Login