
import { Button } from 'antd'
import CustomButton from '../../Components/CustomButton'

import { useNavigate } from 'react-router-dom'


const AgreeTerms = () => {


    const navigate = useNavigate()

    const AgreeButton = () => {
        navigate('/resetpassword')
    }

    const DeclineButton = () => {
        navigate('/signup')
    }

    // console.log('email', email);


    return (


        <div className='loginpagemain'>

            <div className='loginpage'>





                <h3 className="successs">
                    Telecommunications Act requirements
                    <p style={{ textAlign: 'left' }}>Lorem ipsum dolor sit amet consectetur. Sed vitae pharetra semper turpis. Aenean integer ac sit imperdiet enim vel senectus cras ut. Est quam ac sed nulla nunc. Sit adipiscing cursus vitae nunc placerat quis arcu augue metus.

                        Faucibus arcu facilisis turpis amet rhoncus eget iaculis etiam id. Ipsum praesent ornare egestas elementum pellentesque amet auctor velit. Nibh et tristique eu duis ullamcorper faucibus non integer viverra. Mattis volutpat lobortis magna pulvinar quisque sed lacinia pellentesque pretium. Viverra at lectus a nunc mollis ac ultrices commodo. Eu lorem sagittis lectus cras nec. Nec ut quis ullamcorper vestibulum. Sed donec enim quis placerat vitae ut. Mus lectus ac amet convallis varius faucibus aliquam non. Pharetra in enim cras eu vel vel arcu duis adipiscing. Tempus pulvinar condimentum ut risus sodales arcu. Laoreet diam amet pellentesque id pretium nulla in pretium. Quis metus pulvinar at in id mattis risus pellentesque viverra. Aliquam sollicitudin facilisis magnis imperdiet accumsan. Integer lectus laoreet dolor diam sit at.
                    </p>

                    <p style={{ marginTop: '40px', textAlign: 'left' }}>
                        Consequat tempus ac cursus sed. Sed vitae sed aliquam turpis hac. Facilisis molestie magna eu dictum malesuada odio consectetur duis vel. Amet nec enim commodo velit sed nisl. Duis orci aliquam adipiscing nam sit. Lobortis quam sit cursus ac integer egestas gravida velit. Eleifend orci elementum aliquam aenean. Id faucibus porttitor ac ullamcorper at tortor. Id praesent tellus in cum dolor bibendum bibendum urna sit. Nibh mauris quam nunc tincidunt vel aenean. Morbi lorem duis nulla pharetra turpis ultrices. Malesuada gravida auctor posuere neque eu suscipit vel congue.
                        Mauris massa mattis cursus eget arcu et ultrices. Auctor mattis tellus viverra varius egestas vitae posuere dui dolor. Congue sagittis dictum pellentesque at amet. Pretium in ipsum sed habitant hac sed. Ipsum scelerisque mi elementum tincidunt sagittis sapien nulla tortor sed.
                    </p>

                </h3>






                <div className='errorbtn'>
                    <CustomButton onClick={AgreeButton} text={'Agree'} />
                    {/* <CustomButton   text={'Later'} /> */}
                    <Button onClick={DeclineButton} className='optionalbutton'>Decline</Button>
                </div>




            </div>

        </div>
    )
}

export default AgreeTerms


