import React, { useState } from 'react';
import { Table, Checkbox } from 'antd';
import edit from "../../assest/Button.png";
import deletebtnimg from "../../assest/newButton.png";
import { useLocation } from 'react-router-dom';

import UpdateNotification from '../Notification/UpdateNotification';
import EditGroup from '../Modal/EditGroup';
import DeleteGroupmodal from '../Modal/DeleteGroupModal';
import DeleteGroupNotification from '../Notification/DeleteGroupNotification';
import moment from 'moment';


const UserGroupTable = ({ usergroupcolmn, selectedCheckboxes, onCheckboxChange, onallCheckboxChange }) => {

    const location = useLocation();
    const currentRoute = location.pathname



    // console.log('currentRoute',currentRoute);


    // console.log('location',location);

    const [modalVisible, setModalVisible] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false)
    const [editmodalVisible, setEditModalVisible] = useState(false)
    const [editnotificationmodal, setEditNotificationmodal] = useState(false)
    const [editgroup, seteditgroup] = useState(null)
    const [deletegroup, setDeletegroup] = useState([])
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });



    console.log('selectedCheckboxes frontend', selectedCheckboxes);




    const deletebtn = (id) => {
        

        setDeletegroup((prevState) => {
            // Add id to the selected campaign IDs, if not already present
            if (!prevState.includes(id)) {
                return [...prevState, id];
            }
            return prevState;
        });
        setModalVisible(true);
    }

    const editmodal = (id) => {
        console.log('id', id);

        seteditgroup(id)
        setEditModalVisible(true)

    }


    // const onChange = (e) => {

    //     setchecked(e.target.checked)
    //     console.log(`my checked = ${e.target.checked}`);
    // };


    const columns = [
        {
            title: <Checkbox onChange={(e) => onallCheckboxChange(e)} />,
            dataIndex: 'checkbox',
            // width:'30px',
            width: 10,
            key: 'checkbox',
            render: (_, record) => (
                // <Checkbox onChange={onChange}></Checkbox>
                <Checkbox
                    onChange={(e) => onCheckboxChange(e, record)}
                    checked={selectedCheckboxes.includes(record._id)}
                // checked={checked.includes(record._id)} 
                />
            ),
        },

        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '10%',
        },

        {
            title: 'Number of User',
            dataIndex: 'userCount',
            key: 'userCount',
            className: 'mt'
            //    width: '50%',
        },
        {
            title: 'Date Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => {
                return moment(createdAt).local().format('MMM D, YYYY hh:mm A z');  // Formats date
            },
            // width: '20%',
            // width: 50,
        },

        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            //  width: 10,
            render: (_, record) => (
                <div className="actions">
                    <img style={{ cursor: 'pointer' }} onClick={() => editmodal(record)} src={edit} alt="Edit" />
                    <img style={{ cursor: 'pointer' }} onClick={() => deletebtn(record._id)} src={deletebtnimg} alt="Delete" />
                </div>
            ),
        },
    ];



    const dataSource = usergroupcolmn?.usergroup?.map((campaign, index) => ({
        key: index,
        ...campaign,
    }));

    return (
        <>
            <Table
                className="table"
                dataSource={dataSource}
                columns={columns}

                // pagination={true}

                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: dataSource,
                    onChange: (page, pageSize) => {
                        setPagination({
                            current: page,
                            pageSize: pageSize,
                        });
                    },
                }}
            />

            <DeleteGroupmodal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                currentRoute={currentRoute}
                setNotificationmodal={setNotificationmodal}
                deletegroup={deletegroup}
                selectedCheckboxes={selectedCheckboxes}
            />


            <DeleteGroupNotification
                visible={notificationmodal}
                onClose={() => setNotificationmodal(false)}
            />




            <EditGroup
                visible={editmodalVisible}
                onClose={() => setEditModalVisible(false)}
                //    currentRoute={}
                setEditNotificationmodal={setEditNotificationmodal}
                editgroup={editgroup}


            />

            <UpdateNotification
                visible={editnotificationmodal}
                onClose={() => setEditNotificationmodal(false)}
            />


        </>
    );
};

export default UserGroupTable;
