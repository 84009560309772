import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'

import CustomButton from '../CustomButton'
import { deleteUserGroup, getUserGroup } from '../../redux/actions/groupcompaginAction';
import { useSelector } from 'react-redux';



const DeleteGroupmodal = ({ visible, onClose,currentRoute,setNotificationmodal,deletegroup,selectedCheckboxes}) => {

   
//  console.log('user',user?.team?.currentLeague?.name);
  const user = useSelector((state) => state?.Auth?.userDetails?.user)



const[boxes,setBoxes]=useState([])

console.log('deletegroup',deletegroup);

console.log('selectedCheckboxes ------',selectedCheckboxes);

useEffect(() => {
  // Only update if selectedCheckboxes is a non-empty array
  if (Array.isArray(selectedCheckboxes) && selectedCheckboxes.length > 0) {
    setBoxes(selectedCheckboxes); // Set boxes to selectedCheckboxes if it's not empty
  } 
}, [selectedCheckboxes]);
console.log('boxes',boxes);



  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }

const onsave=async()=>{




try {

  console.log('in the hit');

   console.log("selectedCheckboxes?.length:", boxes.length);
if ((deletegroup?.length > 0) || (selectedCheckboxes?.length > 0)){
  const validusers = [
    ...(Array.isArray(selectedCheckboxes) ? selectedCheckboxes : [selectedCheckboxes]),  // Wrap in an array if it's not already an array
    ...(Array.isArray(deletegroup) ? deletegroup.filter(id => typeof id === 'string') : [])  // Filter deletegroup to include only strings
  ];

  console.log('validusers', validusers);


    


    
    let payload={
      ids:validusers
    }
  const res= await deleteUserGroup(payload)

  // //  console.log('res',res);
   

   if (res?.data){

    let checkpaylaod={
      organizationid:user.organizationid,
      page:1
    }
    await getUserGroup(checkpaylaod)

    // setUserchecked([])
    // setMultieditgroup([])

    setNotificationmodal(true)
   }


   cancel()
  
  
  }

  else{
    console.log('in the error');
    
  }


} catch (error) {

  console.log('error',error);
  
  
}

 
}
  


  return (
    <Modal
      className='deletemodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   
<h1>

  Delete Group?
</h1>



<p>
  Do you want to delete {selectedCheckboxes?.length > 1 ? `${selectedCheckboxes.length} Groups` : 'this Group'}?
</p>


<div className='btn'>

   <CustomButton onClick={onsave} text={'Yes'}/>


   <CustomButton onClick={cancel} text={'No'}/>
 
</div>
    </Modal>




  )
}

export default DeleteGroupmodal;
