import { notification } from 'antd'
import { publicAPI } from '../../Config/Constant'
import store from '../store'



export const GetMyOrganizationUser = async(payload) => {
 
    // console.log('payload here',payload);



     try {
       const res = await publicAPI.post('/myorganization', payload)
    

    console.log('my res',res);
    
           
       
         if (res) {
          
            store.dispatch({
                type: 'SET_ALL_MY_ORGANIZATION_USERS',
                payload: res.data.data,
              })
    

          

            return res
           
         } 
         

       
     } catch (err) {
       console.error('Error during login:', err); 
       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }




 export const EditMemberOrganizationUser = async(payload) => {
 
  // console.log('payload here',payload);



   try {
     const res = await publicAPI.post('/myorganization/update', payload)
  



     
       if (res) {

        let myorganizationpayload={organization_ids:[payload.organization_id]}
        
        await GetMyOrganizationUser(myorganizationpayload)

        

          return res
         
       } 
       

     
   } catch (err) {
     console.error('Error during login:', err); 
     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}

export const DeleteMemberOrganizationUser = async(payload) => {
 
  // console.log('payload here',payload);



   try {
     const res = await publicAPI.post('/myorganization/delete', payload)
  



     
       if (res) {

        let myorganizationpayload={organization_ids:[payload.organization_id]}
        
        await GetMyOrganizationUser(myorganizationpayload)

        

          return res
         
       } 
       

     
   } catch (err) {
     console.error('Error during login:', err); 
     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}