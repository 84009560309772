// ChartCard.jsx
import React, { useState } from 'react';
import { Card, Radio } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const ChartCard = ({ title, dataKey, color, data, selected, onClick, colornumber }) => {
    const [view, setView] = useState('weekly');
    // const [date, setDate] = useState(moment());
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

   

    // console.log('today',today);
    

    const dataYearly = [
        { name: 'Jan', value: 30 },
        { name: 'Feb', value: 20 },
        { name: 'Mar', value: 27 },
        { name: 'Apr', value: 23 },
        { name: 'May', value: 34 },
        { name: 'Jun', value: 45 },
        { name: 'Jul', value: 65 },
        { name: 'Aug', value: 55 },
        { name: 'Sep', value: 40 },
        { name: 'Oct', value: 30 },
        { name: 'Nov', value: 20 },
        { name: 'Dec', value: 25 }
    ];

    const dataMonthly = [
        { name: 'Week 1', value: 30 },
        { name: 'Week 2', value: 20 },
        { name: 'Week 3', value: 27 },
        { name: 'Week 4', value: 23 }
    ];

    const dataWeekly = [
        { name: 'Mon', value: 12 },
        { name: 'Tue', value: 18 },
        { name: 'Wed', value: 20 },
        { name: 'Thu', value: 24 },
        { name: 'Fri', value: 35 },
        { name: 'Sat', value: 40 },
        { name: 'Sun', value: 28 }
    ];

    const dataDaily = [
        { name: '00:00', value: 5 },
        { name: '06:00', value: 10 },
        { name: '12:00', value: 20 },
        { name: '18:00', value: 15 },
        { name: '24:00', value: 25 }
    ];

    const handleViewChange = (e) => {
        setView(e.target.value);
    };

    // const handleDateChange = (date) => {
    //     setDate(date);
    // };
    const handleDateChange = (e) => {
        setDate(e.target.value); 
      };

    const getData = () => {
        switch (view) {
            case 'yearly':
                return dataYearly;
            case 'monthly':
                return dataMonthly;
            case 'weekly':
                return dataWeekly;
            case 'daily':
                return dataDaily;
            default:
                return dataWeekly;
        }
    };

    return (
        <Card
            title={title}
            bordered={false}
            extra={
                // <DatePicker 
                //   onChange={handleDateChange} 
                //   defaultValue={date ? moment(date) : moment()} // Ensure date is in the correct format
                //   picker="date"  // Make sure it's always the date picker, not year or month picker
                // />
                <input className='calender' onChange={handleDateChange} value={date} type='date'></input>
              }
           // extra={<DatePicker picker={view === 'yearly' ? 'year' : view === 'monthly' ? 'month' : 'date'} onChange={handleDateChange} defaultValue={date} />}
// extra={<RangePicker
//     showTime
  
//     format="YYYY-MM-DD"

//     // style={{ width: '100%' }}
//     // className="inputbox custom-range-picker"
//     onChange={handleDateChange} defaultValue={date}
// />}
          

            className={`chart-card ${selected ? 'selected' : ''}`}
            onClick={onClick}
        >
            <Radio.Group value={view} onChange={handleViewChange} style={{ marginBottom: 16 }}>
                <Radio.Button value="yearly">Yearly</Radio.Button>
                <Radio.Button value="monthly">Monthly</Radio.Button>
                <Radio.Button value="weekly">Weekly</Radio.Button>
                <Radio.Button value="daily">Daily</Radio.Button>
            </Radio.Group>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart width={474} height={386} data={getData()}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#F20B7A" stopOpacity={1} />
                            <stop offset="100%" stopColor="#F20B7A" stopOpacity={0} />
                        </linearGradient>
                        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                            <feDropShadow dx="0" dy="0" stdDeviation="5" floodColor="#15803D" floodOpacity="0.5" />
                        </filter>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey={dataKey}
                        stroke={color}
                        strokeWidth={2}
                        fill="url(#colorUv)"
                        // opacity={0.1}
                        filter="url(#shadow)"
                        activeDot={{ r: 8 }}
                    />

                </LineChart>

            </ResponsiveContainer>
            <div className='d-flex'>
                <p
                    className={`chart-card ${colornumber === 0 ? 'redbox' : colornumber === 1 ? 'greenbox' : 'nobox'}`}

                >


                </p>

                <p className='boxpara'>User Who Clicked</p>
            </div>

        </Card>
    );
};

export default ChartCard;
