import { useEffect, useState } from 'react'

import webapplogo from "../../assest/webapplogo.png"

import CustomTextInput from '../../Components/CustomTextInput'
import CustomButton from '../../Components/CustomButton'

import { useNavigate } from 'react-router-dom'
import { AutheticateOTP, getUser, SendOTP } from '../../redux/actions/authAction'
import { notification } from 'antd'
import { useSelector } from 'react-redux'

const Verfication = () => {
    const [phone, setPhone] = useState('')
    const [verify, setverify] = useState(false)
    const [code, setCode] = useState('')

    const navigate = useNavigate()


    const user = useSelector((state) => state?.Auth?.userDetails?.user)

    console.log('user', user);


    useEffect(() => {
        let organizationid = localStorage.getItem('organizationid')
        getUser(organizationid)
    }, [])


    useEffect(() => {
        if (user) {
            setPhone(user?.phone);
           
        }
    }, [user]);



    // console.log('email', email);


    const sendcode = async () => {


        try {


            if (!phone){
                return notification.error({
                    message: 'Phone Number is Missing',
                });
            }

            let organizationid = localStorage.getItem('organizationid')

            // const phoneRegex = /^\+\d{10,15}$/;
            // if (!phoneRegex.test(phone)) {
            //     return notification.error({
            //         message: 'Invalid Phone Number',
            //         description: 'Please enter a valid phone number.',
            //     });
            // }


            if (organizationid) {

                let payload = {
                    organizationid,
                    phone,
                }

                const res = await SendOTP(payload)

                // console.log('res here',res);

                if (res?.data) {

                    setverify(true)
                }
            }


        } catch (error) {

            console.log('error', error);


        }


    }


    const SendVerfication = async () => {

        try {

            if (!code || code.trim() === ''){
                return notification.error({
                    message: 'code is Missing',
                    
                });
            }

            let organizationid = localStorage.getItem('organizationid')
         let sessiontoken=   localStorage.getItem('sessiontoken')

            if (organizationid) {

                let payload = {
                    organizationid,
                    code,
                    sessiontoken,
                }

                const res = await AutheticateOTP(payload, navigate)

                // console.log('res here',res);


            }


        } catch (error) {

            console.log('error', error);


        }


    }

    return (


        <div className='loginpagemain'>

            <div className='loginpage'>

                {/* <Image className='webimage' src={webapplogo}/> */}
                <img className='webimage' src={webapplogo} alt=''></img>



                {!verify ?
                    <>

                        <p>
                            Verify Your Mobile Device

                        </p>
                        <h2>Please enter details</h2>


                        <div className='textinput'>

                            <p>
                                Phone Number
                            </p>
                            <CustomTextInput  value={phone} onChange={setPhone} />

                            <CustomButton onClick={sendcode} text={'Send Code'} />


                        </div>
                    </>

                    :
                    <>
                        <p>
                            Enter Code to Verify Device

                        </p>
                        <h2>Please enter details</h2>


                        <div className='textinput'>

                            <p>
                                Verification Code
                            </p>
                            <CustomTextInput value={code} onChange={setCode} />

                            <CustomButton onClick={SendVerfication} text={'Verify Device'} />


                            <div onClick={sendcode} className='reendcode'>
                        <p>Resend Code</p>
                            </div>


                        </div>
                    </>

                }



            </div>

        </div>
    )
}

export default Verfication