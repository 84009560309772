import React, { useState } from 'react';
import { Table, Checkbox } from 'antd';
import edit from "../../assest/Button.png";
import deleteimg from "../../assest/newButton.png";
import DeleteModal from "../Modal/Deletemodal"
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteNotification from '../Notification/DeleteNotification';

import UpdateNotification from '../Notification/UpdateNotification';
import EditCompagain from '../Modal/EditCompagain';
import moment from 'moment';


const CampaignsTable = ({ campaigns, selectedCheckboxes, onCheckboxChange,oncheckallboxchange }) => {
    const location = useLocation();
    const currentRoute = location.pathname
    const navigate =useNavigate()

    // console.log('currentRoute',currentRoute);


    // console.log('location',location);

    const [modalVisible, setModalVisible] = useState(false)
    const [notificationmodal, setNotificationmodal] = useState(false)
    const [editmodalVisible, setEditModalVisible] = useState(false)
    const [editnotificationmodal, setEditNotificationmodal] = useState(false)
    const [selectedCampaignId, setSelectedCampaignId] = useState([]);
    const [editCompagin, SeteditCompagin] = useState(null)

        const [pagination, setPagination] = useState({
            current: 1,
            pageSize: 10,
            total: 0,
          });
    
    // const onChange = (e) => {
    //     console.log(`checked = ${e.target.checked}`);
    // };

    const deletebtn = (id) => {
        setSelectedCampaignId((prevState) => {
            // Add id to the selected campaign IDs, if not already present
            if (!prevState.includes(id)) {
                return [...prevState, id];
            }
            return prevState;
        });

        setModalVisible(true);
    }

    const editmodal = (record) => {
        // SeteditCompagin(record)
        navigate('/campaignflow', {
            state: { campaign: record }
        });
        setEditModalVisible(true)

    }
    const columns = [

        {
            title: <Checkbox onChange={(e) => oncheckallboxchange(e)} />,
            dataIndex: 'checkbox',
            // width:'30px',
            width: 10,
            key: 'checkbox',
            render: (_, record) => (
                // <Checkbox onChange={onChange}></Checkbox>
                <Checkbox
                    onChange={(e) => onCheckboxChange(e, record)}
                    checked={selectedCheckboxes.includes(record._id)}
                />
            ),
        },
        {
            title: 'Campaign ID',
            dataIndex: 'id',
            key: 'id',
            render: (_, record, index) => {
                return `ST000${index + 1}`;  // Formats as 'ST0001', 'ST0002', etc.
            },
            //  width: 120,

        },
        {
            title: 'Campaign Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdDate',
            key: 'createdDate',
            width: '20%',
            render: (createdDate) => {
                return moment(createdDate).local().format('MMM D, YYYY hh:mm A z');  // Formats date
            },
            // width: 50,
        },
        {
            title: 'Users',
            dataIndex: 'userCount',
            key: 'userCount',
            // width: '18%',
            width: '9%',
            render: (text) => `${text} Users`,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            //  width: 10,
            render: (_, record) => (
                <div className="actions">
                    {/* <img style={{ cursor: 'pointer' }} onClick={() => editmodal(record)}  src={edit} alt="Edit" /> */}
                    <img
                        onClick={() => {

                            const currentDate = new Date();
                            const campaignDate = new Date(record.createdDate);

                            if (campaignDate < currentDate) {
                                return;
                            }

                            editmodal(record)
                        }}
                        src={edit}
                        alt="Edit"
                        style={{
                            cursor: new Date(record.createdDate) < new Date() ? 'not-allowed' : 'pointer',
                            opacity: new Date(record.createdDate) < new Date() ? 0.5 : 1
                        }}
                    />
                    <img style={{ cursor: 'pointer' }} onClick={() => deletebtn(record._id)} src={deleteimg} alt="Delete" />
                </div>
            ),
        },
    ];





    const dataSource = campaigns?.usercompaign?.map((campaign, index) => ({
        key: index,
        ...campaign,
    }));


    // {`ST000${index + 1}`}
    // </div>
    // <div>
    //     {campaign.name}
    // </div>
    // <div>
    // {moment(campaign.startDate).local().format('MMM D, YYYY hh:mm A z')}

    return (
        <>
            <Table
                className="table"
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    current: pagination.current,       
                    pageSize: pagination.pageSize,     
                    total: dataSource,           
                    onChange: (page, pageSize) => {
                      setPagination({
                        current: page,       
                        pageSize: pageSize,  
                      });
                    },
                  }}

                // pagination={true}
            />

            <DeleteModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                currentRoute={currentRoute}
                setNotificationmodal={setNotificationmodal}
                selectedCampaignId={selectedCampaignId}
            />


            <DeleteNotification
                visible={notificationmodal}
                onClose={() => setNotificationmodal(false)}
                currentRoute={currentRoute}

            />


            <EditCompagain
                visible={editmodalVisible}
                onClose={() => setEditModalVisible(false)}
                //    currentRoute={}
                setEditNotificationmodal={setEditNotificationmodal}
                editCompagin={editCompagin}


            />

            <UpdateNotification
                visible={editnotificationmodal}
                onClose={() => setEditNotificationmodal(false)}
            />


        </>
    );
};

export default CampaignsTable;
