import React, { useState } from 'react'
import { Button, Input, Modal, notification } from 'antd'



import CustomButton from '../CustomButton'
import Customcommentbox from '../Customcommenbox'
import CustomTextInput from '../CustomTextInput'
import { createTemplate } from '../../redux/actions/templateaction'
import InsertTemplate from './InsertTemplate'





const CompagainMessage = ({ visible, onClose, setCompagainmessage, compagainmessage, userdetails }) => {


  const [name, setName] = useState('')
  const [insertemplate, setInsertTemplate] = useState(null)

  console.log('userdetails', userdetails);


  //  console.log('user',user?.team?.currentLeague?.name);

  const cancel = () => {
    onClose()
  }

  const saved = async () => {

    if (name == '' || compagainmessage === '') {

      notification.error({ message: 'Title or message can\'t be empty.', duration: 3 });
      return

    }


    let payload = {
      name,
      message: compagainmessage,
      organizationid: userdetails?.organizationid
    }


    console.log('payload', payload);

    const res = await createTemplate(payload)

    // console.log('res chekcig',res);


    if (res?.data?.success) {
      onClose()
    }


    // setPlayerInfo({})
    // onClose()
    // setcompaignTemplate(false)
    // setdaterange(true)
  }





  return (
    <Modal
      className='compgainmessage'
      title=''
      open={visible}
      width={908}
      height={526}
      onCancel={onClose}
      centered
      footer={[



      ]}

    >


      <h1>
        Create Custom Message for Campaign
      </h1>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button className='back-btn' onClick={() => {
          setInsertTemplate(true);
        }}>Insert Template</Button>

      </div>

      <p>Title</p>




      {/* <Input width={'40%'} className='title' value={name}
 onChange={(e) => {
  // Check if the length of the input is less than or equal to 125 characters
  if (e.target.value.length <= 125) {
    setName(e.target.value);
  }
}}

 /> */}

      <Input
        width={'40%'}
        className='title'
        value={name}
        onChange={(e) => {
          // Check if the length of the input is less than or equal to 125 characters
          if (e.target.value.length <= 125) {
            setName(e.target.value);
          }
        }}
        style={{
          borderColor: name.length === 125 ? 'red' : '', // Add a red border when the length reaches 125
          borderWidth: name.length === 125 ? '2px' : '1px' // Optional: Thicker border when at the limit
        }}
      />


      <p>Message</p>

      <Customcommentbox value={compagainmessage} onChange={setCompagainmessage} />




      <div className='btn'>

        <CustomButton className='cancel' width={'22%'} style={{ marginBottom: '0px' }} onClick={cancel} text={'Cancel'} />

        <CustomButton width={'22%'} style={{ marginBottom: '0px' }} onClick={saved} text={'Save'} />




      </div>


      <InsertTemplate
        visible={insertemplate}
        onClose={() => setInsertTemplate(false)}
        userdetails={userdetails}

      />

    </Modal>


  )
}

export default CompagainMessage
