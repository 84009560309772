// import React, { useState } from 'react'
// import { Modal, Select } from 'antd'


// import CustomTextInput from '../CustomTextInput'
// import CustomButton from '../CustomButton'

// import { useSelector } from 'react-redux';
// import { addUserGroup, getUserGroup } from '../../redux/actions/groupcompaginAction';
// import { Input } from 'antd';
// import { getfreeuser } from '../../redux/actions/freeuserAction';



// const { Option } = Select;

// const CreateUserGroupModal = ({ visible, onClose,setNotificationmodal}) => {

//   const [name,setName]=useState('')
//   const alluser = useSelector((state) => state?.Auth?.Allusers)
//   const userdetails= useSelector((state) => state?.Auth?.userDetails?.user)

//   const freeuser = useSelector((state) => state?.freeuserReducer?.allfreeusers)

//   const [selectedUserId, setSelectedUserId] = useState([]);

//   const { Search } = Input;

//    const onSearch = async (value) => {




//         try {


        
//             let payload = {
//                 organizationid: userdetails.organizationid,
//                 search: value,

//             }


//             // let payload = {
//             //     organizationid: userdetails.organizationid,
//             //     search: value,

//             // }

//             console.log('payload', payload);


//              await getfreeuser(payload);

//         } catch (error) {
//             console.log('error', error);

//         }

//     }


//   console.log('userdetails',userdetails);
  

//   // Handler for when an option is selected
//   const handleChange = (value) => {
//     // setSelectedUserId(value);  // This will store the selected user ID
//     // console.log('Selected user ID:', value);


//     const selectedUserObjects = freeuser?.freeuser?.filter((user) => value.includes(user._id));
//     setSelectedUserId(selectedUserObjects);
//   };


//   console.log('alluser', alluser);


   
// //  console.log('user',user?.team?.currentLeague?.name);

//   const cancel = () => {
//     // setPlayerInfo({})
//     onClose()
//   }


//   const onsaved =async()=>{

// let payload={
//   name,
//   users:selectedUserId.map((item) => item._id),
//   organizationid:userdetails.organizationid,
// }

// // console.log('payload',payload);

//  console.log('payload',payload);
//  const res = await addUserGroup(payload)

// // console.log('res',res);


// if (res.data.success) {
//   setName('');  // Correct function call to update state
//   setSelectedUserId([]); 
  
  
//   let newpayload={
//     organizationid:  userdetails.organizationid
// }

// await getUserGroup(newpayload)
//   // Correct function call to update state
  
//   onClose();
//   //setNotificationmodal(true)
//     // Close the modal or whatever action you need
//    //setNotificationmodal=(true);  // Set the notification modal to true
// }




//     // onClose()
//     // setNotificationmodal(true)
//   }

  


//   return (
//     <Modal
//       className='addusermodal'
//       title=''
//       open={visible}
//       onCancel={onClose}
//       centered
//      footer={[
        
      
        
//       ]}
      
//     >

   
// <h1>
// Create User Group
// </h1>



// <p>

// Please Update details

// </p>


// <div className='add-user-main'>
//     <p>
//     Group Name
//     </p>
//     <CustomTextInput className='inputbox' value={name} onChange={setName} />

//     <p>
//     Search Users
//     </p>





// <Search placeholder="Search user...." onSearch={onSearch} onPressEnter={(e) => onSearch(e.target.value)} enterButton  />

//     <p style={{paddingTop:'20px'}}>
//     Add Users
//     </p>




   
  

//   <Select className="dropdown" onChange={handleChange}     mode="multiple" value={selectedUserId?.map(user => user._id)}  >
//       {/* Map through allusers and create an Option for each user */}
//       {freeuser?.freeuser?.map((user) => (
//         <Option key={user._id} value={user._id}>
//           {user.Name}
//         </Option>
//       ))}
//     </Select>


 





// </div>

// <div className='btn'>

// <CustomButton style={{marginBottom:'0px'}} onClick={onsaved} text={'Save'}/>


// <CustomButton style={{marginBottom:'0px'}}  onClick={cancel} text={'Cancel'}/>

// </div>

//     </Modal>
//   )
// }

// export default CreateUserGroupModal




import React, { useState, useEffect } from 'react';
import { Modal, Select, Input } from 'antd';
import CustomTextInput from '../CustomTextInput';
import CustomButton from '../CustomButton';
import { useSelector } from 'react-redux';
import { addUserGroup, getUserGroup } from '../../redux/actions/groupcompaginAction';
import { getfreeuser } from '../../redux/actions/freeuserAction';
import { CloseOutlined } from '@ant-design/icons'; 

const { Option } = Select;

const CreateUserGroupModal = ({ visible, onClose, setNotificationmodal }) => {
  const [name, setName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);

  const alluser = useSelector((state) => state?.Auth?.Allusers);
  const userdetails = useSelector((state) => state?.Auth?.userDetails?.user);
  const freeuser = useSelector((state) => state?.freeuserReducer?.allsearchfreeuser);

  const { Search } = Input;

  useEffect(() => {
    if (searchTerm) {
      onSearch(searchTerm);  // Trigger search if searchTerm is updated
    }
  }, [searchTerm]);

  // Search handler to fetch users based on search term
  const onSearch = async (value) => {
    try {
      let inmodal=true;
      let payload = {
        organizationid: userdetails.organizationid,
        search: value,
        inmodal,
      };
      await getfreeuser(payload); 
      setOpen(true);
      // Fetch users based on search
    } catch (error) {
      console.log('Error searching users:', error);
    }
  };

  // Handle user selection
  // const handleChange = (value) => {
  //   const selectedUserObjects = freeuser?.freeuser?.filter((user) => value.includes(user._id));
  //   setSelectedUserId(selectedUserObjects);
  // };

  const handleChange = (value) => {
    // Get the selected users based on the value (user _id)
    const selectedUserObjects = freeuser?.freeuser?.filter((user) => value.includes(user._id));
  
    // Add the newly selected users to the previously selected ones
    setSelectedUserId((prevSelectedUserIds) => {
      // Merge new selections with the previous ones and remove duplicates
      const updatedSelectedUserIds = [...prevSelectedUserIds, ...selectedUserObjects];

      // console.log('updatedSelectedUserIds',updatedSelectedUserIds);
      
      
      // Remove duplicates based on user._id
      // const uniqueSelectedUsers = updatedSelectedUserIds.filter(
      //   (value, index, self) => index == self.findIndex((t) => t._id == value._id)
      // );
  
      const uniqueSelectedUsers = updatedSelectedUserIds.filter((value, index, self) => {

        // console.log('value',value);

        // console.log('index',index);

        // console.log('self',self);
        
        
        
        // Check if this _id already exists in the list, if not, include it
        return index === self.findIndex((t) => t._id === value._id);
      });

      return uniqueSelectedUsers;
    });
  };

  const handleRemoveUser = (userId) => {
    setSelectedUserId((prevSelectedUserIds) =>
      prevSelectedUserIds.filter((user) => user._id !== userId) // Remove user by _id
    );
  };
  


  //   const handleChange = (value) => {
  //   // setSelectedUserId(value);  // This will store the selected user ID
  //   // console.log('Selected user ID:', value);


  //   const selectedUserObjects = freeuser?.freeuser?.filter((user) => value.includes(user._id));
  //   setSelectedUserId(selectedUserObjects);
  // };


console.log('selectedUserId',selectedUserId);


  // Save handler
  const onSaved = async () => {
    let payload = {
      name,
      users: selectedUserId.map((item) => item._id),
      organizationid: userdetails.organizationid,
    };

    const res = await addUserGroup(payload);

    if (res.data.success) {
      setName('');  
      setSelectedUserId([]);  
      let newPayload = {
        organizationid: userdetails.organizationid,
        page:1
      };
      await getUserGroup(newPayload); 

      await getfreeuser(newPayload)
      onClose(); 
    }

    setSelectedUserId([]);  // Clear selected users
    setSearchTerm('')
  };

  // Cancel handler
  const cancel = () => {
    setName('');  // Reset name
    setSelectedUserId([]);  // Clear selected users
    setSearchTerm('')
    onClose(); // Close modal
  };

  return (
    <Modal
      className="addusermodal"
      title="Create Group"
      open={visible}
      onCancel={cancel}
      centered
      footer={null}
    >
      <h1>Create User Group</h1>
      <p>Please update details</p>

      <div className="add-user-main">
        <p>Group Name</p>
        <CustomTextInput className="inputbox" value={name} onChange={setName} />

        <p>Search Users</p>
        <Search
          placeholder="Search user..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} 
          onSearch={onSearch}
          enterButton
        />

        <p style={{ paddingTop: '20px' }}>Add Users</p>
        <Select
  className="dropdown"
  onChange={handleChange}
  // mode="multiple"
  // value={selectedUserId?.map((user) => user.Name)} // Show selected user _ids
  placeholder="Select Users"
  style={{ width: '100%' }}
  open={open}
  onBlur={() => setOpen(false)} 
  onSearch={onSearch}
>
  {/* Filter users that are not already selected */}
  {freeuser?.freeuser
    ?.filter((user) => !selectedUserId.some((selected) => selected._id === user._id)) // Exclude selected users
    .map((user) => (
      <Option key={user._id} value={user._id}>
        {user.Name}
      </Option>
    ))}
</Select>

  
<>
    {selectedUserId.length > 0 && (
      <div className='removeitem'>
        {selectedUserId.map((item) => (
          <div className='removebox' key={item._id} style={{ display: 'flex', alignItems: 'center',flexDirection:'row' }}>
            <span style={{ color:  '#EF4444'}}>{item.Name}</span>
            <CloseOutlined
            size={10}
              onClick={() => handleRemoveUser(item._id)} 
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>
    )}
  </>


      </div>

      <div className="btn">
        <CustomButton style={{ marginBottom: '0px' }} onClick={onSaved} text="Save" />
        <CustomButton style={{ marginBottom: '0px' }} onClick={cancel} text="Cancel" />
      </div>
    </Modal>
  );
};

export default CreateUserGroupModal;
