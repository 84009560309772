import React from 'react';
import { Card } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'antd/dist/reset.css'; // Ensure you import the Ant Design styles
import { htmlToText } from 'html-to-text'; // Importing html-to-text library

const Customcommentbox = ({ value, onChange }) => {
    const handleChange = (content, delta, source, editor) => {
     
        const plainText = htmlToText(editor.getHTML(), {
            wordwrap: false,
        });
        onChange(plainText);
    };

    return (
        <Card style={{ width: '100%', height:'325px' }}>
            <ReactQuill
                value={value}
                onChange={handleChange}
                modules={{
                    toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image', 'video'],
                        ['clean']
                    ]
                }}
                formats={[
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'link', 'image', 'video'
                ]}
            />
        </Card>
    );
};

export default Customcommentbox;
