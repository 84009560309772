

import success from "../../assest/success.png"

import CustomButton from '../../Components/CustomButton'

import { useNavigate } from 'react-router-dom'


const VerficationSuccess = () => {


    const navigate = useNavigate()

    const dashboard =()=>{
        navigate('/dashboard/overview')
    }


    // console.log('email', email);


    return (


        <div className='loginpagemain'>

            <div className='loginpage'>

                {/* <Image className='webimage' src={webapplogo}/> */}
                <img className='successimg' src={success} alt=""></img>


                <h3 className="successs">
                    Successfully Verified!

                    <p>Thank you for verifying your device.</p>
                </h3>







                <CustomButton onClick={dashboard} text={'Continue'} />




            </div>

        </div>
    )
}

export default VerficationSuccess