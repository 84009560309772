import  { useEffect, useState } from 'react'

import webapplogo from "../assest/webapplogo.png"

import CustomButton from '../Components/CustomButton'
import CustomPasswordInput from '../Components/CustomInputPassword'
import { useNavigate } from 'react-router-dom'
import { authPassword } from '../redux/actions/authAction'
import { notification } from 'antd';

const ResetPassword = () => {

    const [Password, setPassword] = useState('')

    const openErrorNotification = () => {
        notification.error({
          message: 'Password Error',
          description: 'Passwords do not match.',
          placement: 'topRight',
        });
      };
    
    const [confirmPassword, setConfirmPassword] = useState('')
    const [token, setToken] = useState(null);
     const navigate = useNavigate()

     let organizationid= localStorage.getItem('organizationid')
  
    // console.log('email', email);
    useEffect(() => {
        // Get query parameters from the URL
        const queryParameters = new URLSearchParams(window.location.search);
    
        // Get the 'token' from the query string
        const token = queryParameters.get('token');

        console.log('token',token);
        
    
        if (token) {
          // Store token in the state
          setToken(token);
    
      
       
        }
      }, []);

const setpassword=()=>{
    try {
        if (Password && confirmPassword !== Password) {

            openErrorNotification();
            
          } 

        if(token)
         

        {
            

            const payload ={
    
                password_reset_token:token,
                Password,
                organizationid
                
         
            }
            
            authPassword(payload,navigate)
          
            
        }

    } catch (error) {
        console.log('error',error);
        
        
    }
}

    return (


        <div className='loginpagemain'>

            <div className='loginpage'>

                {/* <Image className='webimage' src={webapplogo}/> */}
                <img className='webimage' src={webapplogo} alt=''></img>

                <p>
                    Update Password

                </p>
                <h2>Please enter details</h2>
                <div className='textinput'>
                   

                    <p >
                        Password
                    </p>
                    <CustomPasswordInput value={Password} onChange={setPassword} />

                    
                    <p style={{marginTop:'10px'}} >
                       Confirm Password
                    </p>
                    <CustomPasswordInput value={confirmPassword} onChange={setConfirmPassword} />
                    <CustomButton  onClick={setpassword}
                    // onClick={()=>{
                    //      navigate('/verfication')
                    // }} 
                    
                    text={'Update Password'} />

                   
                </div>

            </div>

        </div>
    )
}

export default ResetPassword