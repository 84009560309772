import { useEffect, useState } from "react"
import Sidebar from "../../Components/Sidebar"
import CustomTextInput from "../../Components/CustomTextInput"
import { Button, Image, Select, Tag, TimePicker } from "antd"
import arrowleft from "../../assest/arrow-left.png"
import { Pagination } from 'antd';
import arrowright from "../../assest/arrow-right.png"
import smallpreviewpic from "../../assest/small preview pic.png" 
import filter from "../../assest/filter.png"
import { Form, DatePicker } from 'antd';
import 'antd/dist/reset.css';
import { Input } from 'antd';

import CompagainNotification from "../../Components/Notification/CompagainNotification"
import CompagainMessagepreview from "../../Components/Modal/CompagainMessagepreview"
import CompagainMessage from "../../Components/Modal/CompagainMessage"
import { useSelector } from "react-redux"

import { getTemplate } from "../../redux/actions/templateaction"
import { addCompagin, EditCompagin } from "../../redux/actions/compagainaction"
import moment from 'moment-timezone';

import { useLocation } from "react-router-dom"
import { getUserGroup } from "../../redux/actions/groupcompaginAction"
import { getUser } from "../../redux/actions/authAction"
import CustomButton from "../../Components/CustomButton"
import InsertTemplate from "../../Components/Modal/InsertTemplate"

const { Option } = Select

const { Search } = Input;

const FreeuserCompaignFlow = () => {

    const location = useLocation();
 


    const campaign = location.state?.campaign;

    console.log('campaign', campaign);
    const [name, setName] = useState('')
    const userdetails = useSelector((state) => state?.Auth?.userDetails?.user)
    const [form] = Form.useForm();

    console.log('userdetails?.organizationid', userdetails);

    const usergroup = useSelector((state) => state?.Groupreducer?.AllusersGroups)

    const alltemplate = useSelector((state) => state?.Templatereducer?.AllTemplate)
    console.log('alltemplate', alltemplate);



    const [currentStep, setCurrentStep] = useState('comptitle');

    const [compaignTemplate, setcompaignTemplate] = useState(false)


    // const [dateRange, setDateRange] = useState([null, null]);
    const [notificationmodal, setNotificationmodal] = useState(false)
    const [messagepreview, setmessagepreview] = useState(false)
    const [message, setMessage] = useState(false)
    const [compagainmessage, setCompagainmessage] = useState('')
    const [activeIndex, setActiveIndex] = useState(null);
    const [selectedtemplate, setSelectedTempalte] = useState(null)



    const [selectedUsers, setSelectedUsers] = useState([]);

    // Handle selection change
    const handleSelectChange = (value) => {

        console.log('value', value);

        // setSelectedUsers(value); // Update selected user IDs

        const selectedUserObjects = usergroup.usergroup.filter((user) => value.includes(user._id));
        setSelectedUsers(selectedUserObjects);
    };

    console.log('selectedUsers', selectedUsers);

    const [date, setDate] = useState(null);

    const [time, setTime] = useState(null);

    const handleDateChange = (date, dateString) => {
        setDate(dateString);
        form.setFieldsValue({ dateRange: dateString });
    };

    const handleTimeChange = (time, timeString) => {
        setTime(timeString);
    };


    // const handleTimeChange = (time, timeString) => {
    //     // If the user types a time, check whether it is in 12-hour format or 24-hour format
    //     let formattedTime;
    
    //     // Check if timeString is in 24-hour format (HH:mm)
    //     if (/^\d{1,2}:\d{2}$/.test(timeString)) {
    //       const [hour, minute] = timeString.split(':');
    //       const timeMoment = moment(`${hour}:${minute}`, 'HH:mm');
    
    //       // Convert to 12-hour format and append AM/PM
    //       formattedTime = timeMoment.format('hh:mm A');
    //     } else {
    //       // If the time has AM/PM format, just use it
    //       formattedTime = timeString;
    //     }
    
    //     setTime(formattedTime); // Update the state with the formatted time
    //     console.log('Formatted Time:', formattedTime); // Log the formatted time
    //   };



    const onSearch = async (value) => {

       


        try {

            let payload = {
                organizationid: userdetails.organizationid,
                search: value,

            }

            console.log('payload', payload);


            await getTemplate(payload);

        } catch (error) {
            console.log('error', error);

        }

    }



    useEffect(() => {
        let organizationid = localStorage.getItem('organizationid');
                let page = 1;
                let payload = { organizationid, page };
                getUserGroup(payload);
        getTemplate(payload)
              getUser(organizationid);
      
    }, [])


    useEffect(() => {
        if (campaign) {
            const createdDate = new Date(campaign.createdDate);  // Ensure the date is properly parsed

             console.log('createdDate', createdDate);



            const selectedTemplateIndex = alltemplate?.template?.findIndex(
                (template) => template._id === campaign.messageid._id
            );

            console.log('selectedTemplateIndex', selectedTemplateIndex);


            if (selectedTemplateIndex !== -1) {
                setActiveIndex(selectedTemplateIndex);
            }

            // If the createdDate is valid, you can set it in the DatePicker
            if (!isNaN(createdDate.getTime())) {  // Check if it's a valid date
                // Convert to a moment object if using moment.js or just use the Date object directly
                const formattedDate = moment(createdDate);
                console.log('formattedDate:', formattedDate);  // Check if formattedDate is valid
                console.log('formattedDate isValid:', formattedDate.isValid());

                let localDate = `${createdDate.getFullYear()}-${(createdDate.getMonth() + 1).toString().padStart(2, '0')}-${createdDate.getDate().toString().padStart(2, '0')}`;
                setDate(localDate);
            
                // Extract the local time part (HH:MM:SS)
                let localTime = `${createdDate.getHours().toString().padStart(2, '0')}:${createdDate.getMinutes().toString().padStart(2, '0')}:${createdDate.getSeconds().toString().padStart(2, '0')}`;
                setTime(localTime);
              
                // Convert to moment if needed

                // Set the form fields
                form.setFieldsValue({
                    campaignTitle: campaign?.name,        // Set the campaign name
                    message: campaign?.message._id,       // Set the campaign message
                    targetGroups: campaign?.targetGroup?.map(user => user._id),
                    template: campaign?.message,  // Set the target groups
                    //  date: date,                 // Set the date for DatePicker
                    // time: time // Optionally set the time
                });

                // Store selected data
                setName(campaign.name)
                setSelectedUsers(campaign.targetGroup);
                setSelectedTempalte(campaign?.messageid);
                //  setDate(formattedDate);                // Set the date
                //  setTime(createdDate.toLocaleTimeString()); // Optionally set time
                // setDate(formattedDate);
               //  setTime(localTime);
            } else {
                console.error("Invalid createdDate:", campaign.createdDate);
            }
        }
    }, [campaign, form]);


    // const handleStepClick = (step) => {


    //     setCurrentStep(step);
    // };

    const handleStepClick = (step) => {
        form.validateFields().then(() => {
            // If validation passes, go to the next step
            setCurrentStep(step);
        }).catch((errorInfo) => {
            // If validation fails, prevent moving to the next step
            console.log('Validation Failed:', errorInfo);
        });
    };

    const handlebackClick = (step) => {
        setCurrentStep(step);
    };

    // const handleRangeChange = (dates, dateStrings) => {
    //     setDateRange(dates);
    //     form.setFieldsValue({ dateRange: dates });
    // };

    const handleClick = (item, index) => {
        setActiveIndex(index);
        setSelectedTempalte(item)
        setmessagepreview(true);
        form.setFieldsValue({ template: item });
    };


    // const formatDisplay = (dates) => {
    //     if (!dates || dates.length !== 2 || !dates[0] || !dates[1]) {
    //         return '';
    //     }
    //     return `${dates[0].format('MM/DD/YYYY')} - ${dates[1].format('MM/DD/YYYY')} ${dates[0].format('hh:mm A')} - ${dates[1].format('hh:mm A')}`;
    // };

    // const handleSelectChange = (value) => {
    //     // Find the user object that matches the selected user ID
    //     const selectedUser = usergroup.usergroup.find((user) => user._id === value);
    //     setSelectedOption(selectedUser); // Store the entire user object in the state
    //   };

    const disablePastDates = (current) => {
        // Disable dates before today
        return current && current < moment().startOf('day');
    };

    // Disable past times for TimePicker
    // const disablePastTimes = () => {
    //     const currentTime = moment();
    //     return {
    //         disabledHours: () => {
    //             // Disable hours less than the current hour
    //             return Array.from({ length: currentTime.hour() }).map((_, index) => index);
    //         },
    //         disabledMinutes: (hour) => {
    //             if (hour === currentTime.hour()) {
    //                 // Disable minutes before the current minute for the current hour
    //                 return Array.from({ length: currentTime.minute() }).map((_, index) => index);
    //             }
    //             return [];
    //         },
    //         disabledSeconds: (hour, minute) => {
    //             if (hour === currentTime.hour() && minute === currentTime.minute()) {
    //                 // Disable seconds before the current second for the current minute
    //                 return Array.from({ length: currentTime.second() }).map((_, index) => index);
    //             }
    //             return [];
    //         },
    //     };
    // };

    const disablePastTimes = (selectedDate) => {
        const currentTime = moment();
        const selectedMoment = moment(selectedDate);
    
        return {
            disabledHours: () => {
                const hoursToDisable = [];
                const currentHour = currentTime.hour();
    
                // If the selected date is today, disable hours before the current hour
                if (selectedMoment.isSame(currentTime, 'day')) {
                    return Array.from({ length: currentHour }).map((_, index) => index);
                }
                // If the selected date is in the future, no hours need to be disabled
                return hoursToDisable;
            },
            disabledMinutes: (hour) => {
                const minutesToDisable = [];
                const currentMinute = currentTime.minute();
    
                // If the selected date is today and we're looking at the current hour,
                // disable minutes before the current minute
                if (selectedMoment.isSame(currentTime, 'day') && hour === currentTime.hour()) {
                    return Array.from({ length: currentMinute }).map((_, index) => index);
                }
                return minutesToDisable;
            },
            disabledSeconds: (hour, minute) => {
                const secondsToDisable = [];
                const currentSecond = currentTime.second();
    
                // If the selected date is today, and we're looking at the current minute,
                // disable seconds before the current second
                if (selectedMoment.isSame(currentTime, 'day') && hour === currentTime.hour() && minute === currentTime.minute()) {
                    return Array.from({ length: currentSecond }).map((_, index) => index);
                }
                return secondsToDisable;
            },
        };
    };
    
    

    const savecompaign = async () => {

        let formattedDates;
        let organizationid = localStorage.getItem('organizationid')
        // console.log('dateRange', dateRange);


        if (date && time) {
            // Combine the selected date and time
            const dateTimeString = `${date} ${time}`;

            // Convert the combined date-time string into a moment object
            const combinedDateTime = moment(dateTimeString, "YYYY-MM-DD HH:mm:ss");

            // Convert to US Eastern Time (ET) and format
            // formattedDates = combinedDateTime.tz('America/New_York').format('YYYY-MM-DD hh:mm:ss A');

            formattedDates = combinedDateTime.local().format('YYYY-MM-DD hh:mm:ss A');


            // console.log('selectedOption',selectedOption);

        }

        let payload = null;



        if (campaign) {

            payload = {

                name,
                title: name,
                message: selectedtemplate?.message || compagainmessage,
                messageid: selectedtemplate._id,
                createdDate: formattedDates,
                targetGroup: selectedUsers.map((item) => item._id),
                organizationid: campaign.organizationid,
                id: campaign._id





                // name, title, message, messageid, datesent,organizationid, targetGroup

            }

            console.log('paylaod', payload);


            const res = await EditCompagin(payload)

            if (res.data.success) {

                setNotificationmodal(true)

            }

        }

        else {
            console.log('in the else');
            

            payload = {

                name,
                title: name,
                message: selectedtemplate?.message || compagainmessage,
                messageid: selectedtemplate._id,
                createdDate: formattedDates,
                targetGroup: selectedUsers.map((item) => item._id),
                organizationid,





                // name, title, message, messageid, datesent,organizationid, targetGroup

            }

            console.log('paylaod', payload);


            const res = await addCompagin(payload)


         
            

            if (res.data.success) {

                setNotificationmodal(true)

            }

        }





    }


    return (
        <>


<div className="maindiv">
    <div>
    <Sidebar />
    </div>




            <div className="compagain-flow">

            {/* <Sidebar /> */}

                <div className="compagain-flow-main">




                    <div className="choices">
                        <div onClick={() => handleStepClick('comptitle')} className={currentStep === 'comptitle' ? "choices-main-selected" : "choices-main"}>
                            <div className="circle">1</div>
                            <p>Campaign Title</p>
                        </div>

                        <div onClick={() => handleStepClick('targetuser')} className={currentStep === 'targetuser' ? "choices-main-selected" : "choices-main"}>
                            <div className="circle">2</div>
                            <p>Select Target User</p>
                        </div>

                        <div onClick={() => handleStepClick('compaignTemplate')} className={currentStep === 'compaignTemplate' ? "choices-main-selected" : "choices-main"}>
                            <div className="circle">3</div>
                            <p>Campaign Template</p>
                        </div>

                        <div onClick={() => handleStepClick('daterange')} className={currentStep === 'daterange' ? "choices-main-selected" : "choices-main"}>
                            <div className="circle">4</div>
                            <p>Select Date Range</p>
                        </div>

                        <div onClick={() => handleStepClick('review')} className={currentStep === 'review' ? "choices-main-selected" : "choices-main"}>
                            <div className="circle">5</div>
                            <p>Review</p>
                        </div>

                    </div>

                    <Form form={form} layout="vertical"
                    //initialValue={campaign?.name}
                    >


                        {currentStep === 'comptitle' && (
                            <div className="compaign-title">
                                <p>Phishing Simulation Campaigns</p>
                                <h1>Create a Campaign Title</h1>

                                <Form.Item
                                    name="campaignTitle"
                                    rules={[{ required: true, message: 'Campaign title is required!' }]} // Validation for required field
                                >
                                    <CustomTextInput
                                        style={{ width: '100%' }}
                                        required={true}
                                        className='inputbox' placeholder={'Enter Title here....'}
                                        value={name}
                                        //onChange={(e) => setName(e.target.value)} 
                                        onChange={setName}
                                    />
                                </Form.Item>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <Button onClick={() => handleStepClick('targetuser')} className="btn">
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        )}



                        {currentStep === 'targetuser' && (
                            <div className="compaign-title">
                                <p>Phishing Simulation Campaigns</p>
                                <h1>Select Target Groups for this Campaign</h1>
                           

                                {userdetails?.tenDlcComplaint ?

                                    <Form.Item
                                        name="targetGroups"
                                        rules={[{ required: true, message: 'At least one target group must be selected!' }]} >
                                        <Select
                                            placeholder="Select Group"
                                            onChange={handleSelectChange}
                                            value={selectedUsers?.map(user => user._id)}
                                            className="inputbox"
                                            mode="multiple"
                                        //  value={selectedUsers} 
                                        // style={{ width: '100%' }}
                                        >
                                            {usergroup?.usergroup?.map((user) => (
                                                <Option key={user._id} value={user._id}>
                                                    {user.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>


                                    :
                                    <>

                                        <p>You can send message to your number in “Free Plan”</p>




                                        <CustomTextInput
                                            type="number"
                                            style={{ width: '100%' }}
                                            disabled={true}
                                            value={userdetails?.phone}
                                            className='inputbox' />



                                    </>
                                }









                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <Button onClick={() => handlebackClick('comptitle')} className="back-btn">
                                        <img alt="" width={24} src={arrowleft} />
                                        Back
                                    </Button>
                                    <Button onClick={() => handleStepClick('compaignTemplate')} className="btn">
                                        Continue
                                    </Button>
                                </div>
                            </div>
                        )}

                        {currentStep === 'compaignTemplate' && (

                            <div className="compaignTemplate">
                                <div>
                                <div>
                                <p className="compheading">
                                    Phishing Simulation Campaigns
                                    {/* <span onClick={() => handleStepClick('daterange')} className="skip">Skip This Step -></span> */}
                                </p>
                                <h1 className="compara">Select Template for Your Campaign</h1>
                                </div>


                            
                                </div>


                                <div className="filtertemplates">
                                    <p>
                                        Templates
                                    </p>
                                    <div className="filtertemplatesflex">
                                        <Image style={{ width: '20px', height: '20px' }} src={filter} />

                                        <Search placeholder="Search user...." onSearch={onSearch} onPressEnter={(e) => onSearch(e.target.value)} enterButton />
                                    </div>
                                </div>
                                <Form.Item
                                    name="template"
                                    rules={[{ required: true, message: 'Template selection is required!' }]} // Validation
                                >
                                    <div className="tempalatmainebox">
                                        {alltemplate?.template.map((item, index) => (
                                            <div key={index} onClick={() => handleClick(item, index)} className={`tempaltebox ${activeIndex === index ? 'active-tempaltebox' : ''}`}>
                                                
                                                <p>{`${String(index + 1).padStart(2, '0')}. ${item?.name?.length > 10 ? item?.name.slice(0, 10) + '...' : item?.name}`}</p>


                                             
                                                <img src={smallpreviewpic} alt="" />
                                                <div className="showmessage">
                                                <p>{item?.message?.slice(0, 80)}{item?.message?.length > 80 ? '...' : ''}</p>

                                                  </div>
                                            </div>
                                        ))}
                                      
                                    </div>
                                    <h1 style={{marginTop:'15px'}}>Create Custom Campaign</h1>

                                    <div onClick={() => { setMessage(true); setActiveIndex(3); }} className={`custom-compagain-tempaltebox ${activeIndex === 3 ? 'active-custom-compagain-tempaltebox' : ''}`}>
                                            <h1>Create Custom Campaign</h1>
                                            <p>Create custom campaign Message as per your needs.</p>
                                            <img src={arrowright} alt="" />
                                        </div>


                                </Form.Item>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <Button onClick={() => handlebackClick('targetuser')} className="back-btn">
                                        <img alt="" width={24} src={arrowleft} />
                                        Back
                                    </Button>
                                    <Button onClick={() => handleStepClick('daterange')} className="btn">Continue</Button>
                                </div>
                            </div>
                        )}

                        {currentStep === 'daterange' && (
                            <div className="compaign-title">
                                <p>Phishing Simulation Campaigns</p>
                                <h1>Select Date Range</h1>

                                <div style={{ display: 'flex', gap: '20px' }}>

                                    <Form.Item
                                        name="date"
                                        label="Select Date"
                                        // rules={[{ required: true, message: 'Date selection is required!' }]} 
                                        rules={[ 
                                            {
                                              required: !campaign?.createdDate, // If createdDate exists, set required to false
                                              message: 'Date selection is required!' 
                                            }
                                          ]}
                                    >
                                        <DatePicker
                                            className="inputbox custom-range-picker"
                                            onChange={handleDateChange}
                                            format="YYYY-MM-DD"
                                            disabledDate={disablePastDates}
                                            value={date}

                                            placeholder="Select Date"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="time"
                                        label="Select Time"
                                        rules={[ 
                                            {
                                              required: !campaign?.createdDate, // If createdDate exists, set required to false
                                              message: 'time selection is required!' 
                                            }
                                          ]}
                                        // rules={[{ required: true, message: 'Time selection is required!' }]} // Validation for time
                                    >
                                        <TimePicker
                                            className="inputbox custom-range-picker"
                                            onChange={handleTimeChange}
                                            value={time}
                                          //  value={time ? moment(time, 'HH:mm:ss') : null}
                                            //value={setTime}
                                              format="HH:mm"
                                            //  format="HH:mm:ss"
                                            //format="hh:mm A" 
                                            // {...disablePastTimes()}
                                            {...disablePastTimes(date)}

                                            placeholder="Select Time"
                                        />
                                    </Form.Item>

                                </div>
                                {/* <RangePicker
                                showTime
                                onChange={handleRangeChange}
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder={['Start Time', 'End Time']}
                                style={{ width: '100%' }}
                                className="inputbox custom-range-picker"
                            /> */}

                                <p className="datetime">Campaign Date & Time:</p>

                                <p className="timeonly"> {date} {time}</p>



                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <Button onClick={() => handlebackClick('compaignTemplate')} className="back-btn">
                                        <img alt="" width={24} src={arrowleft} />
                                        Back
                                    </Button>
                                    <Button onClick={() => handleStepClick('review')} className="btn">Continue</Button>
                                </div>
                            </div>
                        )}

                        {currentStep === 'review' && (
                            <div className="compaign-title">
                                <p>Phishing Simulation Campaigns</p>
                                <h1>Review Campaign</h1>
                                <h2 className="title">Title of Campaign: <p className="desctipton">{name}</p></h2>

                                {!userdetails?.tenDlcComplaint ? (
                                    <h2 className="title">
                                        Phone Number:
                                        <p className="description">{userdetails?.phone}</p>
                                    </h2>
                                ) : (
                                    <h2 className="title">
                                        Target Groups:
                                        {/* <p className="description">
                                            {selectedUsers.map((item) => (
                                                <span key={item._id}>{item.name}</span> // Display each user's name
                                            ))}
                                        </p> */}

<p className="description">
  {selectedUsers.map((item, index) => (
    <span key={item._id}>
      {item.name}{index < selectedUsers.length - 1 && ' ' /* Add space between names */}
    </span>
  ))}
</p>

                                    </h2>
                                )}


                                <h2 className="title">Your Campaign Message: <p className="desctipton">{selectedtemplate?.message || compagainmessage}</p></h2>
                                <h2 className="title">Campaign Date & Time:
                                    {/* <p className="desctipton">{formatDisplay(dateRange)}</p> */}
                                    <p className="desctipton">
                                        {date} {time}
                                    </p>



                                </h2>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <Button onClick={() => handlebackClick('daterange')} className="back-btn">
                                        <img alt="" width={24} src={arrowleft} />
                                        Back
                                    </Button>
                                    <Button onClick={savecompaign} className="btn">Save</Button>
                                </div>
                            </div>
                        )}






                    </Form>







                </div>


            </div>

            </div>

            <CompagainNotification
                visible={notificationmodal}
                onClose={() => setNotificationmodal(false)}
                campaign={campaign}

            />

            <CompagainMessagepreview
                visible={messagepreview}
                onClose={() => setmessagepreview(false)}
                selectedtemplate={selectedtemplate}
             

            />


            <CompagainMessage
                visible={message}
                onClose={() => setMessage(false)}
                setCompagainmessage={setCompagainmessage}
                compagainmessage={compagainmessage}
                setcompaignTemplate={setcompaignTemplate}
                userdetails={userdetails}
            />

{/* 
            <InsertTemplate
              visible={insertemplate}
              onClose={() => setInsertTemplate(false)}
              userdetails={userdetails}
            
            /> */}
        </>
    )
}


export default FreeuserCompaignFlow