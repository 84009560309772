import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
 import reportWebVitals from './reportWebVitals';

import App from './Config/App'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import store from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  <Provider store={store}>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#289FC9'
      },
    }}
  >
    <App />
  </ConfigProvider>
</Provider>,
);


reportWebVitals();
