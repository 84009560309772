import React, { useState } from 'react'
import { Input, Modal, notification } from 'antd'
import frame from '../../assest/Frame30.png'



import CustomButton from '../CustomButton'

import CustomTextInput from '../CustomTextInput'






const InsertTemplate = ({ visible, onClose }) => {


    const templatearary = [
        {
            img: frame,
            title: '01. Template Name',
            message: 'Lorem ipsum dolor sit amet consectetur. Consectetur eget pretium nascetur vitae. Risus sed parturient donec urna velit. Accumsan lorem enim quis sit molestie praesent ut.'
        },


        {
            img: frame,
            title: '02. Template Name',
            message: 'Lorem ipsum dolor sit amet consectetur. Consectetur eget pretium nascetur vitae. Risus sed parturient donec urna velit. Accumsan lorem enim quis sit molestie praesent ut.'
        },

        {
            img: frame,
            title: '03. Template Name',
            message: 'Lorem ipsum dolor sit amet consectetur. Consectetur eget pretium nascetur vitae. Risus sed parturient donec urna velit. Accumsan lorem enim quis sit molestie praesent ut.'
        },

        {
            img: frame,
            title: '04. Template Name',
            message: 'Lorem ipsum dolor sit amet consectetur. Consectetur eget pretium nascetur vitae. Risus sed parturient donec urna velit. Accumsan lorem enim quis sit molestie praesent ut.'
        },

        {
            img: frame,
            title: '05. Template Name',
            message: 'Lorem ipsum dolor sit amet consectetur. Consectetur eget pretium nascetur vitae. Risus sed parturient donec urna velit. Accumsan lorem enim quis sit molestie praesent ut.'
        },
        {
            img: frame,
            title: '06. Template Name',
            message: 'Lorem ipsum dolor sit amet consectetur. Consectetur eget pretium nascetur vitae. Risus sed parturient donec urna velit. Accumsan lorem enim quis sit molestie praesent ut.'
        },
        {
            img: frame,
            title: '07. Template Name',
            message: 'Lorem ipsum dolor sit amet consectetur. Consectetur eget pretium nascetur vitae. Risus sed parturient donec urna velit. Accumsan lorem enim quis sit molestie praesent ut.'
        },
    ]



    const onSaved = () => {
        onClose()
    }



    const onCancel = () => {
        onClose()
    }


    const onSearch = () => {

    }



    return (
        <Modal
            className='inserttemplate'
            title=''
            open={visible}
            width={908}
            onCancel={onClose}
            centered
            footer={[



            ]}

        >





            <div className='flex'>


                <p className='templatedesign'>
                    Design Templates for Campaign
                </p>


                <div>
                    <Input.Search
                        placeholder="Search user..."
                        //   value={searchTerm}
                        onChange={(e) => onSearch(e.target.value)} // Update searchTerm and trigger search
                        onSearch={onSearch} // Trigger search when enter is pressed
                        enterButton

                    />
                </div>
            </div>




            {templatearary.map((item, index) => {
                return (
                    <div className='alltemplate' key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img width={40} src={item?.img} alt={item?.title} />
                        <div className='temptitle'>
                            <h1>{item?.title}</h1>
                            <p>{item?.message}</p>
                        </div>
                    </div>
                );
            })}

            <div className="btn">
                <CustomButton width={150} className='cancel' onClick={onCancel} text="Cancel" />
                <CustomButton width={150} onClick={onSaved} text="Save" />

            </div>


        </Modal>
    )
}

export default InsertTemplate
