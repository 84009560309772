// src/components/CustomPasswordInput.js
import React, { useState } from 'react';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';

const CustomPasswordInput = ({ value, onChange, placeholder, disabled, width, style, ...props }) => {
  const [visible, setVisible] = useState(false);

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <div style={{ position: 'relative', width }}>
      <Input
        type={visible ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        style={{ width: '100%', ...style }}
        className='custompasswordinput'
        suffix={
          visible ? (
            <EyeTwoTone onClick={toggleVisibility} />
          ) : (
            <EyeInvisibleOutlined onClick={toggleVisibility} />
          )
        }
        {...props}
      />
    </div>
  );
};

CustomPasswordInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  style: PropTypes.object,
};

CustomPasswordInput.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
  width: '100%',
  style: {},
};

export default CustomPasswordInput;
