// src/components/CustomTextInput.js
import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const CustomTextInput = ({ value, onChange, placeholder, disabled, width, style,required, ...props }) => {
  // Properly handle the onChange event
  const handleChange = (e) => {
    onChange(e.target.value); // Pass the new value to the onChange prop
  };

  const inputStyle = {
    width, 
    height: style?.height || 'auto',  // Ensure the height is applied correctly
    paddingTop: '5px', // Push content to the top if necessary
    lineHeight: 'normal', // Make sure line height is normal for text to flow
    ...style, // Merge with the custom style
  };

  return (
    <Input
      value={value}
      onChange={handleChange} // Use the handleChange function
      placeholder={placeholder}
      disabled={disabled}
      // style={{ width, ...style }}
      style={inputStyle}
      className='custominput'
      required={required}

      {...props}
    />
  );
};

CustomTextInput.propTypes = {
  value: PropTypes.string, // value should be a string
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  style: PropTypes.object,
  required: PropTypes.bool,
};

CustomTextInput.defaultProps = {
  value: '',
  placeholder: '',
  disabled: false,
  width: '100%', // Default width
  style: {}, // Default empty style
  required: false,
};

export default CustomTextInput;
