// src/components/CustomButton.js
import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';


const CustomButton = ({ text, type, onClick, disabled, icon, width, style, ...props }) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={disabled}
      icon={icon}
   className='mycustombutton'
   style={{ width, ...style }}
      {...props}
    >
      {text}
    </Button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  style: PropTypes.object, // Accept additional style props
};

CustomButton.defaultProps = {
  type: 'default',
  onClick: () => {},
  disabled: false,
  icon: null,
  width: '100%', // Default width
  style: {}, // Default empty style
};

export default CustomButton;
