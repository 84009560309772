import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'


import CustomTextInput from '../CustomTextInput'
import CustomButton from '../CustomButton'
import { Editfreeuser, getfreeuser } from '../../redux/actions/freeuserAction'
import { useSelector } from 'react-redux'
import CustomTextArea from '../CustomTextArea'

// setNotificationmodal={setNotificationmodal}

const EditModal = ({ visible, onClose,setEditNotificationmodal,editfreeuser}) => {

  const [name,setName]=useState('')
  const [email,setEmail]=useState('')
  const [phone,setPhone]=useState('')
  const [companyname,setCompanyName]=useState('')
  const [role,setRole]=useState('')
  const [department,setdepartment]=useState('')
  const [notes,setNotes]=useState('')

   
  const user = useSelector((state) => state?.Auth?.userDetails?.user)

  useEffect(() => {
    if (editfreeuser) {
      setName(editfreeuser.Name)
      setEmail(editfreeuser.email)
      setPhone(editfreeuser.phone)
      setCompanyName(editfreeuser.companyName)
      setRole(editfreeuser.role)
      setdepartment(editfreeuser.departments)
      setNotes(editfreeuser.notes)

    }
  }, [editfreeuser]);



  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }

  const confirmed =async()=>{


    let payload={
      Name:name,
      email,
      phone,
      companyName:companyname,
      role,
      departments:department,
      notes,
      id:editfreeuser._id
    }


    console.log('paylaod',payload);
    

     const res= await Editfreeuser(payload)
    
       if (res?.data){
        let checkpaylaod={
          organizationid:user.organizationid,
          page:1
        }
        await getfreeuser(checkpaylaod)
        cancel()
        setEditNotificationmodal(true)

           }


    


   
  }


  


  return (
    <Modal
      className='addusermodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   
<h1>
Edit Individual User
</h1>



<p>

Please Update details

</p>


<div className='add-user-main'>
    <p>
    Full Name
    </p>
    <CustomTextInput  className='inputbox' value={name} onChange={setName} />

    <p>
    Email Address
    </p>
    <CustomTextInput   className='inputbox'  value={email} onChange={setEmail} />


    <p>
    Phone Number
    </p>
    <CustomTextInput   className='inputbox'  value={phone} onChange={setPhone} />

    <p>
    Company Name
    </p>
    <CustomTextInput   className='inputbox'  value={companyname} onChange={setCompanyName} />

    <p>
    Role
    </p>
    <CustomTextInput className='inputbox'  value={role} onChange={setRole} />


    <p>
    Department
    </p>
    <CustomTextInput className='inputbox'  value={department} onChange={setdepartment} />


    <p>
    Notes
    </p>
    <CustomTextArea style={{height:'135px'}} className='inputbox'  value={notes} onChange={setNotes} />



</div>

<div className='btn'>

<CustomButton style={{marginBottom:'0px'}} onClick={confirmed} text={'Save'}/>


<CustomButton className='cancel' style={{marginBottom:'0px'}}  onClick={cancel} text={'Cancel'}/>

</div>

    </Modal>
  )
}

export default EditModal
