import { combineReducers } from 'redux'

 import Auth from './reducers/authReducers'
import Groupreducer from "./reducers/groupReducer"
import Templatereducer from "./reducers/templateReducer"
import compaignReducer from "./reducers/compaignReducers"
import freeuserReducer from "./reducers/freeuserReducer"
import myorganizationReducers from "./reducers/myorganizationReducers"


const rootReducer = combineReducers({
    Auth,
    Groupreducer,
    Templatereducer,
    compaignReducer,
    freeuserReducer,
    myorganizationReducers,
  
})

export default rootReducer