import { useState } from 'react'

import webapplogo from "../assest/webapplogo.png"

import CustomTextInput from '../Components/CustomTextInput'
import CustomButton from '../Components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { Checkbox, notification, Radio } from 'antd';
import { authSignup } from '../redux/actions/authAction';
import { invalidemail } from '../Config/Constant';
// import { notification } from 'antd'

const Signup = () => {
    const [email, setEmail] = useState('')

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [company, setcompany] = useState('')
    const [value, setValue] = useState(1);
    const navigate = useNavigate()
    const [tenDlcComplaint, setTenDlcComplaint] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);


    let organization_name = 'minitestmpn';
    let organization_slug = 'minitestmpn';

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
      const isChecked = e.target.checked;
      setAcceptTerms(isChecked);
  

      if (isChecked) {
        setTenDlcComplaint(true); 
      } else {
        setTenDlcComplaint(false); 
      }
    };
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    }

    const register = () => {
        try {

            const emailDomain = email.split('@')[1];

            if (invalidemail.includes(emailDomain)) {

                return notification.error({
                    message: 'Invalid Email',
                    description: 'The email domain is not allowed.',
                });
            }
            const payload = {
                email_address: email,
                companyName: company,
                Name: name,
                phone,
                tenDlcComplaint,
            }

            console.log('payload',payload);
            

             authSignup(payload,navigate)

        } catch (error) {
            console.log('error', error);

        }
    }

    return (
        <div className='loginpagemain'>
            <div className='loginpage'>
                <img className='webimage' src={webapplogo} alt=''></img>
                <p>
                    Create New Account
                </p>
                <h2>Please enter details</h2>
                <div className='textinput'>
                    <p>
                        Full Name
                    </p>
                    <CustomTextInput value={name} onChange={setName} />
                    <p style={{ marginTop: '15px', marginBottom: '2px' }}>
                        Email Address
                    </p>
                    <CustomTextInput value={email} onChange={setEmail} />
    
                    <p style={{ marginTop: '15px', marginBottom: '2px' }}>
                        Phone Number
                    </p>
                    <CustomTextInput value={phone} onChange={setPhone} />
                    <p style={{ marginTop: '15px', marginBottom: '2px' }}>
                        Company Name
                    </p>
                    <CustomTextInput value={company} onChange={setcompany} />
    
                    <p style={{ marginTop: '15px', marginBottom: '2px' }}>Does your company issue corporate owned devices</p>
                    <Radio.Group onChange={onChange} value={value} key="corporate_owned">
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                    </Radio.Group>
                    <div className="legal" >
                        {/* <input className='checkbox' type="checkbox" id="acceptTerms" name="acceptTerms"></input> */}
                        <Checkbox id="acceptTerms" name="acceptTerms"  onChange={handleCheckboxChange}
                                            
                                        >


                                        </Checkbox>
                        <label className='conset' htmlFor="acceptTerms">
                        I consent to receive SMS text messages at the mobile number I have provided for testing & evaluation of SmishTrap's capability to send smishing campaigns on behalf of an organization. Before being able to test a 2FA code will be sent to verify my phone number. I acknowledge that my consent is not a condition for purchasing any products or services. I understand that I can opt-out of receiving these messages at any time by replying STOP and I can request assistance by replying HELP or emailing info@smishtrap.com. Messaging and data rates may apply. Message frequency varies. No mobile information will be shared with third parties. For more information, visit our <a href="https://smishtrap.com/privacypolicy">privacy policy</a> or <a href="https://smishtrap.com/termsandcondition">terms and conditions of use</a>.
                        </label>
                    </div>
                    <CustomButton onClick={register} text={'Register'} />
                    <p className='notaccount'>
                        Already have an account?
                        <span onClick={() => {
                            navigate('/')
                        }}>Login Here</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Signup