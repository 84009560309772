import { notification } from 'antd'
import { publicAPI } from '../../Config/Constant'
import store from '../store'



//add

export const addCompagin = async(payload,navigate) => {
 
    console.log('payload here',payload);



     try {
       const res = await publicAPI.post('/compagin/create', payload)
       if (res) {
       
         if (res) {
           notification.success({
             description: res.data.data.message,
             duration: 2,
           })

            return res
           // }
         } 
         

       }
     } catch (err) {
       console.error('Error during login:', err); 
       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }


// get


 export const getCompagain = async(payload) => {
 
    console.log('payload here',payload);



     try {
       const res = await publicAPI.post('/compagin/get', payload)
       if (res) {

           // console.log('res',res);
           
       
         if (res) {
          
            store.dispatch({
                type: 'SET_ALL_COMPAIGN',
                payload: res.data.data,
              })

              

          
   
            return res
          
         } 
         

       }
     } catch (err) {

       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }



 // delete



 export const deleteCompagain = async(payload) => {
 
  console.log('payload here',payload);



   try {
     const res = await publicAPI.post('/compagin/delete', payload)
     if (res) {

         // console.log('res',res);
         
     
       if (res) {
        
      // await  getCompagain()

            

        
 
          return res
        
       } 
       

     }
   } catch (err) {

     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}


// edit
 
export const EditCompagin = async(payload) => {
 
  console.log('payload here',payload);



   try {
     const res = await publicAPI.post('/compagin/edit', payload)
     if (res) {
     
       if (res) {
         notification.success({
           description: res.data.data.message,
           duration: 2,
         })

          return res
         // }
       } 
       

     }
   } catch (err) {
     console.error('Error during login:', err); 
     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}
