
import { Button } from 'antd'
import CustomButton from '../../Components/CustomButton'

// import { useNavigate } from 'react-router-dom'


const VerficationError = () => {


    // const navigate = useNavigate()


    // console.log('email', email);


    return (


        <div className='loginpagemain'>

            <div className='loginpage'>

                
           


                <h3 className="successs">
                Your Device is Not Verified!
                <p>Lorem ipsum dolor sit amet consectetur. Sed vitae pharetra semper turpis. </p>

                        </h3>
                      



                       
                    
<div className='errorbtn'>
                            <CustomButton  text={'Verify'} />
                            {/* <CustomButton   text={'Later'} /> */}
                            <Button className='optionalbutton'>Later</Button>
                            </div>




            </div>

        </div>
    )
}

export default VerficationError


