
import { useEffect, useState } from 'react'
import CustomPasswordInput from '../Components/CustomInputPassword'
import CustomTextInput from '../Components/CustomTextInput'
import Sidebar from '../Components/Sidebar'
import profileimg from "../assest/Image.png"
import UpdateNotification from '../Components/Notification/UpdateNotification'
import { useSelector } from 'react-redux'
import { getUser, UpdateProfile } from '../redux/actions/authAction'
import { notification } from 'antd'
import { invalidemail } from '../Config/Constant'


const Profile = () => {



    const user = useSelector((state) => state?.Auth?.userDetails?.user)

    console.log('user', user);


    useEffect(() => {
        let organizationid = localStorage.getItem('organizationid')
        getUser(organizationid)
    }, [])

    useEffect(() => {
        if (user) {
            setName(user?.Name);
            setEmail(user?.email);
            setPhone(user?.phone);
            setCompany(user?.companyName);
            setPassword(user?.password
                )
        }
    }, [user]);

    const [email, setEmail] = useState('')
    const [Password, setPassword] = useState('')
    const [name, setName] = useState(user?.Name || '')
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('')
    const [notificationmodal, setNotificationmodal] = useState(false)



    const Updatebtn = () => {


        const emailDomain = email.split('@')[1];

        if (invalidemail.includes(emailDomain)) {
          
            return notification.error({
              message: 'Invalid Email',
              description: 'The email domain is not allowed.',
            });
          }
    
        const phoneRegex = /^\+\d{10,15}$/;

        if (!phoneRegex.test(phone)) {
          return notification.error({
            message: 'Invalid Phone Number',
            description: 'Please enter a valid phone number.',
          });
        }
        try {
            let payload = {
                organizationid: user?.organizationid,
                memberid: user?.memberid,
                Name: name,
                email,
                companyName: company,
                phone,
                Password,
            }


            // let {organizationid,memberid,Name,email,companyName,phone,Password}=req.body

            const res = UpdateProfile(payload)
            // console.log('res',res);

            if (res?.data){
                setNotificationmodal(true)
            }
            
            // if (res) {
            //     setNotificationmodal(true)
            // }
        } catch (error) {
            console.log('error', error);

        }




    }

    return (
        <>

            <div className="profile-page">
                <Sidebar />
                <div className="profile-page-right-side">
                    <div className="headerbox">

                        <div className="headertext">
                            Profile
                        </div>

                        <div onClick={Updatebtn} className="createcompaignbutton">
                            Update Profile
                        </div>
                    </div>


                    <div className='profile-section'>
                        <img alt='' className='profileimg' src={profileimg}></img>

                        <div className='profile-textinput'>

                            <p>
                                Full Name
                            </p>
                            <CustomTextInput className='input' value={name} onChange={setName} />
                            <p>
                                Email Address
                            </p>
                            <CustomTextInput className='input' value={email} onChange={setEmail} />

                            <p>
                                Phone Number
                            </p>
                            <CustomTextInput className='input' value={phone} onChange={setPhone} />


                            <p>
                                Company Name
                            </p>
                            <CustomTextInput className='input' value={company} onChange={setCompany} />


                            <p>
                                Password
                            </p>
                            <CustomPasswordInput className='input' value={Password} onChange={setPassword} />

                        </div>

                    </div>
                </div>
            </div>



            <UpdateNotification
                visible={notificationmodal}
                onClose={() => setNotificationmodal(false)}

            />
        </>
    )
}

export default Profile