import React, { useState } from 'react'
import {  Modal, notification } from 'antd'


import CustomTextInput from '../CustomTextInput'
import CustomButton from '../CustomButton'
import { useSelector } from 'react-redux'
import { addfreeuser,getfreeuser } from '../../redux/actions/freeuserAction'
import CustomTextArea from '../CustomTextArea'

// setNotificationmodal={setNotificationmodal}

const AddUserModal = ({ visible, onClose,setNotificationmodal}) => {

  const [name,setName]=useState('')
  const [email,setEmail]=useState('')
  const [phone,setPhone]=useState('')
  const [companyname,setCompanyName]=useState('')
  const [role,setRole]=useState('')
  const [department,setdepartment]=useState('')
  const [notes,setNotes]=useState('')
  const [nameBorderColor, setNameBorderColor] = useState(''); // Default border color
  const [emailBorderColor, setEmailBorderColor] = useState('');


  const userdetails = useSelector((state) => state?.Auth?.userDetails?.user)

   
//  console.log('user',user?.team?.currentLeague?.name);
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


  const cancel = () => {
    setName('');
    setEmail('');
    setPhone('');
    setCompanyName('');
    setRole('');
    setdepartment('');
    setNotes('');
    // setPlayerInfo({})
    setNameBorderColor('')
    setEmailBorderColor('')
    onClose()
  }

  const confirmed = async() => {
    if (name === '' || email === '' || phone === '') {
      notification.error({ message: 'Please update mandatory fields.', duration: 3 });
      setNameBorderColor('#FF0000')
      setEmailBorderColor('#FF0000')
    } else if (!emailPattern.test(email)) {
      notification.error({ message: 'Please provide email in email format', duration: 3 });
      setEmailBorderColor('#FF0000')
    } else {

      let payload={
        Name:name,
        email,
        phone,
        companyName:companyname,
        role,
        departments:department,
        notes,
     organizationid:userdetails.organizationid

      }

 
      

      const res = await addfreeuser(payload)



      if(res){

        let newpayload={
          organizationid:userdetails.organizationid,
          page:1
        }

         await getfreeuser(newpayload)

         setName('');
         setEmail('');
         setPhone('');
         setCompanyName('');
         setRole('');
         setdepartment('');
         setNotes('');
         setNameBorderColor('')
      setEmailBorderColor('')
      cancel();
      setNotificationmodal(true);
      }
      


      
    }
  };
  
  


  


  return (
    <Modal
      className='addusermodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   
<h1>
Add Individual User
</h1>



<p>

Please Add details

</p>


<div className='add-user-main'>
    <p>
    Full Name <span style={{color:'red'}} >*</span>
    </p>
    <CustomTextInput  style={{ borderColor: nameBorderColor }}  className='inputbox' value={name} onChange={setName} />

    <p>
    Email Address <span style={{color:'red'}} >*</span>
    </p>
    <CustomTextInput style={{ borderColor: nameBorderColor }}  className='inputbox'  value={email} onChange={setEmail}  />


    <p>
    Phone Number <span style={{color:'red'}} >*</span>
    </p>
    <CustomTextInput className='inputbox'  value={phone} onChange={setPhone} />

    <p>
    Company Name
    </p>
    <CustomTextInput className='inputbox'  value={companyname} onChange={setCompanyName} />


    <p>
    Role
    </p>
    <CustomTextInput className='inputbox'  value={role} onChange={setRole} />


    <p>
    Department
    </p>
    <CustomTextInput className='inputbox'  value={department} onChange={setdepartment} />


    <p>
    Notes
    </p>

   
    <CustomTextArea style={{height:'135px'}}  className='inputbox'  value={notes} onChange={setNotes} />
   



</div>

<div className='btn'>

<CustomButton style={{marginBottom:'0px'}} onClick={confirmed} text={'Save'}/>


<CustomButton className='cancel' style={{marginBottom:'0px'}}  onClick={cancel} text={'Cancel'}/>

</div>

    </Modal>
  )
}

export default AddUserModal
