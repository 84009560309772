import React, { useEffect, useState } from 'react'
import { Modal, Select } from 'antd'


import CustomTextInput from '../CustomTextInput'
import CustomButton from '../CustomButton'
import { useSelector } from 'react-redux'
import { EditCompagin, getCompagain } from '../../redux/actions/compagainaction'

// setNotificationmodal={setNotificationmodal}

const EditCompagain = ({ visible, onClose, setEditNotificationmodal, editCompagin }) => {

  const [name, setName] = useState('')
  const [date, setDate] = useState( '')
  const [selectedUsers, setSelectedUsers] = useState([]);


  const { Option } = Select;

  const usergroup = useSelector((state) => state?.Groupreducer?.AllusersGroups)
    const user = useSelector((state) => state?.Auth?.userDetails?.user)

  // Handle selection change
  const handleSelectChange = (value) => {

    console.log('value', value);

    // setSelectedUsers(value); // Update selected user IDs

    const selectedUserObjects = usergroup.usergroup.filter((user) => value.includes(user._id));
    setSelectedUsers(selectedUserObjects);
  };


  useEffect(() => {
    if (editCompagin) {
      const formattedDate = new Date(editCompagin.createdDate).toISOString().split('T')[0];
      setDate(formattedDate);
      setName(editCompagin.name)
      setSelectedUsers(editCompagin?.targetGroup)
    }
  }, [editCompagin]);

  console.log('editCompagin', editCompagin);


  //  console.log('user',user?.team?.currentLeague?.name);

  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }

  const confirmed = async() => {


    let payload={
      createdDate:date,
      name, 
      title:name, 
      targetGroup:selectedUsers,
      id:editCompagin._id
    }

     const res= await EditCompagin(payload)
    
       if (res?.data){
        let checkpaylaod={
          organizationid:user.organizationid,
          page:1
        }
        await getCompagain(checkpaylaod)

        cancel()
        setEditNotificationmodal(true)
       }

       

  

    console.log('paylaod',payload);
    
  }





  return (
    <Modal
      className='addusermodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
      footer={[



      ]}

    >


      <h1>
        Edit Compaigns
      </h1>



      <p>

        Please Update details

      </p>





      <div className='add-user-main'>
        <p>
          Compaign Name
        </p>
        <CustomTextInput className='inputbox' value={name} onChange={setName} />

        <p>
          Date And Time
        </p>
        <CustomTextInput type='date' className='inputbox' value={date} onChange={setDate} />



        <p style={{ paddingTop: '20px' }}>
          Add Users
        </p>
        {/* <Dropdown in className='dropdown'  /> */}


        {/* 
    <Select className="dropdown" defaultValue="option1">
    <Option value="option1">Option 1</Option>
    <Option value="option2">Option 2</Option>
    <Option value="option3">Option 3</Option>
  </Select> */}


        <Select
          placeholder="Select Group"
          onChange={handleSelectChange}
          value={selectedUsers?.map(user => user._id)}
          className="dropdown"
          mode="multiple"
        //  value={selectedUsers} 
        // style={{ width: '100%' }}
        >
          {usergroup?.usergroup?.map((user) => (
            <Option key={user._id} value={user._id}>
              {user.name}
            </Option>
          ))}
        </Select>

      </div>

      <div className='btn'>

        <CustomButton style={{ marginBottom: '0px' }} onClick={confirmed} text={'Save'} />


        <CustomButton className='cancel' style={{ marginBottom: '0px' }} onClick={cancel} text={'Cancel'} />

      </div>

    </Modal>
  )
}

export default EditCompagain
