import React from 'react'
import { Modal } from 'antd'


import CustomButton from '../CustomButton'
import phone from "../../assest/preview pic.png"



const CompagainMessagepreview = ({ visible, onClose, selectedtemplate }) => {


  //  console.log('selectedtemplate',selectedtemplate);

  //  console.log('user',user?.team?.currentLeague?.name);

  const cancel = () => {
    // setPlayerInfo({})
    onClose()

  }







  return (
    <Modal
      className='importusermodal'
      title=''
      open={visible}

      onCancel={onClose}
      centered
      footer={[



      ]}

    >


      <h1>
        Message Preview
      </h1>



      <p>
        {selectedtemplate?.name}

      </p>

      {/* <div className="insideshowmessage">
                                                    <p>{selectedtemplate?.message}</p>
                                                  </div> 
<img style={{display:'flex',margin:'auto',paddingBottom:'20px'}} width={291} alt='' src={phone}></img> */}

      <div style={{ position: 'relative', display: 'inline-block', display: 'flex', margin: 'auto' }}>
        <img
          style={{ display: 'flex', margin: 'auto', paddingBottom: '20px' }}
          width={291}
          alt=""
          src={phone}
        />
        <div className="insideshowmessage">
          <p>{selectedtemplate?.message}</p>
        </div>
      </div>



      <CustomButton style={{ margin: 'auto', display: 'flex' }} width={'50%'} onClick={cancel} text={'Continue'} />

    </Modal>
  )
}

export default CompagainMessagepreview
