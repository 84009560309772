import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'


import CustomTextInput from '../CustomTextInput'
import CustomButton from '../CustomButton'

import { useSelector } from 'react-redux'
import { EditMemberOrganizationUser } from '../../redux/actions/myorganization.action'

// setNotificationmodal={setNotificationmodal}

const EditMemberOrganization = ({ visible, onClose,setEditNotificationmodal,member}) => {

  const [name,setName]=useState('')
  const [email,setEmail]=useState('')
  const [phone,setPhone]=useState('')
  const [memberid,SetMemberid]=useState('')


   
  const user = useSelector((state) => state?.Auth?.userDetails?.user)

  useEffect(() => {
    if (member) {
      setName(member.name)
      setEmail(member.email_address)
      setPhone(member.mfa_phone_number)
      SetMemberid(member?.member_id)

    }
  }, [member]);



  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }

  const confirmed =async()=>{


    let payload={
      name,
      email_address:email,
      mfa_phone_number: phone.replace(/[\s\-\(\)]/g, '').startsWith('+1') ? phone.replace(/[\s\-\(\)]/g, '') : '+1' + phone.replace(/[\s\-\(\)]/g, ''),
      member_id:memberid,
      organization_id:user.organizationid

    }


    console.log('paylaod',payload);
    

     const res= await EditMemberOrganizationUser(payload)
    
       if (res?.data){
        cancel()
        setEditNotificationmodal(true)

           }


    


   
  }


  


  return (
    <Modal
      className='addusermodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   
<h1>
Edit Member 
</h1>



<p>

Please Update details

</p>


<div className='add-user-main'>
    <p>
    Full Name
    </p>
    <CustomTextInput  className='inputbox' value={name} onChange={setName} />

    <p>
    Email Address
    </p>
    <CustomTextInput   className='inputbox'  value={email} onChange={setEmail} />

    <p>
Memberid
    </p>
    <CustomTextInput disabled={true}  className='inputbox'  value={memberid} onChange={SetMemberid} />


    <p>
    Phone Number
    </p>
    <CustomTextInput   className='inputbox'  value={phone} onChange={setPhone} />



  



</div>

<div className='btn'>

<CustomButton style={{marginBottom:'0px'}} onClick={confirmed} text={'Save'}/>


<CustomButton className='cancel' style={{marginBottom:'0px'}}  onClick={cancel} text={'Cancel'}/>

</div>

    </Modal>
  )
}

export default EditMemberOrganization
