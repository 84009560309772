

const initialState = {
    allfreeusers:[],
    allsearchfreeuser:[]
  
  }
  
  const freeuserReducer = (state = initialState, action) => {
    const { type, payload } = action
    
    switch (type) {
      case 'SET_FREE_USER': {
        return {
          ...state,
          allfreeusers: payload,
        
        }
      }
  
      case 'SEARCH_SET_FREE_USER': {
        return {
          ...state,
          allsearchfreeuser: payload,
        
        }
      }
    
  
  
  
      default:
        return state
    }
  }
  
  export default freeuserReducer