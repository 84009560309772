// import React, { useEffect, useState } from 'react'
// import { Modal, Select } from 'antd'


// import CustomTextInput from '../CustomTextInput'
// import CustomButton from '../CustomButton'
// import Search from "antd/es/transfer/search";
// import { editUserGroup, getUserGroup } from '../../redux/actions/groupcompaginAction';
// import { useSelector } from 'react-redux';



// const { Option } = Select;

// const EditGroup = ({ visible, onClose,setEditNotificationmodal,editgroup}) => {

//       const user = useSelector((state) => state?.Auth?.userDetails?.user)

//   const [name,setName]=useState('')

//   const [selectedUsers,setSelectedUsers]=useState([])

//   console.log('editgroup',editgroup);
  


//     const freeuser = useSelector((state) => state?.freeuserReducer?.allfreeusers)
  

//     const handleSelectChange = (value) => {
//       setSelectedUsers(value);  // Update selectedUsers state with the array of selected user IDs
//     };
   
// //  console.log('user',user?.team?.currentLeague?.name);

//   const cancel = () => {
//     // setPlayerInfo({})
//     onClose()
//   }
//   const onSearch = () => {

//   }

//   useEffect(() => {
//     if (editgroup) {
 
//       setName(editgroup.name)
//       setSelectedUsers(editgroup?.users)
//     }
//   }, [editgroup]);
  

//   const onsaved =async()=>{


//   let payload={
//       name, 
//       users:selectedUsers,
//       id:editgroup._id,
//     }

//     console.log('paylaod',payload);
    

//      const res= await editUserGroup(payload)
    
//        if (res?.data){
//         let checkpaylaod={
//           organizationid:user.organizationid
//         }
//         await getUserGroup(checkpaylaod)

//         cancel()
//         setEditNotificationmodal(true)
//        }




//     // onClose()
//     // setEditNotificationmodal(true)
//   }

  


//   return (
//     <Modal
//       className='addusermodal'
//       title=''
//       open={visible}
//       onCancel={onClose}
//       centered
//      footer={[
        
      
        
//       ]}
      
//     >

   
// <h1>
// Edit User Group
// </h1>



// <p>

// Please Update details

// </p>


// <div className='add-user-main'>
//     <p>
//     Group Name
//     </p>
//     <CustomTextInput className='inputbox' value={name} onChange={setName} />

//     <p>
//     Search Users
//     </p>





// <Search placeholder="Search user...." onSearch={onSearch} enterButton />

//     <p style={{paddingTop:'20px'}}>
//     Add Users
//     </p>
//     {/* <Dropdown in className='dropdown'  /> */}



//     <Select className="dropdown"
//     mode="multiple"  // Allows multiple selection
//     defaultValue={[]}  // Initially, no user is selected
//     value={selectedUsers.map((item) => item._id)} // Bind the selected user IDs to the state
//     onChange={handleSelectChange}  // Update the state when selection changes
    
//     >
//     {freeuser?.freeuser?.map((user) => (
//             <Option key={user._id} value={user._id}>
//               {user.Name}
//             </Option>
//           ))}
//   </Select>





// </div>

// <div className='btn'>

// <CustomButton style={{marginBottom:'0px'}} onClick={onsaved} text={'Save'}/>


// <CustomButton style={{marginBottom:'0px'}}  onClick={cancel} text={'Cancel'}/>

// </div>

//     </Modal>
//   )
// }

// export default EditGroup


import React, { useEffect, useState } from 'react'
import { Modal, Select,Input } from 'antd'


import CustomTextInput from '../CustomTextInput'
import CustomButton from '../CustomButton'
import { editUserGroup, getUserGroup } from '../../redux/actions/groupcompaginAction';
import { useSelector } from 'react-redux';
import { getfreeuser } from '../../redux/actions/freeuserAction';
import { CloseOutlined } from '@ant-design/icons'; 

const { Option } = Select;

const EditGroup = ({ visible, onClose, setEditNotificationmodal, editgroup }) => {
  const user = useSelector((state) => state?.Auth?.userDetails?.user);
  const [name, setName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
    const [open, setOpen] = useState(false);
  
  const { Search } = Input;

  const freeuser = useSelector((state) => state?.freeuserReducer?.allsearchfreeuser);

  useEffect(() => {
    if (editgroup) {
      setName(editgroup.name);
      setSelectedUsers(editgroup?.users); // Preselect users when editing
    }
  }, [editgroup]);

  // Handle the search term change
  const onSearch = async (value) => {
    setSearchTerm(value); // Set search term in state
    if (value.trim() === '') {
      // If search term is empty, load all users
      await getfreeuser({ organizationid: user.organizationid, search: '' });
      setOpen(true)
    } else {
      // Trigger search API call
      let inmodal=true;
      let payload = {
        organizationid: user.organizationid,
        search: value,
        inmodal,
      };
      await getfreeuser(payload);
      setOpen(true)
    }
  };

  // Handle the selection change (add or remove users from the selected list)

  const handleSelectChange = (value) => {
    // Get the selected users based on the value (user _id)
    const selectedUserObjects = freeuser?.freeuser?.filter((user) => value.includes(user._id));
  
    // Add the newly selected users to the previously selected ones
    setSelectedUsers((prevSelectedUserIds) => {
      // Merge new selections with the previous ones and remove duplicates
      const updatedSelectedUserIds = [...prevSelectedUserIds, ...selectedUserObjects];

      // console.log('updatedSelectedUserIds',updatedSelectedUserIds);
      
      
      // Remove duplicates based on user._id
      // const uniqueSelectedUsers = updatedSelectedUserIds.filter(
      //   (value, index, self) => index == self.findIndex((t) => t._id == value._id)
      // );
  
      const uniqueSelectedUsers = updatedSelectedUserIds.filter((value, index, self) => {

       
        
        
        // Check if this _id already exists in the list, if not, include it
        return index === self.findIndex((t) => t._id === value._id);
      });

      return uniqueSelectedUsers;
    });
  };

  const handleRemoveUser = (userId) => {
    setSelectedUsers((prevSelectedUserIds) =>
      prevSelectedUserIds.filter((user) => user._id !== userId) // Remove user by _id
    );
  };
  // Save the group data
  const onSaved = async () => {
    let payload = {
      name,
      users: selectedUsers.map((item) => item._id),
      id: editgroup._id,
    };

    console.log('payload', payload);

     const res = await editUserGroup(payload);

    if (res?.data) {
      let checkPayload = {
        organizationid: user.organizationid,
        page:1
      };
      await getUserGroup(checkPayload); // Get updated user groups
      await getfreeuser(checkPayload)
      cancel();
      setEditNotificationmodal(true); // Set notification modal to true
    }
  };

  // Handle cancel
  const cancel = () => {
    onClose();
  };

  return (
    <Modal
      className="addusermodal"
      title="Edit Group"
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
    >
      <h1>Edit  Group</h1>
      <p>Please Update details</p>

      <div className="add-user-main">
        <p>Group Name</p>
        <CustomTextInput className="inputbox" value={name} onChange={setName} />

        <p>Search Users</p>
        <Input.Search
          placeholder="Search user..."
          value={searchTerm}
          onChange={(e) => onSearch(e.target.value)} // Update searchTerm and trigger search
          onSearch={onSearch} // Trigger search when enter is pressed
          enterButton
          
        />

        <p style={{ paddingTop: '20px' }}>Add Users</p>

        <Select
          className="dropdown"
          // mode="multiple"
          // value={selectedUsers.map((item) => item._id)} // Use selected user's IDs
          onChange={handleSelectChange} // Update selected users
          placeholder="Select Users"
          style={{ width: '100%' }}
          open={open}
          onBlur={() => setOpen(false)} 
          onSearch={onSearch}
          
        >
          {/* Map through filtered users and create an Option for each */}
          {freeuser?.freeuser?.map((user) => (
            <Option key={user._id} value={user._id}>
              {user.Name}
            </Option>
          ))}
        </Select>

        <>
    {selectedUsers.length > 0 && (
      <div className='removeitem'>
        {selectedUsers?.map((item) => (
          <div className='removebox' key={item._id} style={{ display: 'flex', alignItems: 'center',flexDirection:'row' }}>
            <span style={{ color:  '#EF4444'}}>{item.Name}</span>
            <CloseOutlined
            size={10}
              onClick={() => handleRemoveUser(item._id)} 
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>
    )}
  </>

      </div>

      <div className="btn">
        <CustomButton style={{ marginBottom: '0px' }} onClick={onSaved} text="Save" />
        <CustomButton style={{ marginBottom: '0px' }} onClick={cancel} text="Cancel" />
      </div>
    </Modal>
  );
};

export default EditGroup;
