import React from 'react'
import { Modal } from 'antd'

import CustomButton from '../../Components/CustomButton'
import { deleteCompagain, getCompagain } from '../../redux/actions/compagainaction';
import { useSelector } from 'react-redux';



const DeleteModal = ({ visible, onClose,currentRoute,setNotificationmodal,deletecomp,selectedCampaignId}) => {

  // console.log('currentRoute',currentRoute);
  // console.log('selectedCampaignId',selectedCampaignId);

  const user = useSelector((state) => state?.Auth?.userDetails?.user)

  // console.log('user', user);
      
  

   
//  console.log('user',user?.team?.currentLeague?.name);

  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }

const onsave=async()=>{


try {

  if (selectedCampaignId.length > 0){

    const validCampaignIds = selectedCampaignId.filter(id => typeof id === 'string');

    console.log('validCampaignIds',validCampaignIds);
    
    let payload={
      ids:validCampaignIds
    }
   const res= await deleteCompagain(payload)

   if (res?.data){
    let checkpaylaod={
      organizationid:user.organizationid,
      page:1
    }
    await getCompagain(checkpaylaod)

    cancel()
    setNotificationmodal(true)
    
   }
  
  }
  

} catch (error) {

  console.log('error',error);
  
  
}



}
  


  return (
    <Modal
      className='deletemodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   
<h1>
  {currentRoute === '/dashboard/my-campaigns' || deletecomp ? 'Delete Campaign?' : 'Delete User?'}
</h1>



{/* <p>

{currentRoute === '/dashboard/my-campaigns' ||  deletecomp ? 'Do you want to delete this campaign.' : 'Do you want to delete this user.'}

</p> */}


<p>
  {currentRoute === '/dashboard/my-campaigns' 
    ? (selectedCampaignId?.length > 1 ? `Do you want to delete ${selectedCampaignId?.length } campaign.` : 'Do you want to delete this campaign.')
    : deletecomp 
      ? 'Do you want to delete this campaign.'
      : 'Do you want to delete this user.'}
</p>


<div className='btn'>

   <CustomButton onClick={onsave} text={'Yes'}/>


   <CustomButton onClick={cancel} text={'No'}/>
 
</div>
    </Modal>




  )
}

export default DeleteModal
