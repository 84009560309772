import { notification } from 'antd'
import { publicAPI } from '../../Config/Constant'
import store from '../store'



export const addfreeuser = async(payload) => {
 
    console.log('payload here',payload);



     try {
       const res = await publicAPI.post('/freeuser/addfreeuser', payload)
    

    
           
       
         if (res) {
          

// await getfreeuser()

           notification.success({
             description: res.data.data.message,
             duration: 2,
           })
        //    navigate('/dashboard/overview')
            return res
           // }
         } 
         

       
     } catch (err) {
       console.error('Error during login:', err); 
       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }


 export const getfreeuser = async(payload) => {
 
    console.log('payload here che',payload);



     try {
       const res = await publicAPI.post('/freeuser/getfreeuser', payload)

       if (res) {
        
        if (payload?.inmodal){
          store.dispatch({
            type: 'SEARCH_SET_FREE_USER',
            payload: res.data.data,
          })

        }
        
        else {

        store.dispatch({
            type: 'SET_FREE_USER',
            payload: res.data.data,
          })
       
           
        }
         
        return res

       }
     } catch (err) {
       console.error('Error during login:', err); 
       notification.error({
         message: err?.response?.data?.message || 'Server Error',
         duration: 3,
       })
     }
   
 }



 export const Editfreeuser = async(payload) => {
 




   try {
     const res = await publicAPI.post('/freeuser/edit', payload)
  

  
         
     
       if (res) {
        

// await getfreeuser()

         notification.success({
           description: res.data.data.message,
           duration: 2,
         })
      //    navigate('/dashboard/overview')
          return res
         // }
       } 
       

     
   } catch (err) {
     console.error('Error during login:', err); 
     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}


export const Editmultifreeuser = async(payload) => {
 




  try {
    const res = await publicAPI.post('/freeuser//multiple-edit', payload)
 

 
        
    
      if (res) {
       

// await getfreeuser()

        notification.success({
          description: res.data.data.message,
          duration: 2,
        })
     //    navigate('/dashboard/overview')
         return res
        // }
      } 
      

    
  } catch (err) {
    console.error('Error during login:', err); 
    notification.error({
      message: err?.response?.data?.message || 'Server Error',
      duration: 3,
    })
  }

}



export const deletefreeuser = async(payload) => {
 




   try {
     const res = await publicAPI.post('/freeuser/delete', payload)
     if (res) {

         // console.log('res',res);
         
     
    
        

          return res
        
       
       

     }
   } catch (err) {

     notification.error({
       message: err?.response?.data?.message || 'Server Error',
       duration: 3,
     })
   }
 
}



// uplaod csv


export const uploadcsvfreeuser = async(payload) => {
 




  try {
    const res = await publicAPI.post('/freeuser/uploadcsv', payload)
    if (res) {

        // console.log('res',res);
        
    
   
       

         return res
       
      
      

    }
  } catch (err) {

    notification.error({
      message: err?.response?.data?.message || 'Server Error',
      duration: 3,
    })
  }

}
