import Axios from 'axios'
import store from '../redux/store'

 export const base_url = '/api'
// export const base_url='http://localhost:8000'



export const publicAPI = Axios.create({ baseURL: base_url })
export const privateAPI = Axios.create({ baseURL: base_url })

export const attachToken = async () => {
  const jwt = localStorage.getItem('token')
  privateAPI.defaults.headers.common.Authorization = `Bearer ${jwt}`

}

privateAPI.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export const version = '2.0.6'

export const invalidemail=["gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "aol.com", "icloud.com", "zoho.com", "mail.com", "yandex.com", "protonmail.com", "gmx.com", "fastmail.com", "rediffmail.com", "inbox.com", "hushmail.com", "lycos.com", "excite.com", "tutanota.com", "runbox.com", "mailfence.com", "posteo.net", "lavabit.com", "startmail.com", "msgsafe.io", "mailo.com", "disroot.org", "riseup.net", "mailinator.com", "10minutemail.com", "tempmail.com", "guerrillamail.com", "emailondeck.com", "mytrashmail.com", "spamex.com", "maildrop.cc", "getairmail.com", "throttlemail.com", "burnermail.io", "fakeinbox.com", "anonaddy.com", "mailnesia.com", "spamgourmet.com", "jetable.org", "meltmail.com", "inboxalias.com", "privaterelay.appleid.com", "freemail.hu", "tiscali.co.uk", "web.de", "seznam.cz", "centrum.cz", "onet.pl", "interia.pl", "laposte.net", "orange.fr", "sfr.fr", "wanadoo.fr", "free.fr", "virgilio.it", "libero.it", "alice.it", "email.it", "poste.it", "t-online.de", "arcor.de", "zoznam.sk", "mail.bg", "abv.bg", "ukr.net", "mail.ru", "bk.ru", "list.ru", "inbox.ru", "rambler.ru", "qq.com", "163.com", "126.com", "sina.com", "sohu.com", "yeah.net", "21cn.com", "263.net", "china.com", "netease.com", "naver.com", "daum.net", "hanmail.net", "kakao.com", "gawab.com", "in.com", "indiatimes.com", "rediff.com", "bigpond.com", "optusnet.com.au", "xtra.co.nz"]
