import React, { useState } from 'react'
import { Modal } from 'antd'
import sampleData from "../../assest/sampledata.csv"


import CustomButton from '../CustomButton'
import * as XLSX from "xlsx";
import { getfreeuser, uploadcsvfreeuser } from '../../redux/actions/freeuserAction';
import { useSelector } from 'react-redux';


const ImportUserModal = ({ visible, onClose}) => {
  const [file, setFile] = useState(null);



  const user = useSelector((state) => state?.Auth?.userDetails?.user)
  // console.log('user.organizationId',user.organizationid  );
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      alert("Please select an Excel file first.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const ab = reader.result;
      const wb = XLSX.read(ab, { type: "array" });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(ws);

      let payload={
        users: data,
        organizationId: user.organizationid,
      }
      // Send the data to the backend
      try {
      let res =await uploadcsvfreeuser(payload)
      if (res?.data){
        let checkpaylaod={
          organizationid:user.organizationid,
          page:1
        }
        await getfreeuser(checkpaylaod)
        cancel()
      }
      } catch (error) {
        console.error("Error uploading data", error);
      }
    };
    reader.readAsArrayBuffer(file);
  };
   
//  console.log('user',user?.team?.currentLeague?.name);

  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }


    // Function to handle CSV download
    const handleDownload = () => {
        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = sampleData; // Path to the CSV file
        link.download = 'sampledata.csv'; // Filename for the download
        link.click(); // Programmatically click the link to start the download
    };
  
  


  return (
    <Modal
      className='importusermodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   
<h1>
Import User
</h1>






<CustomButton onClick={handleDownload} className='download'  text={'Download Sample CSV Format'}/>

<h2 className='headtext'>
“To access additional features and enhanced support, a paid plan is required. If you have any questions or need further assistance, please feel free to contact us.”
</h2>

<input
        type="file"
        id="fileInput"
        accept=".xls,.xlsx,.csv"  // Allow only Excel and CSV files
        // style={{ display: 'none' }}  // Hide the file input
        onChange={handleFileChange}  // Trigger when a file is selected
      />

<p>Please upload your CSV file by clicking the button below.</p>

<CustomButton  onClick={handleUpload} text={'Upload CSV File'}/>

    </Modal>
  )
}

export default ImportUserModal
