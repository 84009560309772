import React from 'react'
import { Modal } from 'antd'



import success from "../../assest/success.png"

import CustomButton from '../CustomButton'





const DeleteNotification = ({ visible, onClose,currentRoute,deletecomp}) => {




   
//  console.log('user',user?.team?.currentLeague?.name);

  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }


  


  return (
    <Modal
      className='addnotification'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   

<div className='notificationmain'>
<img className='successimg' src={success} alt=""></img>


<h3 className="successs">
Successfully Deleted!
{/* 
    <p>Your user has been deleted</p> */}


    <p>

{currentRoute === '/dashboard/my-campaigns' || deletecomp ? 'Your campaign has been deleted' : 'Your user has been deleted'}

</p>
</h3>












<CustomButton onClick={cancel} text={'Continue'} />


</div>


   









    </Modal>
  )
}

export default DeleteNotification
