// import React, { useEffect, useState } from 'react';
// import { Input, Modal } from 'antd';
// import { useSelector } from 'react-redux';
// import CustomTextInput from '../../CustomTextInput';
// import CustomButton from '../../CustomButton';
// import { Editfreeuser, Editmultifreeuser, getfreeuser } from '../../../redux/actions/freeuserAction';

// const EditUserModal = ({ visible, onClose, setEditNotificationmodal, editfreeuser }) => {
//   const [users, setUsers] = useState([]);
//   const user = useSelector((state) => state?.Auth?.userDetails?.user);


//   useEffect(() => {
//     if (editfreeuser && Array.isArray(editfreeuser)) {
//       setUsers(editfreeuser);
//     }
//   }, [editfreeuser]);

//   const cancel = () => {
//     onClose();
//   };

//   const handleFieldChange = (field, value) => {
//     // Apply the changes to all users for the given field
//     const updatedUsers = users.map(user => ({
//       ...user,
//       [field]: value // Update the specified field for all users
//     }));
//     setUsers(updatedUsers); // Update state with the modified array
//   };

//   const confirmed = async () => {
//     // You can create a payload for multiple users
//     const payload = users.map(user => ({
//       companyName: user.companyName,
//       role: user.role,
//       departments: user.departments,
//       notes: user.notes,
//       id: user._id,
//     }));

//     console.log('payload', payload);

//     // Sending the payload for editing
//     const res = await Editmultifreeuser(payload);

//     if (res?.data) {
//       const checkpaylaod = {
//         organizationid: user.organizationid,
//       };
//       await getfreeuser(checkpaylaod);
//       cancel();
//       setEditNotificationmodal(true);
//     }
//   };

//   return (
//     <Modal
//       className='addusermodal'
//       title=''
//       open={visible}
//       onCancel={onClose}
//       centered
//       footer={[]}
//     >
//       <h1>Edit Multiple Users</h1>
//       <p>Please Update details for selected users</p>

//       <div className='add-user-main'>
//         {users.map((user, index) => (
//           <div key={user._id} className="user-edit-section">
//             <h3>User {index + 1}</h3>

//             <p>Full Name</p>
//             <CustomTextInput
//               disabled={true}
//               className='inputbox'
//               value={user.Name}
//               // No need to handle changes for Full Name
//             />

//             <p>Email Address</p>
//             <CustomTextInput
//               disabled={true}
//               className='inputbox'
//               value={user.email}
//               // No need to handle changes for Email
//             />

//             <p>Phone Number</p>
//             <CustomTextInput
//               disabled={true}
//               className='inputbox'
//               value={user.phone}
//               // No need to handle changes for Phone
//             />

//             <p>Company Name</p>
//             <CustomTextInput
//               disabled={true}
//               className='inputbox'
//               value={user.companyName}
//               // No need to handle changes for Company Name
//             />

//             <p>Role</p>
//             <Input
//               className='inputbox'
//               value={user?.role}
//               onChange={(e) => handleFieldChange('role', e.target.value)}
//             />

//             <p>Department</p>
//             <Input
//               className='inputbox'
//               value={user?.departments}
//               onChange={(e) => handleFieldChange('departments', e.target.value)}
//             />

//             <p>Notes</p>
//             <Input
//               style={{ height: '135px' }}
//               className='inputbox'
//               value={user?.notes}
//               onChange={(e) => handleFieldChange('notes', e.target.value)}
//             />
//           </div>
//         ))}
//       </div>

//       <div className='btn'>
//         <CustomButton style={{ marginBottom: '0px' }} onClick={confirmed} text={'Save'} />
//         <CustomButton
//           className='cancel'
//           style={{ marginBottom: '0px' }}
//           onClick={cancel}
//           text={'Cancel'}
//         />
//       </div>
//     </Modal>
//   );
// };

// export default EditUserModal;



import React, { useEffect, useState } from 'react';
import { Input, Modal } from 'antd';
import { useSelector } from 'react-redux';
import CustomTextInput from '../../CustomTextInput';
import CustomButton from '../../CustomButton';
import { Editmultifreeuser, getfreeuser } from '../../../redux/actions/freeuserAction';
import TextArea from 'antd/es/input/TextArea';

const EditUserModal = ({ visible, onClose, setEditNotificationmodal, editfreeuser,setMultieditfreeuser,setchecked,setSelectAllFreeuser }) => {
  const [users, setUsers] = useState([]);
  const currentUser = useSelector((state) => state?.Auth?.userDetails?.user);

  useEffect(() => {
    if (editfreeuser && Array.isArray(editfreeuser)) {
      setUsers(editfreeuser); // Store the selected users
    }
  }, [editfreeuser]);

  const cancel = () => {
    onClose();
  };

  const handleFieldChange = (field, value) => {
    // Update the field for all users
    const updatedUsers = users.map(user => ({
      ...user,
      [field]: value // Update the field for each user
    }));
    setUsers(updatedUsers); // Update state with the modified array
  };

  const confirmed = async () => {
    // Create the payload for all users
    const payload = users.map(user => ({
      role: user.role,
      departments: user.departments,
      notes: user.notes,
      id: user._id, // Include the user ID in the payload
    }));

    console.log('payload', payload);

    // Send the payload for editing
    const res = await Editmultifreeuser(payload);

    if (res?.data) {
      const checkpaylaod = {
        organizationid: currentUser.organizationid,
        page:1,
      };
      await getfreeuser(checkpaylaod);
      cancel();
      setEditNotificationmodal(true);
      setMultieditfreeuser([])
      setchecked([])
      setSelectAllFreeuser(false)
    }
  };

  return (
    <Modal
      className='addusermodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
      footer={[]}
    >
      <h1>Edit Multiple Users</h1>
      <p>Please Update details for selected users</p>

      <div className='add-user-main'>
        {users.length > 0 && (
          <>
             <p>Full Name</p>
         <CustomTextInput
              disabled={true}
              className='inputbox'
     
            />

            <p>Email Address</p>
            <CustomTextInput
              disabled={true}
              className='inputbox'
           
              // No need to handle changes for Email
            />

            <p>Phone Number</p>
            <CustomTextInput
              disabled={true}
              className='inputbox'
             
              // No need to handle changes for Phone
            />

            <p>Company Name</p>
            <CustomTextInput
              disabled={true}
              className='inputbox'
           
              // No need to handle changes for Company Name
            />



            <p>Role</p>
            <Input
              className='inputbox'
              // value={users[0]?.role} // Set the role for the first user as a placeholder
              onChange={(e) => handleFieldChange('role', e.target.value)} // Apply to all users
            />

            <p>Department</p>
            <Input
              className='inputbox'
              // value={users[0]?.departments} // Set the department for the first user as a placeholder
              onChange={(e) => handleFieldChange('departments', e.target.value)} // Apply to all users
            />

            <p>Notes</p>
            <TextArea
              style={{ height: '135px' }}
              className='inputbox'
              // value={users[0]?.notes} // Set the notes for the first user as a placeholder
              onChange={(e) => handleFieldChange('notes', e.target.value)} // Apply to all users
            />
          </>
        )}
      </div>

      <div className='btn'>
        <CustomButton style={{ marginBottom: '0px' }} onClick={confirmed} text={'Save'} />
        <CustomButton
          className='cancel'
          style={{ marginBottom: '0px' }}
          onClick={cancel}
          text={'Cancel'}
        />
      </div>
    </Modal>
  );
};

export default EditUserModal;

