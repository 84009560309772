import React from 'react'
import { Modal } from 'antd'
import CustomButton from '../CustomButton'
import { useSelector } from 'react-redux';
import { deletefreeuser, getfreeuser } from '../../redux/actions/freeuserAction';



const DeleteUsermodal = ({ visible, onClose,setNotificationmodal,checked,setchecked,multieditfreeuser}) => {


  const user = useSelector((state) => state?.Auth?.userDetails?.user)

  // console.log('user', user);
      
  
console.log('checked',checked);

   
//  console.log('user',user?.team?.currentLeague?.name);

  const cancel = () => {
    // setPlayerInfo({})
    onClose()
  }

const onsave=async()=>{


try {

  if (checked.length > 0){

    const validusers = checked.filter(id => typeof id === 'string');

    console.log('validusers',validusers);
    


    
    let payload={
      ids:validusers
    }
    const res= await deletefreeuser(payload)

    console.log('res',res);
    

  if (res?.data){

    setchecked([])
    if ( multieditfreeuser && multieditfreeuser?.length > 0){
      
      window.location.reload()
    }

     let checkpaylaod={
       organizationid:user.organizationid,
       page:1
     }
     await getfreeuser(checkpaylaod)
     cancel()
     setNotificationmodal(true)
     
   }



  }
  

} catch (error) {

  console.log('error',error);
  
  
}


 
}
  


  return (
    <Modal
      className='deletemodal'
      title=''
      open={visible}
      onCancel={onClose}
      centered
     footer={[
        
      
        
      ]}
      
    >

   
<h1>
   Delete User?
</h1>



{/* <p>
 Do you want to delete this user
</p> */}


<p>
  Do you want to delete {multieditfreeuser?.length > 0 ? `${multieditfreeuser.length} Users` : 'this User'}?
</p>


<div className='btn'>

   <CustomButton onClick={onsave} text={'Yes'}/>


   <CustomButton onClick={cancel} text={'No'}/>
 
</div>
    </Modal>




  )
}

export default DeleteUsermodal
